import React, { Fragment, useEffect, useRef, useState } from "react";
import st from "../../styles/allUse/content.module.scss";
import { useSelector } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import "./settings.scss";
import GPSettingTable from "./gp-setting";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 800,
  timerProgressBar: true,
});

export default function Settings() {
  const apiUrl = useSelector((state) => state.app.apiPath);
  const access_token = useSelector((state) => state.app.access_token);

  const formSettings = {
    display: "",
    image: "",
    settingName: "",
    settingValue: "",
    link: "",
  };

  const [valueGp, setValueGp] = useState(formSettings);
  const [valueGpFashion, setValueGpFashion] = useState(formSettings);
  const [valueChat, setValueChat] = useState(formSettings);
  const [valueSlip, setValueSlip] = useState(formSettings);
  const [valueEmail, setValueEmail] = useState(formSettings);
  const [valueKey, setValueKey] = useState(formSettings);
  const [valueLineStore, setValueLineStore] = useState(formSettings);
  const [valueLineMen, setValueLineMen] = useState(formSettings);
  const [valueLineWomen, setValueLineWomen] = useState(formSettings);
  const [valueLineCustomer, setValueLineCustomer] = useState(formSettings);
  const [valueWebExtra, setValueWebExtra] = useState(formSettings);
  const [valueCODFashion, setValueCODFashion] = useState(formSettings);
  /** */
  const [valueVerify, setValueVerify] = useState(formSettings);
  const [reservedWord, setReservedWord] = useState(formSettings);
  const [changeVerify, setChangeVerify] = useState(false);
  const [changeDisplayWebExt, setChangeDisplayWebExt] = useState(false);
  const [changeReservedWord, setChangeReservedWord] = useState(false);

  const imageStore = useRef();
  const imageMen = useRef();
  const imageWomen = useRef();
  const imageCustomer = useRef();

  const [imageStoreFile, setImageStoreFile] = useState([]);
  const [imageMenFile, setImageMenFile] = useState([]);
  const [imageWomenFile, setImageWomenFile] = useState([]);
  const [imageCustomerFile, setImageCustomerFile] = useState([]);

  const profileStore = useRef();
  const profileMen = useRef();
  const profileWomen = useRef();
  const profileCustomer = useRef();
  const webExtraBanner = useRef();

  const [imageStoreProfileFile, setImageStoreProfileFile] = useState([]);
  const [imageMenProfileFile, setImageMenProfileFile] = useState([]);
  const [imageWomenProfileFile, setImageWomenProfileFile] = useState([]);
  const [imageCustomerProfileFile, setImageCustomerProfileFile] = useState([]);
  const [webExtraBannerFile, setWebExtraBannerFile] = useState([]);

  useEffect(() => {
    apiGetSetting();
  }, []);

  useEffect(() => {
    if (changeVerify) {
      apiUpdateSetting(valueVerify);
      setChangeVerify(false);
    }
  }, [valueVerify]);

  useEffect(() => {
    if (changeReservedWord) {
      apiUpdateSetting(reservedWord);
      setChangeReservedWord(false);
    }
  }, [reservedWord]);

  async function apiGetSetting() {
    setValueGp(formSettings);
    setValueGpFashion(formSettings);
    setValueSlip(formSettings);
    setValueChat(formSettings);
    setValueEmail(formSettings);
    setValueKey(formSettings);
    setValueLineStore(formSettings);
    setValueLineMen(formSettings);
    setValueLineWomen(formSettings);
    setValueLineCustomer(formSettings);
    setValueVerify(formSettings);
    setReservedWord(formSettings);
    setValueWebExtra(formSettings);

    await axios({
      method: "GET",
      url: `${apiUrl}/api/admin/getSetting`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }).then((res) => {
      if (res.data.status) {
        for (let item of res.data.setting) {
          if (item.settingName === "gross_profit") setValueGp(item);
          if (item.settingName === "gross_profit_fashion") setValueGpFashion(item);
          if (item.settingName === "cron_job_month") setValueSlip(item);
          if (item.settingName === "time_delete_message") setValueChat(item);
          if (item.settingName === "email_user") setValueEmail(item);
          if (item.settingName === "email_password") setValueKey(item);
          if (item.settingName === "line_member") setValueLineStore(item);
          if (item.settingName === "line_men") setValueLineMen(item);
          if (item.settingName === "line_women") setValueLineWomen(item);
          if (item.settingName === "line_customer") setValueLineCustomer(item);
          if (item.settingName === "auto_verify") setValueVerify(item);
          if (item.settingName === "reserved_word") setReservedWord(item);
          if (item.settingName === "web_extra") setValueWebExtra(item);
          if (item.settingName === "cod_fashion") setValueCODFashion(item);
        }
      }
    });
  }

  function handleSubmitForm(setting, fieldName, changeProfile = false) {
    if (!setting.settingValue || setting.settingValue === "") {
      Toast.fire({
        icon: "error",
        title: "Error",
      });
      return false;
    }
    Swal.fire({
      title: "ยืนยันการตั้งค่า " + fieldName,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      confirmButtonColor: "#f472b6",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        apiUpdateSetting(setting, changeProfile);
      }
    });
  }

  async function apiUpdateSetting(setting) {
    const formData = new FormData();
    if (imageStoreFile.length > 0 && setting.settingName === "line_member") {
      formData.append("image", imageStoreFile[0]);
    }
    if (imageMenFile.length > 0 && setting.settingName === "line_men") {
      formData.append("image", imageMenFile[0]);
    }
    if (imageWomenFile.length > 0 && setting.settingName === "line_women") {
      formData.append("image", imageWomenFile[0]);
    }
    if (
      imageCustomerFile.length > 0 &&
      setting.settingName === "line_customer"
    ) {
      formData.append("image", imageCustomerFile[0]);
    }
    //  profile image //
    if (
      imageStoreProfileFile.length > 0 &&
      setting.settingName === "line_member"
    ) {
      formData.append("profile_image", imageStoreProfileFile[0]);
    }
    if (imageMenProfileFile.length > 0 && setting.settingName === "line_men") {
      formData.append("profile_image", imageMenProfileFile[0]);
    }
    if (
      imageWomenProfileFile.length > 0 &&
      setting.settingName === "line_women"
    ) {
      formData.append("profile_image", imageWomenProfileFile[0]);
    }
    if (
      imageCustomerProfileFile.length > 0 &&
      setting.settingName === "line_customer"
    ) {
      formData.append("profile_image", imageCustomerProfileFile[0]);
    }
    //  banner //
    if (webExtraBannerFile.length > 0 && setting.settingName === "web_extra") {
      formData.append("image", webExtraBannerFile[0]);
    }

    formData.append("settingName", setting.settingName);
    formData.append("settingValue", setting.settingValue);
    formData.append("link", setting.link);
    await axios({
      method: "POST",
      url: `${apiUrl}/api/admin/updateSetting`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: formData,
    })
      .then((res) => {
        Toast.fire({
          icon: "success",
          title: "แก้ไขแล้ว",
        });
        apiGetSetting();
      })
      .catch((err) => console.log(err));
  }

  async function apiUpdateDisplayWeb(setting) {
    await axios({
      method: "POST",
      url: `${apiUrl}/api/admin/updateDisplayWeb`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: { settingName: setting.settingName, display: setting.display },
    })
      .then((res) => {
        Toast.fire({
          icon: "success",
          title: "แก้ไขแล้ว",
        });
        apiGetSetting();
      })
      .catch((err) => console.log(err));
  }

  function inputImageOnChange(e, setting, changeProfile = false) {
    if (!e.target.files.length) return false;
    if (
      ["image/jpeg", "iamge/jpg", "image/png", "image/webp"].includes(
        e.target.files[0].type
      )
    ) {
      const urlImg = URL.createObjectURL(e.target.files[0]);
      if (changeProfile) {
        // handle profile image change
        if (setting.settingName === "line_member") {
          profileStore.current.src = urlImg;
          setImageStoreProfileFile(e.target.files);
        }
        if (setting.settingName === "line_men") {
          profileMen.current.src = urlImg;
          setImageMenProfileFile(e.target.files);
        }
        if (setting.settingName === "line_women") {
          profileWomen.current.src = urlImg;
          setImageWomenProfileFile(e.target.files);
        }
        if (setting.settingName === "line_customer") {
          profileCustomer.current.src = urlImg;
          setImageCustomerProfileFile(e.target.files);
        }
      } else {
        if (setting.settingName === "line_member") {
          imageStore.current.src = urlImg;
          setImageStoreFile(e.target.files);
        }
        if (setting.settingName === "line_men") {
          imageMen.current.src = urlImg;
          setImageMenFile(e.target.files);
        }
        if (setting.settingName === "line_women") {
          imageWomen.current.src = urlImg;
          setImageWomenFile(e.target.files);
        }
        if (setting.settingName === "line_customer") {
          imageCustomer.current.src = urlImg;
          setImageCustomerFile(e.target.files);
        }
      }
    } else {
      Swal.fire({
        title: "กรุณาอัปโหลดเฉพาะไฟล์รูปภาพ",
        icon: "warning",
        position: "center",
        timer: 1000,
        showConfirmButton: false,
      });
    }
  }

  function inputBannerOnChange(e, setting, changeProfile = false) {
    if (!e.target.files.length) return false;
    if (
      ["image/jpeg", "iamge/jpg", "image/png", "image/webp"].includes(
        e.target.files[0].type
      )
    ) {
      const urlImg = URL.createObjectURL(e.target.files[0]);
      webExtraBanner.current.src = urlImg;
      setWebExtraBannerFile(e.target.files);
    } else {
      Swal.fire({
        title: "กรุณาอัปโหลดเฉพาะไฟล์รูปภาพ",
        icon: "warning",
        position: "center",
        timer: 1000,
        showConfirmButton: false,
      });
    }
  }

  function updateVerify() {
    setChangeVerify(true);
    setValueVerify({
      ...valueVerify,
      settingValue: valueVerify.settingValue === "yes" ? "no" : "yes",
    });
  }

  async function updateWebExtraDisplay() {
    setChangeDisplayWebExt(true);
    setValueWebExtra({
      ...valueWebExtra,
      display: valueWebExtra.display === "yes" ? "no" : "yes",
    });
  }

  useEffect(() => {
    if (changeDisplayWebExt) {
      apiUpdateDisplayWeb(valueWebExtra);
      setChangeDisplayWebExt(false);
    }
  }, [valueWebExtra.display]);

  function removeTag(_word) {
    const reserved = reservedWord.settingValue.replace("," + _word, "");
    setChangeReservedWord(true);
    setReservedWord({ ...reservedWord, settingValue: reserved });
  }
  function addTag(_word) {
    if (reservedWord.settingValue.indexOf(_word) < 0) {
      const reserved = (reservedWord.settingValue += "," + _word);
      setChangeReservedWord(true);
      setReservedWord({ ...reservedWord, settingValue: reserved });
    } else {
      Toast.fire({
        icon: "info",
        title: "มีคำนี้แล้ว",
        timer: 1500,
      });
    }
  }

  function openSwlInput() {
    Swal.fire({
      title: "กรอกคำที่ต้องการเพิ่ม",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      cancelButtonText: "ย้อนกลับ",
      confirmButtonText: "ยืนยัน",
      showLoaderOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        addTag(result.value);
      }
    });
  }

  const [filename, setFilename] = useState("เลือกไฟล์");
  const [fileManual, setFileManual] = useState(null);
  const [fileError, setFileError] = useState(false);
  const inputFile = useRef();

  const onSelectFile = (event) => {
    setFileError(false);
    const file = event.target.files[0];
    const filename = file.name;
    setFileManual(file);
    setFilename(filename);
  };

  const uploadFile = () => {
    if (!fileManual) {
      setFileError(true);
      inputFile.current.value = "";
      return false;
    }
    const formData = new FormData();
    formData.append("file", fileManual);

    Swal.fire({
      title: "ยืนยันการอัพโหลด",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      confirmButtonColor: "#f472b6",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "POST",
          url: `${apiUrl}/api/admin/uploadManual`,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
          data: formData,
        })
          .then((res) => {
            Toast.fire({
              icon: "success",
              title: "เพิ่มคู่มือสำเร็จ",
            });
            inputFile.current.value = "";
            setFileManual(null);
            setFilename("เลือกไฟล์");
            // apiGetSetting();
          })
          .catch((err) => {
            console.log(err);
            Toast.fire({
              icon: "error",
              title: "error",
            });
          });
      }
    });
  };

  return (
    <div
      className={`main-page ${st.content} animate-[fade_0.3s_ease-in-out] setting-page`}
    >
      <p className={st.title}>ตั้งค่า </p>
      <div className="first-rows">
        <div className="column-setting">
          <label>
            ตั้งค่า Gross profit <br />
            (สินค้าแนะนำ)
          </label>
          <div className="settings-box">
            <div className="left-content">
              <input
                type="text"
                value={valueGp.settingValue}
                onKeyPress={(e) => regNumber(e)}
                onChange={(e) =>
                  setValueGp({
                    ...valueGp,
                    settingValue: e.target.value.trim(""),
                  })
                }
              />
              <span>%</span>
            </div>
            <button
              type="button"
              className="btn-confirm"
              onClick={() => handleSubmitForm(valueGp, "Gross profit")}
            >
              อัปเดต
            </button>
          </div>
          <label>
            ตั้งค่า Gross profit <br />
            (สินค้าแฟชั่น)
          </label>
          <div className="settings-box">
            <div className="left-content">
              <input
                type="text"
                value={valueGpFashion.settingValue}
                onKeyPress={(e) => regNumber(e)}
                onChange={(e) =>
                  setValueGpFashion({
                    ...valueGpFashion,
                    settingValue: e.target.value.trim(""),
                  })
                }
              />
              <span>%</span>
            </div>
            <button
              type="button"
              className="btn-confirm"
              onClick={() => handleSubmitForm(valueGpFashion, "Gross profit fashion")}
            >
              อัปเดต
            </button>
          </div>
          <label>
            บวกเพิ่มค่าเก็บปลายทาง <br />
            (COD สินค้าแฟชั่น)
          </label>
          <div className="settings-box">
            <div className="left-content">
              <input
                type="text"
                value={valueCODFashion.settingValue}
                onKeyPress={(e) => regNumber(e)}
                onChange={(e) =>
                  setValueCODFashion({
                    ...valueCODFashion,
                    settingValue: e.target.value?.trim(""),
                  })
                }
              />
              <span>บาท</span>
            </div>
            <button
              type="button"
              className="btn-confirm"
              onClick={() => handleSubmitForm(valueCODFashion, "COD สินค้าแฟชั่น")}
            >
              อัปเดต
            </button>
          </div>
          <label className="mt-4">ตั้งค่าลบสลิป</label>
          <div className="settings-box">
            <div className="left-content">
              <input
                type="text"
                value={valueSlip.settingValue}
                onKeyPress={(e) => regNumber(e)}
                onChange={(e) =>
                  setValueSlip({
                    ...valueSlip,
                    settingValue: e.target.value.trim(""),
                  })
                }
              />
              <span>เดือน</span>
            </div>
            <button
              type="button"
              className="btn-confirm"
              onClick={() => handleSubmitForm(valueSlip, "ลบสลิป")}
            >
              อัปเดต
            </button>
          </div>
          {false && (
            <Fragment>
              <label className="mt-4">ตั้งค่าลบแชท</label>
              <div className="settings-box">
                <div className="left-content">
                  <input
                    type="text"
                    value={valueChat.settingValue}
                    onKeyPress={(e) => regNumber(e)}
                    onChange={(e) =>
                      setValueChat({
                        ...valueChat,
                        settingValue: e.target.value.trim(""),
                      })
                    }
                  />
                  <span>วัน</span>
                </div>
                <button
                  type="button"
                  className="btn-confirm"
                  onClick={() => handleSubmitForm(valueChat, "ลบแชท")}
                >
                  อัปเดต
                </button>
              </div>
            </Fragment>
          )}
          <label className="mt-4">ตั้งค่าอีเมล</label>
          <div className="settings-box">
            <div className="left-content">
              <input
                type="text"
                value={valueEmail.settingValue}
                onChange={(e) =>
                  setValueEmail({
                    ...valueEmail,
                    settingValue: e.target.value.trim(""),
                  })
                }
              />
            </div>
            <button
              type="button"
              className="btn-confirm"
              onClick={() => handleSubmitForm(valueEmail, "อีเมล")}
            >
              อัปเดต
            </button>
          </div>
          <label className="mt-4">Key</label>
          <div className="settings-box">
            <div className="left-content">
              <input
                type="text"
                value={valueKey.settingValue}
                onChange={(e) =>
                  setValueKey({
                    ...valueKey,
                    settingValue: e.target.value.trim(""),
                  })
                }
              />
            </div>
            <button
              type="button"
              className="btn-confirm"
              onClick={() => handleSubmitForm(valueKey, "Key")}
            >
              อัปเดต
            </button>
          </div>
          <Fragment>
            <label className="mt-4">อัพโหลดคู่มือ</label>
            <div className="settings-box">
              <h2 className="head-title-file">
                <label
                  htmlFor="file-upload"
                  className={`custom-file-upload ${fileError ? "error" : ""}`}
                >
                  <i class="fa-solid fa-file"></i> {filename}
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept=".pdf"
                  ref={inputFile}
                  onChange={onSelectFile}
                />
              </h2>
              <button
                type="button"
                className="btn-upload-manual"
                onClick={uploadFile}
              >
                <i class="fa-solid fa-upload"></i>
              </button>
            </div>
          </Fragment>
        </div>
        <div className="column-setting">
          <div className="flex flex-col gap-2">
            <label className="">ร้านค้า</label>
            <div className="settings-box">
              <div className="forImage">
                <img
                  src={apiUrl + valueLineStore.extra_image}
                  ref={profileStore}
                  alt="image-first"
                  aria-hidden
                  onError={(e) => {
                    e.target.src = "/assets/profile.jpg";
                  }}
                />
                <label
                  className="text-xs text-gray-500"
                  htmlFor="line_store_pf"
                >
                  รูปโปรไฟล์
                </label>
                <input
                  id="line_store_pf"
                  type="file"
                  accept=".jpg,.jpeg,.png,.webp"
                  onChange={(e) => inputImageOnChange(e, valueLineStore, true)}
                />
                <i className="fa-solid fa-camera"></i>
                <div className="circle absolute"></div>
              </div>
              <div className="forImage">
                <img
                  src={apiUrl + valueLineStore.image}
                  ref={imageStore}
                  alt="image-first"
                  aria-hidden
                  onError={(e) => {
                    e.target.src = "/assets/empty.png";
                  }}
                />
                <label
                  className="text-xs text-gray-500"
                  htmlFor="line_store_qr"
                >
                  QR Code
                </label>
                <input
                  id="line_store_qr"
                  type="file"
                  accept=".jpg,.jpeg,.png,.webp"
                  onChange={(e) => inputImageOnChange(e, valueLineStore)}
                />
                <i className="fa-solid fa-camera"></i>
                <div className="circle absolute"></div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex rounded-md shadow-sm h-10 w-[15rem] sm:w-[9rem] ">
                  <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                    @
                  </span>
                  <input
                    className="text-center block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-pink-500 focus:ring-pink-500 sm:text-sm"
                    type="text"
                    value={valueLineStore.settingValue}
                    onChange={(e) =>
                      setValueLineStore({
                        ...valueLineStore,
                        settingValue: e.target.value.trim(""),
                      })
                    }
                  />
                </div>
                <div className="flex rounded-md shadow-sm h-10 w-[15rem] sm:w-[9rem] ">
                  <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-2 text-sm text-gray-500">
                    <i className="fa-solid fa-link"></i>
                  </span>
                  <input
                    className="text-center block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-pink-500 focus:ring-pink-500 sm:text-sm"
                    type="text"
                    value={valueLineStore.link}
                    onChange={(e) =>
                      setValueLineStore({
                        ...valueLineStore,
                        link: e.target.value.trim(""),
                      })
                    }
                  />
                </div>
              </div>
              <button
                type="button"
                onClick={() => handleSubmitForm(valueLineStore, "ไลน์ร้านค้า")}
                className="bg-green-600 hover:bg-green-700 text-white text-md rounded w-[4rem] h-10"
              >
                อัปเดต
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <label>ลูกค้าชาย</label>
            <div className="settings-box">
              <div className="forImage">
                <img
                  src={apiUrl + valueLineMen.extra_image}
                  ref={profileMen}
                  alt="image-second"
                  aria-hidden
                  onError={(e) => {
                    e.target.src = "/assets/profile.jpg";
                  }}
                />
                <label className="text-xs text-gray-500" htmlFor="line_men_pf">
                  รูปโปรไฟล์
                </label>
                <input
                  id="line_men_pf"
                  type="file"
                  accept=".jpg,.jpeg,.png,.webp"
                  onChange={(e) => inputImageOnChange(e, valueLineMen, true)}
                />
                <i className="fa-solid fa-camera"></i>
                <div className="circle absolute"></div>
              </div>
              <div className="forImage">
                <img
                  src={apiUrl + valueLineMen.image}
                  ref={imageMen}
                  alt="image-second"
                  aria-hidden
                  onError={(e) => {
                    e.target.src = "/assets/empty.png";
                  }}
                />
                <label className="text-xs text-gray-500" htmlFor="line_men_qr">
                  QR Code
                </label>
                <input
                  id="line_men_qr"
                  type="file"
                  accept=".jpg,.jpeg,.png,.webp"
                  onChange={(e) => inputImageOnChange(e, valueLineMen)}
                />
                <i className="fa-solid fa-camera"></i>
                <div className="circle absolute"></div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex rounded-md shadow-sm h-10 w-[15rem] sm:w-[9rem] ">
                  <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                    @
                  </span>
                  <input
                    className="text-center block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-pink-500 focus:ring-pink-500 sm:text-sm"
                    type="text"
                    value={valueLineMen.settingValue}
                    onChange={(e) =>
                      setValueLineMen({
                        ...valueLineMen,
                        settingValue: e.target.value.trim(""),
                      })
                    }
                  />
                </div>
                <div className="flex rounded-md shadow-sm h-10 w-[15rem] sm:w-[9rem] ">
                  <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-2 text-sm text-gray-500">
                    <i className="fa-solid fa-link"></i>
                  </span>
                  <input
                    className="text-center block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-pink-500 focus:ring-pink-500 sm:text-sm"
                    type="text"
                    value={valueLineMen.link}
                    onChange={(e) =>
                      setValueLineMen({
                        ...valueLineMen,
                        link: e.target.value.trim(""),
                      })
                    }
                  />
                </div>
              </div>
              <button
                type="button"
                onClick={() => handleSubmitForm(valueLineMen, "ไลน์ลูกค้าชาย")}
                className="bg-green-600 hover:bg-green-700 text-white text-md rounded w-[4rem] h-10"
              >
                อัปเดต
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <label>ลูกค้าหญิง</label>
            <div className="settings-box">
              <div className="forImage">
                <img
                  src={apiUrl + valueLineWomen.extra_image}
                  ref={profileWomen}
                  alt="image-third"
                  aria-hidden
                  onError={(e) => {
                    e.target.src = "/assets/profile.jpg";
                  }}
                />
                <label
                  className="text-xs text-gray-500"
                  htmlFor="line_women_pf"
                >
                  รูปโปรไฟล์
                </label>
                <input
                  id="line_women_pf"
                  type="file"
                  accept=".jpg,.jpeg,.png,.webp"
                  onChange={(e) => inputImageOnChange(e, valueLineWomen, true)}
                />
                <i className="fa-solid fa-camera"></i>
                <div className="circle absolute"></div>
              </div>
              <div className="forImage">
                <img
                  src={apiUrl + valueLineWomen.image}
                  ref={imageWomen}
                  alt="image-third"
                  aria-hidden
                  onError={(e) => {
                    e.target.src = "/assets/empty.png";
                  }}
                />
                <label
                  className="text-xs text-gray-500"
                  htmlFor="line_women_qr"
                >
                  QR Code
                </label>
                <input
                  id="line_women_qr"
                  type="file"
                  accept=".jpg,.jpeg,.png,.webp"
                  onChange={(e) => inputImageOnChange(e, valueLineWomen)}
                />
                <i className="fa-solid fa-camera"></i>
                <div className="circle absolute"></div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex rounded-md shadow-sm h-10 w-[15rem] sm:w-[9rem] ">
                  <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                    @
                  </span>
                  <input
                    className="text-center block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-pink-500 focus:ring-pink-500 sm:text-sm"
                    type="text"
                    value={valueLineWomen.settingValue}
                    onChange={(e) =>
                      setValueLineWomen({
                        ...valueLineWomen,
                        settingValue: e.target.value.trim(""),
                      })
                    }
                  />
                </div>
                <div className="flex rounded-md shadow-sm h-10 w-[15rem] sm:w-[9rem] ">
                  <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-2 text-sm text-gray-500">
                    <i className="fa-solid fa-link"></i>
                  </span>
                  <input
                    className="text-center block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-pink-500 focus:ring-pink-500 sm:text-sm"
                    type="text"
                    value={valueLineWomen.link}
                    onChange={(e) =>
                      setValueLineWomen({
                        ...valueLineWomen,
                        link: e.target.value.trim(""),
                      })
                    }
                  />
                </div>
              </div>
              <button
                type="button"
                onClick={() =>
                  handleSubmitForm(valueLineWomen, "ไลน์ลูกค้าหญิง")
                }
                className="bg-green-600 hover:bg-green-700 text-white text-md rounded w-[4rem] h-10"
              >
                อัปเดต
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <label>ลูกค้าทั่วไป</label>
            <div className="settings-box">
              {/* Line Profile  */}
              <div className="forImage">
                <img
                  src={apiUrl + valueLineCustomer.extra_image}
                  ref={profileCustomer}
                  alt="image-fourth"
                  aria-hidden
                  onError={(e) => {
                    e.target.src = "/assets/profile.jpg";
                  }}
                />
                <label
                  className="text-xs text-gray-500"
                  htmlFor="line_member_pf"
                >
                  รูปโปรไฟล์
                </label>
                <input
                  id="line_member_pf"
                  type="file"
                  accept=".jpg,.jpeg,.png,.webp"
                  onChange={(e) =>
                    inputImageOnChange(e, valueLineCustomer, true)
                  }
                />
                <i className="fa-solid fa-camera"></i>
                <div className="circle absolute"></div>
              </div>
              <div className="forImage">
                <img
                  src={apiUrl + valueLineCustomer.image}
                  ref={imageCustomer}
                  alt="image-fourth"
                  aria-hidden
                  onError={(e) => {
                    e.target.src = "/assets/empty.png";
                  }}
                />
                <label
                  className="text-xs text-gray-500"
                  htmlFor="line_member_qr"
                >
                  QR Code
                </label>
                <input
                  id="line_member_qr"
                  type="file"
                  accept=".jpg,.jpeg,.png,.webp"
                  onChange={(e) => inputImageOnChange(e, valueLineCustomer)}
                />
                <i className="fa-solid fa-camera"></i>
                <div className="circle absolute"></div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex rounded-md shadow-sm h-10 w-[15rem] sm:w-[9rem] ">
                  <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                    @
                  </span>
                  <input
                    className="text-center block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-pink-500 focus:ring-pink-500 sm:text-sm"
                    type="text"
                    value={valueLineCustomer.settingValue}
                    onChange={(e) =>
                      setValueLineCustomer({
                        ...valueLineCustomer,
                        settingValue: e.target.value.trim(""),
                      })
                    }
                  />
                </div>
                <div className="flex rounded-md shadow-sm h-10 w-[15rem] sm:w-[9rem] ">
                  <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-2 text-sm text-gray-500">
                    <i className="fa-solid fa-link"></i>
                  </span>
                  <input
                    className="text-center block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-pink-500 focus:ring-pink-500 sm:text-sm"
                    type="text"
                    value={valueLineCustomer.link}
                    onChange={(e) =>
                      setValueLineCustomer({
                        ...valueLineCustomer,
                        link: e.target.value.trim(""),
                      })
                    }
                  />
                </div>
              </div>
              <button
                type="button"
                onClick={() =>
                  handleSubmitForm(valueLineCustomer, "ไลน์ลูกค้าทั่วไป")
                }
                className="bg-green-600 hover:bg-green-700 text-white text-md rounded w-[4rem] h-10"
              >
                อัปเดต
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="second-rows mt-8">
        <div className="column-setting">
          <label>ตั้งค่าการตรวจสอบโพสต์ร้านค้า</label>
          <div className="settings-box">
            <p className="opacity-70">กำหนดเอง</p>
            <label className="inline-flex items-center relative cursor-pointer ml-4 mr-4">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={valueVerify.settingValue === "yes" ? true : false}
                onChange={updateVerify}
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600" />
            </label>
            <p className="opacity-70">อัตโนมัติ</p>
          </div>

          <GPSettingTable />
        </div>
        <div className="column-setting">
          <div className="web_extra_setting">
            <label>เว็ปไซต์สำหรับสินค้าต่างประเทศ</label>
            <div className="settings-box">
              <p className="opacity-70">ปิดการแสดง</p>
              <label className="inline-flex items-center relative cursor-pointer ml-4 mr-4">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={valueWebExtra.display === "yes" ? true : false}
                  onChange={updateWebExtraDisplay}
                />
                <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600" />
              </label>
              <p className="opacity-70">เปิดการแสดง</p>
            </div>
            <div className="">
              {/* <label className="">ลูกค้าทั่วไป</label> */}
              <div className="settings-box-webextra">
                <div className="forImage">
                  <img
                    src={apiUrl + valueWebExtra.image}
                    ref={webExtraBanner}
                    alt="web-extra-banner"
                    aria-hidden
                    onError={(e) => {
                      e.target.src = "/assets/empty.png";
                    }}
                  />
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png,.webp"
                    onChange={(e) => inputBannerOnChange(e, valueWebExtra)}
                  />
                  <i className="fa-solid fa-camera"></i>
                  <div className="circle absolute"></div>
                </div>
                <div className="flex items-center gap-4">
                  <div className="flex rounded-md shadow-sm h-10 w-[14rem] ">
                    <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                      <i className="fa-solid fa-link"></i>
                    </span>
                    <input
                      className="text-center block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-pink-500 focus:ring-pink-500 sm:text-sm"
                      type="text"
                      placeholder="https://example.com"
                      value={valueWebExtra.settingValue}
                      onChange={(e) =>
                        setValueWebExtra({
                          ...valueWebExtra,
                          settingValue: e.target.value.trim(""),
                        })
                      }
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() =>
                      handleSubmitForm(
                        valueWebExtra,
                        "เว็ปไซต์สำหรับสินค้าต่างประเทศ"
                      )
                    }
                    className="bg-green-600 hover:bg-green-700 text-white text-md rounded w-[4rem] h-10"
                  >
                    อัปเดต
                  </button>
                </div>
              </div>
            </div>
          </div>
          {false && (
            <Fragment>
              <label>ตั้งค่าคำต้องห้าม</label>
              <div className="mt-4">
                {reservedWord.settingValue.split(",").map((data, index) => {
                  if (data !== "") {
                    return (
                      <React.Fragment key={index}>
                        <div className="tag-reserved-word text-sm leading-sm">
                          {data}
                          <i
                            className="fa-solid fa-minus ml-2"
                            onClick={() => removeTag(data)}
                          ></i>
                        </div>
                      </React.Fragment>
                    );
                  }
                })}
                <div
                  className="tag-reserved-word addword text-sm leading-md"
                  onClick={() => openSwlInput()}
                >
                  เพิ่ม
                  <i className="fa-solid fa-plus ml-2"></i>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

function regNumber(e) {
  let regExp = new RegExp(/^[0-9]$/);
  if (!regExp.test(e.key)) {
    e.preventDefault();
  }
}