import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from "./Table";
import st from "../../styles/allUse/content.module.scss";
import { Listbox, Transition } from "@headlessui/react";

export default function Account() {
  const apiUrl = useSelector((state) => state.app.apiPath);
  const access_token = useSelector((state) => state.app.access_token);
  const [memberList, setMemberList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectType, setSelectType] = useState();
  const [refreshData, setRefreshData] = useState(0);
  const [selectStatus, setSelectStatus] = useState();
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    apiGetMember();
  }, [refreshData]);

  async function apiGetMember() {
    await axios({
      method: "GET",
      url: `${apiUrl}/api/admin/member/get`,
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    }).then((res) => {
      if (res.data.status) {
        setMemberList(res.data.data);
        setFilteredData(res.data.data);
      }
    });
  }

  useEffect(() => {
    if (memberList) {
      setSelectStatus({ statusMember: memberList.statusMember });
    }
  }, []);

  useEffect(() => {
    setPage(1);
    const result = memberList.filter((el) => {
      if (searchText !== "") {
        if (el.username.toLowerCase().includes(searchText)) {
          return el;
        }
      } else {
        return el;
      }
    });
    const result2 = result.filter((type) => type.isStore === selectType);
    if (selectType === "all") {
        setFilteredData(result);
    } else {
        setFilteredData(result2);
    }
  }, [searchText, selectType]);

  return (
    <div className={`main-page ${st.content} animate-[fade_0.3s_ease-in-out]`} style={{paddingTop: '5rem', paddingBottom: '5rem'}}>
      <p className={st.title}>จัดการผู้ใช้ </p>
      <div className={st.searchText}>
        <div className="w-20 h-full absolute right-60 z-10">
          <ChooseType selectType={selectType} setSelectType={setSelectType} />
        </div>
        <input
          maxLength={10}
          onChange={(e) => setSearchText(e.target.value)}
          type="text"
          placeholder="ค้นหาชื่อผู้ใช้"
          style={{ margin: "0 0 0 auto" }}
        />
        <i className="fa-solid fa-magnifying-glass"></i>
      </div>
      <div className={`${st.contentTable}`}>
        {filteredData?.length > 0 && (
          <Table
            onRefresh={() => setRefreshData(refreshData + 1)}
            filteredData={filteredData}
            rowsPerPage={14}
            apiGetAll={apiGetMember}
            page={page}
            setPage={setPage}
            selectStatus={selectStatus} 
            setSelectStatus={setSelectStatus} 
          />
        )}
      </div>
    </div>
  );
}

function ChooseType({ selectType, setSelectType }) {
  const type = [
    { name: "ทั้งหมด", isStore: "all" },
    { name: "ลูกค้า", isStore: "no" },
    { name: "ร้านค้า", isStore: "yes" }
  ];
  if (selectType === undefined) {
    selectType = "all";
  }
  return (
    <>
      <Listbox value={selectType} onChange={setSelectType}>
        <div className="relative h-full">
          <Listbox.Button className="h-full bg-gray-100 appearance-none border-2 pr-5 border-gray-100 rounded w-full py-1 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-pink-500">
            <div className="h-full flex flex-row justify-center items-center">
              <span className="truncate text-left">
                {selectType === "all" && "ทั้งหมด"}
                {selectType === "no" && "ลูกค้า"}
                {selectType === "yes" && "ร้านค้า"}
              </span>
              <i
                className="absolute mt-[2px] font-bold fa-solid fa-arrows-up-down"
                style={{ top: "27%" }}
              ></i>
            </div>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {type?.map((data, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 ${
                      active ? "bg-pink-100 text-pink-900" : "text-gray-900"
                    }`
                  }
                  value={data.isStore}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate align-left ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {data.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-pink-600"></span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </>
  );
}
