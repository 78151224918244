import React from "react";
import st from "../../styles/allUse/table.module.scss";

function PurchaseModal({ isOpen, onClose }) {
  if (!isOpen) return null;
  return (
    <div className={st.modalOverlay}>
      <div className={st.modal}>
        <button className={st.closeButton} onClick={onClose}>
          &times;
        </button>
        <div className={st.contentModal}></div>
      </div>
    </div>
  );
}

export default PurchaseModal;
