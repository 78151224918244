import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function PackageEditor({
  setPackageEdit,
  editorLoaded,
  name,
  packageEdit
}) {
  return (
    <div>
      {editorLoaded ? (
        <CKEditor
          name={name}
          editor={ClassicEditor}
          data={packageEdit.content}
          config={{
            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"]
          }}
          onChange={(event, editor) => {
            setPackageEdit((prev) => ({
              ...prev,
              content: editor.getData()
            }));
          }}
        />
      ) : (
        <div>Editor loading</div>
      )}
    </div>
  );
}
