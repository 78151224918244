import { createSlice } from "@reduxjs/toolkit";

const initialAppState = {
    apiPath: process.env.REACT_APP_API_PATH,
    socketPath: process.env.REACT_APP_SOCKET_PATH,
    isLogin: !!localStorage.getItem('accessToken'),
    refresh_token: (localStorage.getItem('refreshToken')?localStorage.getItem('refreshToken'):null),
    access_token: (localStorage.getItem('accessToken')?localStorage.getItem('accessToken'):null),
}

const appSlice = createSlice({
    name: 'app',
    initialState: initialAppState,
    reducers: {
        login(state, action) {
            state.isLogin = true
            state.access_token = action.payload.access_token
            state.refresh_token = action.payload.refresh_token
        },
        logout(state, action) {
            state.isLogin = false
            state.access_token = ''
            state.refresh_token = ''
            localStorage.clear()
        },
        checkToken(state, action) { 
            state.isLogin = true
            state.access_token = action.payload
            state.refresh_token = localStorage.getItem('refreshToken')

            localStorage.removeItem('accessToken')
            localStorage.setItem('accessToken', action.payload)
        }
    }
})


export const appAction = appSlice.actions;
export default appSlice;