import axios from "axios";
import Chart from "chart.js/auto";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import st from "../../styles/dashboard/dashboard.module.scss";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

const { io } = require("socket.io-client");
const orderTypes = [
  { id: 1, name: "ออเดอร์ชุดชั้นใน", preName: "ชุดชั้นใน" },
  { id: 2, name: "ออเดอร์แฟชั่น", preName: "แฟชั่น" },
];

export default function Dashboard() {
  const access_token = useSelector((state) => state.app.access_token);
  const apiSocket = useSelector((state) => state.app.socketPath);
  const apiUrl = useSelector((state) => state.app.apiPath);
  const [orderListShow, setOrderListShow] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [orderFashionList, setOrderFashionList] = useState([]);
  const [memberPerMonth, setMemberPerMonth] = useState([]);
  const [totalPerPack, setTotalPerPack] = useState([]);
  const [orderShow, setOrderShow] = useState([]);
  const [todayOrder, setTodayOrder] = useState([]);
  const [todayOrderFashion, setTodayOrderFashion] = useState([]);
  const [isFetchSuccess, setIsFetchSuccess] = useState(false);
  const [topDashboard, setTopDashboard] = useState({});
  const [onReceiveChat, setOnReceiveChat] = useState(true);

  const [selected, setSelected] = useState(orderTypes[0]);
  // const socket = io(apiSocket);
  const chart = useRef();
  const chartMember = useRef();

  useEffect(() => {
    if (selected.id === 1) {
      setOrderShow(todayOrder);
      setOrderListShow(orderList);
    } else if (selected.id === 2) {
      setOrderShow(todayOrderFashion);
      setOrderListShow(orderFashionList);
    }
  }, [orderFashionList, orderList, selected]);

  useEffect(() => {
    if (onReceiveChat) {
      apiGetDashboard();
    }
    if (isFetchSuccess) {
      polarChart();
      chartMemberPerMonth();
    }
  }, [isFetchSuccess, onReceiveChat, topDashboard]);

  // socket.on("connect", () => {});
  // socket.on("admin", (req, res) => {
  //   if (req) {
  //     setOnReceiveChat(true);
  //   }
  // });

  async function apiGetDashboard() {
    setOnReceiveChat(false);
    await axios({
      method: "GET",
      url: `${apiUrl}/api/admin/getDashboard`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }).then((res) => {
      if (res.data.status) {
        let orderSuccess = [];
        let orderFashionSuccess = [];
        const dataTopSeller = res.data.orderAll.sort(
          (a, b) => b.totalPrice - a.totalPrice
        );
        const dataTopSellerFashion = res.data.orderAllFashion.sort(
          (a, b) => b.totalPrice - a.totalPrice
        );
        for (let i = 0; i < 10; i++) {
          if (dataTopSeller[i]) {
            orderSuccess.push(dataTopSeller[i]);
          }
        }
        for (let i = 0; i < 10; i++) {
          if (dataTopSellerFashion[i]) {
            orderFashionSuccess.push(dataTopSellerFashion[i]);
          }
        }
        setOrderList(orderSuccess);
        setOrderFashionList(orderFashionSuccess);
        setMemberPerMonth(res.data.memberPerMonth);
        setTotalPerPack(res.data.totalPerPack);
        setTodayOrder(res.data.toDayOrder);
        setTodayOrderFashion(res.data.toDayOrderFashion);
        setTopDashboard(res.data.forTopDashboard);
        setIsFetchSuccess(true);
      }
    });
  }

  function polarChart() {
    let label = [];
    let data = [];
    for (let item of totalPerPack) {
      label.push(item.packageName);
      data.push(item.totalMember);
    }
    if (data.length > 0) {
      new Chart(chart.current, {
        type: "polarArea",
        data: {
          labels: label,
          datasets: [
            {
              // label: '# of Votes',
              data: data,
              backgroundColor: [
                "rgba(255, 99, 132, 0.8)",
                "rgba(54, 162, 235, 0.8)",
                "rgba(255, 206, 86, 0.8)",
                "rgba(75, 192, 192, 0.8)",
                "rgba(153, 102, 255, 0.8)",
                "rgba(255, 159, 64, 0.8)",
              ],
              borderColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    }
  }
  function chartMemberPerMonth() {
    let label = [];
    let data = [];
    for (let item of memberPerMonth) {
      label.push(item.month);
      data.push(item.total);
    }
    if (data.length > 0) {
      new Chart(chartMember.current, {
        type: "bar",
        data: {
          labels: label,
          datasets: [
            {
              // label: label,
              data: data,
              backgroundColor: [
                "rgba(251, 36, 36, 0.7)",
                "rgba(54, 162, 235, 0.7)",
                "rgba(255, 206, 86, 0.7)",
                "rgba(75, 192, 192, 0.7)",
                "rgba(153, 102, 255, 0.7)",
                "rgba(255, 159, 64, 0.7)",
              ],
              borderColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    }
  }

  return (
    <div className={` ${st.content}`}>
      <div
        className={`main-page ${st.contentBody} animate-[fade_0.3s_ease-in-out]`}
      >
        <p className={st.title}></p>
        <div className={st.flexCol}>
          <div className={st.flexRow}>
            <Link to="/payment" className="w-1/4  sm:w-full">
              <div
                className={`${st.box} hover:shadow-red-100 hover:cursor-pointer hover:scale-105`}
              >
                <div className={`${st.boxBg} bg-red-500/80`}>
                  <i className="text-white text-4xl hover:text-[50px] duration-200 fa-solid fa-users"></i>
                  <p className="text-white text-xl font-bold">
                    {topDashboard.newMember}
                  </p>
                </div>
                <div className={`${st.boxFooter} bg-red-500/50`}>
                  <p className="text-white font-bold">MEMBER</p>
                </div>
              </div>
            </Link>
            <Link to="/store" className="w-1/4  sm:w-full">
              <div
                className={`${st.box} hover:shadow-green-100 hover:cursor-pointer hover:scale-105`}
              >
                <div className={`${st.boxBg} bg-green-500/80 `}>
                  <i className="text-white text-4xl hover:text-[50px] duration-200 fa-solid fa-store"></i>
                  <p className="text-white text-xl font-bold">
                    {topDashboard.newStore}
                  </p>
                </div>
                <div className={`${st.boxFooter} bg-green-500/50 `}>
                  <p className="text-white font-bold">STORE</p>
                </div>
              </div>
            </Link>
            <Link to="/order" className="w-1/4  sm:w-full">
              <div
                className={`${st.box} hover:shadow-yellow-100 hover:cursor-pointer hover:scale-105`}
              >
                <div className={`${st.boxBg} bg-yellow-500/80`}>
                  <i className="text-white text-4xl hover:text-[50px] duration-200 fa-solid fa-cart-arrow-down"></i>
                  <p className="text-white text-xl font-bold">
                    {topDashboard.newOrder}
                  </p>
                </div>
                <div className={`${st.boxFooter} bg-yellow-500/50`}>
                  <p className="text-white font-bold">ORDER</p>
                </div>
              </div>
            </Link>
            <Link to="/orderfashion" className="w-1/4  sm:w-full">
              <div
                className={`${st.box} hover:shadow-purple-100 hover:cursor-pointer hover:scale-105`}
              >
                <div className={`${st.boxBg} bg-purple-500/80`}>
                  <i className="text-white text-4xl hover:text-[50px] duration-200 fa-solid fa-cart-arrow-down"></i>
                  <p className="text-white text-xl font-bold">
                    {topDashboard.newOrderFashion}
                  </p>
                </div>
                <div className={`${st.boxFooter} bg-purple-500/50 `}>
                  <p className="text-white font-bold">ORDER FASHION</p>
                </div>
              </div>
            </Link>
          </div>
          <div className={st.flexTopRow}>
            <div className={st.orderList}>
              <div className={st.box}>
                <div className={st.titleBest}>
                  <ListboxOrder selected={selected} setSelected={setSelected} />
                </div>
                <div className={st.overFlow}>
                  <>
                    <div className="flex flex-row justify-between mb-4 bg-gray-200/50 p-1">
                      <div className="">เลขออเดอร์</div>
                      <div className="">การชำระเงิน</div>
                      <div className="">สถานะขนส่ง</div>
                      <div className="">ราคารวม</div>
                    </div>
                    {orderShow?.map((data, index) => (
                      <div key={index}>
                        <div
                          key={index}
                          className="flex flex-row justify-between mb-2 mx-2"
                        >
                          <div className="w-10">{data.orderNumber}</div>
                          <div
                            className={`  text-center pl-3
                                                     ${
                                                       data.paymentStatus ===
                                                         "pending" &&
                                                       "text-yellow-500"
                                                     }
                                                     ${
                                                       data.paymentStatus ===
                                                         "confirm" &&
                                                       "text-green-500"
                                                     }
                                                     ${
                                                       data.paymentStatus ===
                                                         "failed" &&
                                                       "text-red-500 "
                                                     }
                                                    `}
                          >
                            {data.paymentStatus === "pending" && "รอการยืนยัน"}
                            {data.paymentStatus === "confirm" && "ยืนยันแล้ว"}
                            {data.paymentStatus === "failed" && "ผิดพลาด"}{" "}
                          </div>
                          <div
                            className={` text-center pr-5
                                                        ${
                                                          data.status ===
                                                            "pending" &&
                                                          "text-yellow-500"
                                                        }
                                                        ${
                                                          data.status ===
                                                            "shipping" &&
                                                          "text-orange-500"
                                                        }
                                                        ${
                                                          data.status ===
                                                            "success" &&
                                                          "text-green-500"
                                                        }
                                                        ${
                                                          data.status ===
                                                            "failed" &&
                                                          "text-red-500 "
                                                        }
                                                         `}
                          >
                            {data.status === "pending" && "กำลังดำเนินการ"}
                            {data.status === "shipping" && "กำลังส่ง"}
                            {data.status === "success" && "ส่งแล้ว"}
                            {data.status === "failed" && "ผิดลาด"}
                          </div>
                          <div>{data.totalPrice}</div>
                        </div>{" "}
                        <hr />
                      </div>
                    ))}
                  </>
                </div>
              </div>
            </div>
            <div className={st.bestSeller}>
              <div className={st.box}>
                <div className={st.titleBest}>
                  10 อันดับร้านค้าขายดี ({selected.preName})
                </div>
                <div className={st.overFlow}>
                  <>
                    <div className="flex flex-row justify-between mb-4 bg-gray-200/50 ">
                      <div className="w-14">
                        <p>ลำดับที่</p>
                      </div>
                      <div className="w-32">
                        <p>ร้านค้า</p>
                      </div>
                      <div className="w-10">
                        <p>จำนวน</p>
                      </div>
                      <div className="w-28">
                        <p>ยอดรวม</p>
                      </div>
                    </div>
                    {orderListShow?.map((data, index) => (
                      <div key={index}>
                        <div className="flex flex-row justify-between mb-2 mx-4">
                          <div className="w-10">
                            <p>{index + 1}</p>
                          </div>
                          <div className="w-32">
                            <p>{data.name}</p>
                          </div>
                          <div className="w-10">
                            <p>{data.totalProductSold}</p>
                          </div>
                          <div className="w-28">
                            <p>{data.totalPrice}</p>
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))}
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className={st.flexBottomRow}>
            <div className={st.barChart}>
              <div className={st.chart}>
                ผู้ใช้งานที่สมัคร ต่อเดือน
                <canvas ref={chartMember}></canvas>
              </div>
            </div>
            <div className={st.polarChart}>
              <div className={st.chart}>
                <div>
                  จำนวนคนต่อแพ็คเก็จ
                  <canvas ref={chart}></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ListboxOrder({ selected, setSelected }) {
  return (
    <div className="w-64">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <Listbox.Button className="cursor-pointer relative w-full rounded-lg bg-white py-2 pl-3 pr-10 text-center shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">{selected.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {orderTypes.map((order, orderIdx) => (
                <Listbox.Option
                  key={orderIdx}
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                      active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                    }`
                  }
                  value={order}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {order.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
