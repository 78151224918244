import React from 'react'
import axios from 'axios'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useEffect } from 'react'

const ForgetPassword = () => {
    const apiUrl = useSelector((state) => (state.app.apiPath))
    const query = new URLSearchParams(useLocation().search);
    const navigate = useNavigate()
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [accessToken, setAccessToken] = useState(query.get('token'))
    const [isToken, setIsToken] = useState(false)

    useEffect(() => {
        checkToken()
    }, [])

    async function checkToken() {
        try {
            await axios({
                method: 'GET',
                url: `${apiUrl}/api/admin/checkReset/${accessToken}`
            }).then((res) => {
                if (res.data.status) {
                    setIsToken(true)
                } else {
                    setIsToken(false)
                }
            })
        } catch (err) {
            // console.log(err);
        }
    }
    async function handleChangePassword() {
        if (password === "" || confirmPassword === "") {
            Swal.fire({
                icon: 'warning',
                position: 'center',
                title: 'กรุณากรอกรหัสผ่าน',
                timer: 1500,
                showConfirmButton: false
            })
            return false;
        }
        if (password !== confirmPassword) {
            Swal.fire({
                icon: 'warning',
                position: 'center',
                title: 'รหัสผ่านไม่ถูกต้อง',
                timer: 1500,
                showConfirmButton: false
            })
            return false;
        }

        try {
            await axios({
                method: 'POST',
                url: `${apiUrl}/api/admin/updatePassword`,
                data: {
                    newPassword: password,
                    token: accessToken
                }
            }).then((res) => {
                if (res.data.status) {
                    Swal.fire({
                        icon: 'success',
                        position: 'center',
                        title: 'สำเร็จ',
                        text : 'กรุณาล็อกอินใหม่อีกครั้ง',
                        timer: 1500,
                        showConfirmButton: false
                    }).then(() => {
                        navigate('/login')
                    })
                }
            })
        } catch (err) {
            // console.log(err);
        }
    }

    return (
        <div className="grid grid-cols-1 h-screen relative" >
            <div className="bg-white opacity-10 absolute top-0 w-[10rem] h-[10rem] rounded-br-full"></div>
            <div className="bg-white opacity-10 absolute bottom-0 right-0 w-[10rem] h-[10rem] rounded-tl-full"></div>
            <img
                className="imageBg absolute mix-blend-overlay w-full h-full object-cover "
                src="./assets/background/art2.jpg"
                alt="login-bg"
            />
            {isToken &&
                <div className=" lg:mx-auto lg:w-[30%] sm:h-screen md:rounded-sm lg:rounded-lg lg:my-auto bg-gray-800 flex flex-col justify-center duration-200">
                    <div className="sm:rounded-lg md:rounded-lg sm:mx-2 md:mx-[10%] sm:bg-gray-700 duration-200 ">
                        <form className="relative max-w-[380px] w-full mx-auto p-8 px-8 rounded-lg  contrast-200">
                            <h2 className="text-4xl my-4 text-white font-bold text-center">
                                เปลี่ยนรหัสผ่าน
                            </h2>
                            <div className="flex flex-col text-gray-100 py-2">
                                <label className="text-start ml-2">Password</label>
                                <input
                                    type="password"
                                    defaultValue={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="rounded-lg bg-gray-600 mt-2 p-2 focus:border-pink-500 focus:bg-white-800 focus:outline-none"
                                />
                            </div>
                            <div className="flex flex-col text-gray-100 py-2">
                                <label className="text-start ml-2">Confirm Password</label>
                                <input
                                    type="password"
                                    defaultValue={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    className="rounded-lg bg-gray-600 mt-2 p-2 focus:border-pink-500 focus:bg-white-800 focus:outline-none"
                                />
                            </div>
                            <button
                                type="button"
                                style={{ boxShadow: "-2px 10px 17px 0px rgb(255 139 142 / 20%" }}
                                className="shadow-transparent rounded-lg w-full my-5 py-2 bg-pink-700 font-bold text-white hover:bg-pink-900 hover:boder hover:boder-white"
                                onClick={handleChangePassword}
                            >
                                ยืนยัน
                            </button>
                        </form>
                    </div>
                </div>
            }
        </div>
    )
}

export default ForgetPassword;