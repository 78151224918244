import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import Editor from "./editor";
import Swal from "sweetalert2";
import st from "../../../../styles/content/contentDetail.module.scss";
import Button from "@mui/material/Button";
import Loading from "../../../loadingPage/loading";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 800,
  timerProgressBar: true,
});

export default function ContentDetail() {
  const query = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const apiUrl = useSelector((state) => state.app.apiPath);
  const access_token = useSelector((state) => state.app.access_token);
  const [contentType, setContentType] = useState(query.get("type"));
  const [imageObj, setImageobj] = useState();
  const inputProfileImage = useRef([]);
  const [id, setId] = useState();
  const [title, setTitle] = useState();
  const [h1, setH1] = useState();
  const [h2, setH2] = useState();
  const [type, setType] = useState();
  const [content, setContent] = useState();
  const [imageLink, setImageLink] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const inputVideo = useRef([]);
  const [videoFile, setVideoFile] = useState([]);
  const [isFile, setIsFile] = useState(false);
  const [pathUrl, setPathUrl] = useState("");
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setEditorLoaded(true);
    apiGetContent();
  }, []);

  async function apiGetContent() {
    await axios({
      method: "GET",
      url: `${apiUrl}/api/admin/content/get`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }).then((res) => {
      for (let content of res.data.content) {
        if (contentType === content.type) {
          setId(content.id);
          setTitle(content.title);
          setH1(content.h1);
          setH2(content.h2);
          setType(content.type);
          setContent(content.content);
          setImageLink(content.image_link);
          setVideoLink(content.video_link);
          setPathUrl(content.video_link);
          setIsFile(content.isFile);
        }
      }
    });
  }

  function handleEdit() {
    const formData = new FormData();
    const fomDataVideo = new FormData();
    formData.append("id", id);
    formData.append("title", title);
    formData.append("h1", h1);
    formData.append("h2", h2);
    formData.append("type", type);
    formData.append("content", content);
    Swal.fire({
      title: "ยืนยันการแก้ไข",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      confirmButtonColor: "#C93A87",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (isFile) {
          if (videoFile.length > 0) {
            fomDataVideo.append("video", inputVideo.current.files[0]);
            fomDataVideo.append("pathUrl", "");
          }
        } else {
          fomDataVideo.append("video", "");
          fomDataVideo.append("pathUrl", pathUrl);
        }
        fomDataVideo.append("isFile", isFile);
        fomDataVideo.append("id", id);
        await apiUpdateVideo(fomDataVideo);
        await apiEditContent(formData);
      }
    });
  }

  async function apiUpdateVideo(fomDataVideo) {
    // if (isLoading) {
    //   Swal.fire({
    //     title: "กำลังอัปโหลด ",
    //     text: "กรุณารอสักครู่",
    //     timerProgressBar: true,
    //     allowOutsideClick: false,
    //     allowEscapeKey: false,

    //     didOpen: () => {
    //       Swal.showLoading();
    //       const b = Swal.getHtmlContainer().querySelector("b");
    //     }
    //   }).then((result) => {
    //     if (result.dismiss === Swal.DismissReason.timer) {
    //     }
    //   });
    // }
    setIsLoading(true);

    axios({
      method: "POST",
      url: `${apiUrl}/api/admin/content/changeVideo`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: fomDataVideo,
    }).then((res) => {
      if (res.data.status) {
        setIsLoading(false);
      }
    });
  }

  async function apiEditContent(formData) {
    await axios({
      method: "POST",
      url: `${apiUrl}/api/admin/content/update`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: formData,
    }).then((res) => {
      if (res.data.status) {
        Toast.fire({
          icon: "success",
          title: "แก้ไขแล้ว",
        });
      }
    });
  }

  function inputImageOnChange(e) {
    if (!e.target.files.length) {
      return false;
    }
    if (
      ["image/jpeg", "iamge/jpg", "image/png", "image/webp"].includes(
        e.target.files[0].type
      )
    ) {
      const URLs = URL.createObjectURL(e.target.files[0]);
      setImageobj(URLs);
    } else {
      Swal.fire({
        title: "กรุณาอัปโหลดเฉพาะไฟล์รูปภาพ",
        icon: "warning",
        position: "center",
        timer: 1000,
        showConfirmButton: false,
      });
    }
  }

  function inputVideoOnChange(e) {
    if (!e.target.files.length) {
      return false;
    }
    if (e.target.files[0].type) {
      const URLs = URL.createObjectURL(e.target.files[0]);
      setVideoFile(URLs);
    }
  }

  return (
    <div
      className={`main-page ${st.content} animate-[fade_0.3s_ease-in-out]`}
      style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
    >
      <p className={st.contentType}>
        {contentType === "terms-of-service" && "Terms of service"}
        {contentType === "privacy-policy" && "Privacy policy"}
        {contentType === "home" && "หน้าแรก"}
        {contentType === "term-member" && "สมัครสมาชิก"}
        {contentType === "term-store" && "สมัครร้านค้า"}
        {contentType === "email-website" && "Email"}
        {contentType === "term-store-post" && "ข้อกำหนดร้านค้า"}
        {contentType === "store-men" && "ร้านค้าชาย"}
        {contentType === "store-women" && "ร้านค้าหญิง"}
        {contentType === "cart-order" && "ตะกร้าสินค้า"}
        {contentType === "fashion" && "หน้าแรกสินค้าแฟชั่น"}
        {contentType === "fashion-category" && "หมวดหมู่สินค้า"}
        {contentType === "fashion-product-list" && "สินค้าแฟชั่น"}
        {contentType === "fashion-payment" && "ชำระเงินสินค้าแฟชั่น"}
      </p>
      <div className={st.wrapBtnBack}>
        <button onClick={() => navigate(-1)}>
          <i className=" fa-solid fa-circle-arrow-left"></i>
          <p>Back to content</p>
        </button>
      </div>
      <form className={`${st.contentBody} `}>
        <div>
          <div className="w-full">
            <p className={st.textTitle}>Title</p>
            <input
              type="text"
              defaultValue={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            {contentType !== "terms-of-service" &&
              contentType !== "privacy-policy" &&
              contentType !== "term-store" &&
              contentType !== "cart-order" &&
              contentType !== "term-store-post" &&
              contentType !== "term-member" &&
              contentType !== "fashion-category" &&
              contentType !== "fashion-product-list" &&
              contentType !== "fashion-payment" && (
                <>
                  <p className={st.textTitle}>Description</p>
                  <textarea
                    type="text"
                    defaultValue={content}
                    onChange={(e) => setContent(e.target.value)}
                  />
                  <p className={st.textTitle}>Type Description</p>
                  <input
                    type="text"
                    defaultValue={h1}
                    onChange={(e) => setH1(e.target.value)}
                  />
                  <p className={st.textTitle}>Note</p>
                  <input
                    type="text"
                    defaultValue={h2}
                    onChange={(e) => setH2(e.target.value)}
                  />
                </>
              )}

            {(contentType === "terms-of-service" ||
              contentType === "privacy-policy" ||
              contentType === "term-member" ||
              contentType === "cart-order" ||
              contentType === "term-store-post" ||
              contentType === "term-store" ||
              contentType === "fashion-category" ||
              contentType === "fashion-product-list" ||
              contentType === "fashion-payment") && (
              <>
                <div className="my-0">
                  <p className={st.textTitle}>Content</p>
                  <Editor
                    value={content}
                    name="description"
                    setValueOnChange={(data) => {
                      setContent(data);
                    }}
                    editorLoaded={editorLoaded}
                  />
                </div>
              </>
            )}
          </div>
          {contentType === "line-website" && (
            <>
              <p className={st.textTitle}>เปลี่ยน QR CODE</p>
              {imageObj ? (
                <img
                  src={imageObj}
                  className="w-36 h-w-36 sm:mx-auto shadow-lg cursor-pointer"
                  alt="image-first"
                  aria-hidden
                  onClick={() => inputProfileImage.current.click()}
                />
              ) : (
                <img
                  src={`${apiUrl}${imageLink}`}
                  alt="image"
                  className="w-36 h-w-36 sm:mx-auto shadow-lg cursor-pointer"
                  onClick={() => inputProfileImage.current.click()}
                  aria-hidden
                  onError={(e) => {
                    e.target.setAttribute("src", "/assets/contact.jpg");
                  }}
                />
              )}
              <input
                type="file"
                style={{ display: "none" }}
                accept=".jpg,.jpeg,.png,.webp"
                ref={inputProfileImage}
                onChange={(e) => inputImageOnChange(e)}
              />
            </>
          )}
          {(contentType === "home" ||
            contentType === "term-member" ||
            contentType === "term-store" ||
            contentType === "fashion") && (
            <>
              <p className={st.textTitle}>
                เลือกใช้คลิปจากช่องทาง Youtube หรือ Server Upload{" "}
              </p>
              <div className="mb-4">
                <label className="flex relative items-start mr-auto cursor-pointer w-12">
                  <input
                    type="checkbox"
                    className="sr-only peer "
                    checked={isFile}
                    onChange={() => setIsFile((prev) => !prev)}
                  />
                  <div className="w-11 h-6 bg-gray-400 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-blue-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-blue-600 peer-checked:bg-green-600" />
                </label>
              </div>
              {isFile ? (
                <>
                  <p className={st.textTitle}>อัปโหลดไฟล์ Video </p>

                  {videoFile.length > 0 ? (
                    <video
                      autoPlay
                      muted
                      controls
                      loop
                      className="mr-auto my-4 object-cover flex justify-center items-center text-sm cursor-pointer w-[auto] h-[220px] bg-[#D9D9D9] rounded "
                      alt="videoStore"
                      src={videoFile}
                      type="video/mp4"
                    />
                  ) : (
                    <video
                      autoPlay
                      muted
                      controls
                      loop
                      className="mr-auto my-4 object-cover flex justify-center items-center text-sm cursor-pointer w-[auto] h-[220px] bg-[#D9D9D9] rounded "
                      alt="videoStore"
                      src={`${apiUrl}/${videoLink}`}
                      type="video/mp4"
                      poster="/assets/empty.png"
                    />
                  )}
                  <div
                    className="btn-form-control relative mb-4"
                    style={{ maxWidth: 150 }}
                  >
                    <Button
                      className="flex gap-2"
                      color="warning"
                      variant="contained"
                      onClick={() => inputVideo.current.click()}
                    >
                      <i
                        className="fa-solid fa fa-cloud-arrow-up"
                        aria-hidden="true"
                      />{" "}
                      Upload File
                    </Button>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      accept=".mp4"
                      ref={inputVideo}
                      onChange={(e) => inputVideoOnChange(e)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <p className={st.textTitle}>ใช้ลิ้งค์จาก Embeded Youtube <span className="text-red-300">(ถ้าไม่ต้องการแสดง Video ให้ตั้งเป็นค่าว่าง)</span></p>
                  <input
                    type="text"
                    className="mb-4"
                    value={pathUrl}
                    onChange={(e) => setPathUrl(e.target.value.trim(""))}
                  />
                </>
              )}
            </>
          )}
        </div>
      </form>
      <div className="mt-8">
        <Button
          className="flex gap-2"
          color="success"
          variant="contained"
          onClick={() => handleEdit()}
        >
          <i className="fa-solid fa fa-save" aria-hidden="true" /> บันทึก
        </Button>
      </div>
      <Loading loading={isLoading} />
    </div>
  );
}
