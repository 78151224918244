import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Table from "./Table";
import st from "../../styles/allUse/content.module.scss";
import { Dialog, Listbox, Transition } from "@headlessui/react";

export default function ProductCate() {
  const apiUrl = useSelector((state) => state.app.apiPath);
  const access_token = useSelector((state) => state.app.access_token);
  const [searchText, setSearchText] = useState("");
  const [sex, setSex] = useState("women");
  const [productCateList, setProductCateList] = useState([]);
  const [refreshData, setRefreshData] = useState(0);

  useEffect(() => {
    apiGetProduct();
  }, [refreshData]);

  async function apiGetProduct() {
    setProductCateList([]);
    await axios({
      method: "GET",
      url: `${apiUrl}/api/admin/productcate`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
      .then((res) => {
        if (res.data.status) {
          setProductCateList(res.data.product_cate);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className={`main-page ${st.content} animate-[fade_0.3s_ease-in-out]`}>
      <p className={st.title}>จัดการประเภทสินค้า</p>
      <div className={st.searchText}>
        {false && (
          <div className="w-20 h-full absolute right-60 z-10">
            <ChooseType sex={sex} setSex={setSex} />
          </div>
        )}
        {false && (
          <>
            <input
              onChange={(e) => setSearchText(e.target.value)}
              type="text"
              placeholder="ค้นหาประเภทสินค้า"
              style={{ margin: "0 0 0 auto" }}
            />
            <i className="fa-solid fa-magnifying-glass"></i>
          </>
        )}
      </div>
      <div className={st.contentTable}>
        {productCateList.length > 0 && (
          <Table
            data={productCateList}
            rowsPerPage={13}
            searchText={searchText}
            setRefreshData={setRefreshData}
            sex={sex}
          />
        )}
      </div>
    </div>
  );
}

function ChooseType({ sex, setSex }) {
  const type = [
    { name: "ชาย", value: "men" },
    { name: "หญิง", value: "women" },
  ];
  return (
    <>
      <Listbox value={sex} onChange={setSex}>
        <div className="relative h-full">
          <Listbox.Button className="h-full  bg-gray-100 appearance-none border-2 pr-5 border-gray-100 rounded w-full py-1 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-pink-500">
            <div className="h-full flex flex-row justify-center items-center">
              <span className="truncate text-left">
                {sex === "men" && "ชาย"}
                {sex === "women" && "หญิง"}
              </span>
              <i
                className="absolute mt-[2px] font-bold fa-solid fa-arrows-up-down"
                style={{ top: "27%" }}
              ></i>
            </div>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {type?.map((data, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 ${
                      active ? "bg-pink-100 text-pink-900" : "text-gray-900"
                    }`
                  }
                  value={data.value}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate align-left ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {data.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-pink-600"></span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </>
  );
}
