import axios from 'axios'
import moment from 'moment'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import ScrollToTop from '../../../../sub_component/scrollToTop/scrollToTop'
import st from '../scss/allPost.module.scss'

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 800,
    timerProgressBar: true,
})

export default function AllPost({ postList, apiGetStore }) {
    const access_token = useSelector((state) => (state.app.access_token))
    const apiUrl = useSelector((state) => (state.app.apiPath))
    const [isDelete, setIsDelete] = useState(false)
    const [select, setSelect] = useState()

    function handleConfirmDelete(post_code) {
        Swal.fire({
            icon: 'warning',
            position: 'center',
            title: 'ยืนยันการลบโพสต์',
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
            showCancelButton: true,
            showConfirmButton: true
        }).then(res => {
            if (res.isConfirmed) {
                apiDeletePost(post_code)
            }
        })
    }

    async function apiDeletePost(post_code) {
        await axios({
            method: 'GET',
            url: `${apiUrl}/api/admin/storePost/delete/${post_code}`,
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        }).then(() => {
            setIsDelete(true)
            setTimeout(() => { setIsDelete(false) }, 1000)
            Toast.fire({
                icon: 'success',
                title: 'ลบแล้ว'
            }).then(() => {
                apiGetStore()
            })
        })
    }

    async function ApiChangeStatus(postCode, status) {
        if (status === 'active') {
            status = 'pending'
        } else if (status === 'pending') {
            status = 'active'
        }
        try {
            await axios({
                method: 'POST',
                url: `${apiUrl}/api/admin/storePost/updateStatus`,
                headers: {
                    Authorization: `Bearer ${access_token}`
                },
                data: {
                    postCode: postCode,
                    status: status
                }
            }).then(res => {
                Toast.fire({
                    icon: 'success',
                    title: 'สำเร็จ'
                })
                apiGetStore()
            })
        } catch (err) {
            console.log(err);
        }

    }

    return (
        <div className={`${st.column_box_product} animate-[fade_0.3s_ease-in-out]`}>
            <div className={`${st.text_box_top} flex justify-between`}>
                <p>โปรไฟล์โพสต์</p>
                <p>โพสต์ทั้งหมด {postList.length} รายการ</p>
            </div>
            {postList.length > 0
                ? <>
                    <div className={`${st.column_product_recommend} ${st.displayScroller}  h-[665px]  sm:h-[750px] overflow-auto relative`}>
                        {postList?.map((data, index) => (
                            <div
                                key={index}
                                className={`
                                ${st.recommend_column} 
                                ${isDelete && index === select && 'animate-[fadeOut_1s_ease-in-out]'}`}>
                                <i className={`fa-solid fa-trash-can ${st.icon_trash}`}
                                    onClick={() => (handleConfirmDelete(data.post_code), setSelect(index))}
                                ></i>
                                <i className="fa-solid fa-trash-can absolute right-8 text-red-500 z-0"></i>
                                <div className="flex flex-row justify-start items-start space-x-3 mb-2">
                                    <p>การแสดงผล</p>
                                    <label className="inline-flex relative justify-start items-start cursor-pointer">
                                        <input type="checkbox" className="sr-only peer" defaultChecked={data.status === "active" ? true : false} onClick={() => ApiChangeStatus(data.post_code, data.status)} />
                                        <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600" />
                                    </label>
                                </div>
                                <div className={`${st.column_calendar}`}>
                                    <i className="fa-solid fa-calendar-days"></i>
                                    <FormetDate dateTime={data.createdAt} />
                                </div>
                                <div className={st.detail_text}>
                                    <p>รายละเอียด :</p>
                                    <p>{data.caption}</p>
                                </div>
                                <div className={st.column_img}>
                                    <ShowImagePost image={data.post_img} />
                                </div>
                            </div>
                        ))}
                        <ScrollToTop />
                    </div>
                </>
                : <div className='my-2'>ไม่มีโพสต์</div>
            }
        </div>
    )
}


function FormetDate({ dateTime }) {
return <h4>{moment(dateTime).format("DD MMM YYYY")}</h4>
}

function ShowImagePost({ image }) {
    const apiUrl = useSelector((state) => (state.app.apiPath))
    if (!image) {
        return false; // ถ้าไม่มีรูป ไม่ต้องแสดงรูป
    }
    let image_split = image.split(",");
    const response = image_split.map((data, index) => {
        return (
            <img
                src={`${apiUrl}${data}`}
                alt="image-product"
                key={index}
                onError={e => {
                    e.target.setAttribute('src', '/assets/empty.png');
                    return false;
                }} />
        )
    })
    return response;
}