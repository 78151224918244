import axios from "axios";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import FormData from "form-data";
import moment from "moment";
import { useLocation } from "react-router-dom";
import st from "../scss/allProduct.module.scss";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 800,
  timerProgressBar: true,
});

export default function CreateProduct({ apiGetStore }) {
  const query = new URLSearchParams(useLocation().search);
  const [storeCode, setStoreCode] = useState(query.get("storeCode"));
  const access_token = useSelector((state) => state.app.access_token);
  const apiUrl = useSelector((state) => state.app.apiPath);
  const inputFirstImage = useRef([]);
  const inputSecondImage = useRef([]);
  const inputThirdImage = useRef([]);
  const inputFourthImage = useRef([]);
  const [imageObj, setImageobj] = useState({});

  let form = {
    name_member: "",
    content_member: "",
    name_premium: "",
    content_premium: "",
    clip: "no",
    hasImage: false,
    price_standard: "",
    price_premium: "",
  };
  const [formProduct, setFormProduct] = useState(form);

  function inputImageOnChange(e, name) {
    if (!e.target.files.length) {
      return false;
    }
    if (
      ["image/jpeg", "iamge/jpg", "image/png", "image/webp"].includes(
        e.target.files[0].type
      )
    ) {
      const URLs = URL.createObjectURL(e.target.files[0]);
      setImageobj((prev) => ({
        ...prev,
        [name]: URLs,
      }));
    } else {
      Swal.fire({
        title: "กรุณาอัปโหลดเฉพาะไฟล์รูปภาพ",
        icon: "warning",
        position: "center",
        timer: 1000,
        showConfirmButton: false,
      });
    }
  }

  function FormetDate({ dateTime }) {
    return <h4>{moment(dateTime).format("DD MMM YYYY")}</h4>;
  }

  const clearImage = () => {
    inputFirstImage.current.value = "";
    inputSecondImage.current.value = "";
    inputThirdImage.current.value = "";
    inputFourthImage.current.value = "";
  };

  function handleClickCreateProduct() {
    if (
      !inputFirstImage.current.value &&
      !inputSecondImage.current.value &&
      !inputThirdImage.current.value &&
      !inputFourthImage.current.value
    ) {
      Swal.fire({
        title: "กรุณาเพิ่มรูปภาพอย่างน้อย 1 รูป",
        icon: "warning",
        position: "center",
        timer: 1800,
        showConfirmButton: false,
      });
      return false;
    }

    if (formProduct.price_standard === "" || formProduct.price_premium === "") {
      Swal.fire({
        title: "กรุณาใส่ราคา",
        icon: "warning",
        position: "center",
        timer: 1800,
        showConfirmButton: false,
      });
      return false;
    }

    const formData = new FormData();
    formData.append("standard", inputFirstImage.current.files[0]);
    formData.append("standard", inputSecondImage.current.files[0]);
    formData.append("premium", inputThirdImage.current.files[0]);
    formData.append("premium", inputFourthImage.current.files[0]);
    formData.append("storeCode", storeCode);
    formData.append("name_member", formProduct.name_member);
    formData.append("content_member", formProduct.content_member);
    formData.append("name_premium", formProduct.name_premium);
    formData.append("content_premium", formProduct.content_premium);
    formData.append("price_standard", formProduct.price_standard);
    formData.append("price_premium", formProduct.price_premium);
    formData.append("clip", formProduct.clip);
    formData.append("hasImage", formProduct.hasImage ? 1 : 0);
    Swal.fire({
      title: "ยืนยันการโพสต์",
      icon: "question",
      position: "center",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      confirmButtonColor: "#C93A87",
      showCancelButton: true,
      showConfirmButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        createProduct(formData);
      } else {
        setImageobj({});
        clearImage();
        inputFirstImage.current = [];
        inputSecondImage.current = [];
        inputThirdImage.current = [];
        inputFourthImage.current = [];
      }
    });
  }

  async function createProduct(params) {
    await axios({
      method: "POST",
      url: `${apiUrl}/api/admin/storeProduct/Create`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
      data: params,
    });
    Toast.fire({
      icon: "success",
      title: "สำเร็จ",
    });
    apiGetStore();
    setImageobj({});
    clearImage();
    setFormProduct(form);
  }

  return (
    <div className={`${st.column_box_product} animate-[fade_0.3s_ease-in-out]`}>
      <div className={`${st.text_box_top} flex justify-between`}>
        <p>ลงสินค้า</p>
      </div>
      <div
        className={`flex flex-col justify-center h-[595px] sm:h-[660px] overflow-auto px-8 ${st.displayScroller}`}
      >
        {/* <div className="">
                    <p className='font-bold text-lg mt-4 text-left'>ข้อมูลส่วน Member</p>
                </div> */}
        <div
          className={`flex flex-col w-full md-to-opx:flex-wrap justify-center items-center gap-4 mt-4 sm:mt-[4rem] `}
        >
          <div className="flex flex-col gap-4 w-full">
            <div className="flex justify-center gap-2">
              {imageObj.first ? (
                <img
                  className=" object-cover flex justify-center items-center text-sm cursor-pointer w-[45%] h-[160px] bg-[#D9D9D9] rounded "
                  alt="image-first"
                  src={imageObj.first}
                  onClick={() => inputFirstImage.current.click()}
                />
              ) : (
                <div
                  className="object-cover flex justify-center items-center text-sm cursor-pointer w-[45%] h-[160px] bg-[#D9D9D9] rounded "
                  onClick={() => inputFirstImage.current.click()}
                >
                  <i className="text-4xl fa-regular fa-image"></i>
                </div>
              )}
              <input
                type="file"
                style={{ display: "none" }}
                accept=".jpg,.jpeg,.png,.webp"
                ref={inputFirstImage}
                onChange={(e) => inputImageOnChange(e, "first")}
              />
              {imageObj.second ? (
                <img
                  src={imageObj.second}
                  alt="image-second"
                  className="object-cover flex justify-center items-center text-sm cursor-pointer w-[45%] h-[160px] bg-[#D9D9D9] rounded "
                  onClick={() => inputSecondImage.current.click()}
                />
              ) : (
                <div
                  className="object-cover flex justify-center items-center text-sm cursor-pointer w-[45%] h-[160px] bg-[#D9D9D9] rounded "
                  onClick={() => inputSecondImage.current.click()}
                >
                  <i className="text-4xl fa-regular fa-image"></i>
                </div>
              )}
              <input
                type="file"
                style={{ display: "none" }}
                accept=".jpg,.jpeg,.png,.webp"
                ref={inputSecondImage}
                onChange={(e) => inputImageOnChange(e, "second")}
              />
            </div>
            <div className="flex justify-center gap-2">
              {imageObj.third ? (
                <img
                  src={imageObj.third}
                  alt="image-third"
                  className="object-cover flex justify-center items-center text-sm cursor-pointer w-[45%] h-[160px] bg-[#D9D9D9] rounded "
                  onClick={() => inputThirdImage.current.click()}
                />
              ) : (
                <div
                  className="object-cover flex justify-center items-center text-sm cursor-pointer w-[45%] h-[160px] bg-[#D9D9D9] rounded "
                  onClick={() => inputThirdImage.current.click()}
                >
                  <i className="text-4xl fa-regular fa-image"></i>
                </div>
              )}
              <input
                type="file"
                style={{ display: "none" }}
                accept=".jpg,.jpeg,.png,.webp"
                ref={inputThirdImage}
                onChange={(e) => inputImageOnChange(e, "third")}
              />
              {imageObj.fourth ? (
                <img
                  src={imageObj.fourth}
                  alt="image-fourth"
                  className="object-cover flex justify-center items-center text-sm cursor-pointer w-[45%] h-[160px] bg-[#D9D9D9] rounded "
                  onClick={() => inputFourthImage.current.click()}
                />
              ) : (
                <div
                  className="object-cover flex justify-center items-center text-sm cursor-pointer w-[45%] h-[160px] bg-[#D9D9D9] rounded "
                  onClick={() => inputFourthImage.current.click()}
                >
                  <i className="text-4xl fa-regular fa-image"></i>
                </div>
              )}
              <input
                type="file"
                style={{ display: "none" }}
                accept=".jpg,.jpeg,.png,.webp"
                ref={inputFourthImage}
                onChange={(e) => inputImageOnChange(e, "fourth")}
              />
            </div>
          </div>

          <form className="flex justify-center w-full sm:flex-col items-start gap-2">
            <div className="flex flex-col gap-2">
              <div className="column-label flex justify-start gap-2">
                <label>ราคา</label>
                <label>
                  {" "}
                  BTH <span style={{ fontSize: "14px" }}>(Member)</span>
                </label>
              </div>
              <input
                className="p-2 bg-white border-2 border-[#747474] rounded-lg"
                value={formProduct.price_standard}
                maxLength={10}
                onChange={(e) => {
                  if (
                    /^[0-9]+$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setFormProduct({
                      ...formProduct,
                      price_standard: e.target.value,
                    });
                  }
                }}
              />
              <div className="column-label flex justify-start gap-2">
                <label>ราคา</label>
                <label>
                  {" "}
                  BTH <span style={{ fontSize: "14px" }}>(Premium)</span>
                </label>
              </div>
              <input
                className="p-2  bg-white border-2 border-[#747474] rounded-lg"
                value={formProduct.price_premium}
                maxLength={10}
                onChange={(e) => {
                  if (
                    /^[0-9]+$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setFormProduct({
                      ...formProduct,
                      price_premium: e.target.value,
                    });
                  }
                }}
              />
            </div>
            <div className="flex flex-col gap-2 sm:w-full">
              <div className="column-label flex justify-start gap-2">
                <label>ชื่อสินค้า</label>
              </div>
              <input
                className="p-2  bg-white border-2 border-[#747474] rounded-lg w-[290px] sm:w-full"
                value={formProduct.name_member}
                maxLength={50}
                placeholder="ยี่ห้อ/เนื้อผ้า/รหัสสินค้า(ตั้งเอง)"
                onChange={(e) => {
                  setFormProduct({
                    ...formProduct,
                    name_member: e.target.value,
                    name_premium: e.target.value,
                  });
                }}
              />
              <div className="column-label flex justify-start gap-2">
                <label>รายละเอียดสินค้า</label>
              </div>
              <textarea
                className="h-[44px] p-2 sm:w-full bg-white border-2 border-[#747474] rounded-lg w-[290px]"
                value={formProduct.content_member}
                placeholder="ใส่ออกกำลังกาย กลิ่นแน่นๆ"
                onChange={(e) =>
                  setFormProduct({
                    ...formProduct,
                    content_member: e.target.value,
                    content_premium: e.target.value,
                  })
                }
              ></textarea>
              <div className="flex flex-row justify-end sm:justify-start mr-6 gap-2 my-auto items-center">
                {false && (
                  <>
                    <input
                      className="check-input"
                      type="checkbox"
                      checked={formProduct.clip === "yes" ? true : false}
                      onChange={(e) =>
                        setFormProduct({
                          ...formProduct,
                          clip: formProduct.clip === "yes" ? "no" : "yes",
                        })
                      }
                    />
                    <label>มีคลิป</label>
                  </>
                )}
                <input
                  className="check-input"
                  type="checkbox"
                  checked={formProduct.hasImage}
                  onChange={(e) =>
                    setFormProduct({
                      ...formProduct,
                      hasImage: !formProduct.hasImage,
                    })
                  }
                />
                <label>มีรูปตอนใส่</label>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="column-button gap-2 flex flex-row justify-center my-3">
        <button
          style={{
            width: "120px",
            height: "30px",
            background: "linear-gradient(180deg, #FF95B5 0%, #C93A87 100%)",
            borderRadius: "50px",
            border: "none",
            fontize: "16px",
            color: "white",
            cursor: "pointer",
          }}
          className="btn-left hover:bg-pink-800"
          onClick={() => handleClickCreateProduct()}
        >
          โพสต์
        </button>
      </div>
    </div>
  );
}
