import React, { useEffect, useState } from "react";
import moment from "moment";
import TableFooter from "../../../sub_component/TableFooter";
import useTable from "../../../../hooks/useTable";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const ReportOrderFashion = ({ data, rowsPerPage }) => {
  const apiUrl = useSelector((state) => state.app.apiPath);
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage);

  useEffect(() => {
    setPage(1);
  }, []);

  function FormatDate({ dateTime }) {
    dateTime = moment(dateTime).format("DD MMM YYYY");
    return <span>{dateTime}</span>;
  }

  function handleShowSlip(slip) {
    Swal.fire({
      imageUrl: apiUrl + slip,
      imageWidth: 400,
      // imageHeight: 500,
      imageAlt: "Custom image",
      showConfirmButton: false,
      backdrop: true,
      background: "rgba(0,0,0,0)",
    });
  }

  return (
    <React.Fragment>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="py-3 px-2  text-center">
              Order NO.
            </th>
            <th scope="col" className="py-3 px-2  text-center">
              วันที่สั่งซื้อ
            </th>
            <th scope="col" className="py-3 px-2  text-center">
              ชนิดสินค้า
            </th>
            <th scope="col" className="py-3 px-2  text-center">
              เพศ
            </th>
            <th scope="col" className="py-3 px-2  text-center">
              ชื่อร้านค้า
            </th>
            <th scope="col" className="py-3 px-2  text-center">
              เบอร์โทรร้านค้า
            </th>
            <th scope="col" className="py-3 px-2 text-center">
              ราคารวม (ก่อนหัก GP)
            </th>
            <th scope="col" className="py-3 px-2 text-center">
              ราคาสุทธิ (หลังหัก GP)
            </th>
            <th scope="col" className="py-3 px-2 text-center">
              ค่าGP
            </th>
            <th scope="col" className="py-3 px-2  text-center">
              เบอร์โทรลูกค้า
            </th>
            <th scope="col" className="py-3 px-2  text-center">
              การชำระเงิน
            </th>
            {/* <th scope="col" className="py-3 px-2 2xl:px-12 text-center min-w-[5rem]">
              สลิปชำระ
            </th> */}
            <th
              scope="col"
              className="py-3 px-2 2xl:px-12 text-center min-w-[5rem]"
            >
              หมายเหตุ
            </th>
          </tr>
        </thead>
        <tbody>
          {slice.map((data, index) => (
            <tr
              key={index}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
            >
              <th
                scope="row"
                className="py-4 px-2 font-medium text-center text-gray-900 whitespace-nowrap dark:text-white"
              >
                {data.orderNumber}
              </th>
              <td className="py-2 px-2 text-center">
                {data.orderDate && <FormatDate dateTime={data.orderDate} />}
              </td>
              <td className="py-2 px-2 text-center">{data.cate_name}</td>
              <td className="py-2 px-2 text-center">{data.gender}</td>
              <td className="py-2 px-2 text-center">{data.storeName}</td>
              <td className="py-2 px-2 text-center">{data.store_user}</td>
              <td className="py-2 px-2 text-center">{data?.payment_type === "COD" ? (data.totalprice - 50) : data.totalprice}</td>
              <td className="py-2 px-2 text-center">{data.netprice}</td>
              <td className="py-2 px-2 text-center">
                {data.price - data.netprice}
              </td>
              <td className="py-2 px-2 text-center">{data.phone}</td>
              <td className="py-2 px-2 text-center">
                {data.payment_type === "COD" ? "COD" : ""}
              </td>
              <td className="py-2 px-2 text-center">{data.note}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </React.Fragment>
  );
};

export default ReportOrderFashion;
