import React from 'react'

const ButtonEdit = () => {
    return (
        <>
            <button
                type="button"
                className="flex flex-row gap-2 text-white bg-pink-400 hover:bg-pink-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-xl text-sm px-5 py-1.5 text-center mr-2 mb-2 dark:focus:ring-yellow-900"
            >
                <i className="my-auto fa-solid fa-pen-to-square"></i>
                <p>แก้ไข</p>
            </button>
        </>
    )
}

export default ButtonEdit;