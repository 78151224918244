import React, { useState } from 'react'
import Table from './Table'

export default function AllPayment({ memberList }) {
    const [searchText, setSearchText] = useState("")

    return (
        <div className="flex-1 overflow-auto animate-[fade_0.3s_ease-in-out] sm:min-w-[380px]">
            <div className="relative mb-2">
                <input maxLength={10} onChange={e => setSearchText(e.target.value)} type="text" className='rounded flex px-3' placeholder='ค้นหาชื่อผู้ใช้' style={{ margin: "0 0 0 auto" }} />
                <i className="absolute right-3 text-gray-500/25 fa-solid fa-magnifying-glass" style={{top: '32%'}}></i>
            </div>
            <div className="overflow-x-auto relative max-w-[1400px] mx-auto border-2 rounded-lg ">
                {memberList.length > 0 &&
                    <Table data={memberList} rowsPerPage={13} searchText={searchText} />
                }
            </div>
        </div>
    )
}
