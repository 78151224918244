import React, { Fragment, useEffect, useState } from "react";
import useTable from "../../../hooks/useTable";
import moment from "moment";
import TableFooter from "../../sub_component/TableFooter";
import st from "../../../styles/allUse/table.module.scss";
import PopupChangePass from "./popupChangePass";
import PopUpAddDay from "./popupAddDay";
import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Listbox, Transition } from "@headlessui/react";
import { now } from "moment";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 800,
  timerProgressBar: true
});
const formStatusMember = [
  { id: 0, statusMember: "active" },
  { id: 1, statusMember: "inactive" },
  { id: 2, statusMember: "banned" }
];
const Table = ({ filteredData, rowsPerPage, apiGetAll, page, setPage, selectStatus, setSelectStatus }) => {
  const apiUrl = useSelector((state) => state.app.apiPath);
  const access_token = useSelector((state) => state.app.access_token);
  const { slice, range } = useTable(filteredData, page, rowsPerPage);
  const [memberData, setMemberData] = useState({});
  const [isOpenChangePass, setIsOpenChangePass] = useState(false);
  const [isOpenAddDay, setIsOpenAddDay] = useState(false);

  function onSetOpen1() {
    setIsOpenChangePass(!isOpenChangePass);
  }
  function onSetOpen2() {
    setIsOpenAddDay(!isOpenAddDay);
  }
  function handleChange(memberCode, memberStatus) {
    Swal.fire({
      title: "ยืนยันการเปลี่ยนสถานะเป็น " + memberStatus,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      confirmButtonColor: "#C93A87",
      showLoaderOnConfirm: true,
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        apiChangeMemberStatus(memberCode, memberStatus);
      }
    });
  }
  async function apiChangeMemberStatus(memberCode, memberStatus) {
    await axios({
      method: "POST",
      url: `${apiUrl}/api/admin/member/changeStatus`,
      headers: {
        Authorization: `Bearer ${access_token}`
      },
      data: {
        memberCode: memberCode,
        status: memberStatus
      }
    }).then((res) => {
      Toast.fire({
        icon: "success",
        title: "แก้ไขแล้ว"
      });
      apiGetAll();
    });
  }
  const deleteStoreHandler = async (memberCode) => {
    Swal.fire({
      title: "ยืนยันการลบ?",
      text: "คุณต้องการลบบัญชีผู้ใช้หรือไม่!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่, ลบเลย!",
      cancelButtonText: "ยกเลิก"
    }).then((result) => {
      if (result.isConfirmed) {
        confirmedDelete(memberCode);
      }
    });
  };
  const confirmedDelete = async (memberCode) => {
    const result = await axios
      .delete(`${apiUrl}/api/admin/member/${memberCode}`, {
        headers: { Authorization: `Bearer ${access_token}` }
      })
      .then(
        (res) => {
          return { status: true, description: res.data.description };
        },
        (error) => {
          return {
            status: false,
            description: error.response.data.description
          };
        }
      );

    if (result.status) {
      Swal.fire({
        title: "ลบบัญชี",
        text: "การลบบัญชีสำเร็จ!",
        icon: "success",
        position: "center",
        timer: 1000,
        showConfirmButton: false
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "ผิดพลาด",
        text: "การลบบัญชีล้มเหลว!",
        position: "center",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "ปิด"
      });
    }
  };

  return (
    <>
      <table className={st.contentTable}>
        <thead>
          <tr>
            <th scope="col"> ชื่อผู้ใช้ (username) </th>
            <th scope="col"> ID LINE </th>
            <th scope="col"> สินค้า </th>
            <th scope="col"> ประเภท </th>
            <th scope="col"> วันที่สมัครสมาชิก </th>
            <th scope="col"> วันหมดอายุ </th>
            <th scope="col"> จำนวนวันคงเหลือ </th>
            <th scope="col"> สถานะ </th>
            <th scope="col"> เพิ่มวันสมาชิก </th>
            <th scope="col"> เปลี่ยนรหัสผ่าน </th>
            <th scope="col"> ลบ</th>
          </tr>
        </thead>
        <tbody>
          {slice?.map((data, index) => (
            <tr key={index}>
              <td>{data.username}</td>
              <td>{data.password}</td>
              <td>
                {data.gender === "men" && "ชาย"}
                {data.gender === "women" && "หญิง"}
              </td>
              <td>
                {data.isStore === "no" && "ลูกค้า"}
                {data.isStore === "yes" && "ร้านค้า"}
              </td>
              <td className="w-[6rem]">
                <span>
                  {data.begin ? moment(data.begin).format("DD MMM YYYY") : ""}
                </span>
              </td>
              <td className="w-[6rem]">
                <span>
                  {data.expire ? moment(data.expire).format("DD MMM YYYY") : ""}
                </span>
              </td>
              <td>
                <span>
                  {data.expire ? moment(data.expire).diff(now(), "days") : ""}
                </span>
              </td>
              <td className="px-2">
                <Listbox value={selectStatus} onChange={setSelectStatus}>
                  {selectStatus && (
                    <div className="relative">
                      <Listbox.Button
                        className={`z-0 bg-gray-200 appearance-none border-2 border-gray-200 rounded-lg w-full py-1 px-4 text-gray-700 leading-tight 
                                               ${
                                                 data.statusMember ===
                                                   "active" &&
                                                 "bg-green-500 hover:bg-green-700"
                                               }
                                               ${
                                                 data.statusMember ===
                                                   "inactive" &&
                                                 "bg-yellow-500 hover:bg-yellow-700"
                                               }
                                               ${
                                                 data.statusMember ===
                                                   "banned" &&
                                                 "bg-red-500 hover:bg-red-700"
                                               }
                                                `}
                      >
                        <span className={` text-center text-white`}>
                          {data.statusMember}
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="z-10 absolute max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {formStatusMember?.map((datax, index) => (
                            <Listbox.Option
                              key={index}
                              className={({ active }) =>
                                `relative cursor-default select-none p-2 ${
                                  active
                                    ? "bg-pink-100 text-pink-900"
                                    : "text-gray-900"
                                }`
                              }
                              value={datax}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate align-left ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}
                                    onClick={() =>
                                      handleChange(
                                        data.member_code,
                                        datax.statusMember
                                      )
                                    }
                                  >
                                    {datax.statusMember}
                                  </span>
                                  {selected ? (
                                    <span className="absolute inset-y-0 right-2 flex items-center pl-3 text-pink-600">
                                      <i className="fa-solid fa-check"></i>
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  )}
                </Listbox>
              </td>
              <td className="py-2">
                {data.isStore === "no" && data.begin && data.expire && (
                  <div className="flex flex-row justify-center">
                    <button
                      onClick={() => (
                        setIsOpenAddDay(!isOpenAddDay), setMemberData(data)
                      )}
                      type="button"
                      className="justify-center w-[7rem] gap-2 flex text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-4 focus:ring-green-600 font-medium rounded-lg text-sm px-3 py-1 text-center dark:focus:ring-green-900"
                    >
                      <i className="my-auto fa-regular fa-calendar-plus"></i>
                      เพิ่มวัน
                    </button>
                  </div>
                )}
              </td>
              <td className="py-2w-[13rem]">
                <div className="flex flex-row justify-center">
                  <button
                    onClick={() => (
                      setIsOpenChangePass(!isOpenChangePass),
                      setMemberData(data)
                    )}
                    type="button"
                    className="gap-2 flex text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-xl text-sm px-3 py-1.5 text-center dark:focus:ring-red-900"
                  >
                    <i className="my-auto fa-solid fa-repeat">
                      {" "}
                      เปลี่ยนรหัสผ่าน
                    </i>
                  </button>
                </div>
              </td>
              <td className="py-2w-[13rem]">
                <div className="flex flex-row justify-center">
                  <button
                    onClick={() => deleteStoreHandler(data.member_code)}
                    type="button"
                    className="gap-2 flex text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-xl text-sm px-3 py-1.5 text-center dark:focus:ring-red-900"
                  >
                    <i className="my-auto fa-solid fa-trash"> ลบบัญชี</i>
                  </button>
                </div>
              </td>
            </tr>
          ))}
          {isOpenChangePass && (
            <PopupChangePass onSetOpen={onSetOpen1} memberData={memberData} />
          )}
          {isOpenAddDay && (
            <PopUpAddDay
              onSetOpen={onSetOpen2}
              memberData={memberData}
              apiGetAll={apiGetAll}
            />
          )}
        </tbody>
      </table>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </>
  );
};

export default Table;
