import axios from "axios";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import FormData from "form-data";
import moment from "moment";
import { useLocation } from "react-router-dom";
import st from "../scss/allProduct.module.scss";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 800,
  timerProgressBar: true,
});

export default function CreatePreorder({ apiGetStore }) {
  const query = new URLSearchParams(useLocation().search);
  const [storeCode, setStoreCode] = useState(query.get("storeCode"));
  const access_token = useSelector((state) => state.app.access_token);
  const apiUrl = useSelector((state) => state.app.apiPath);
  const inputFirstImage = useRef([]);
  const inputSecondImage = useRef([]);
  const [imageObj, setImageobj] = useState({});

  let form = {
    name_premium: "",
    content_premium: "",
    clip: "no",
    hasImage: false,
    price_premium: "",
  };
  const [formProduct, setFormProduct] = useState(form);

  const clearImage = () => {
    inputFirstImage.current.value = "";
    inputSecondImage.current.value = "";
  };

  function inputImageOnChange(e, name) {
    if (!e.target.files.length) {
      return false;
    }
    if (
      ["image/jpeg", "iamge/jpg", "image/png", "image/webp"].includes(
        e.target.files[0].type
      )
    ) {
      const URLs = URL.createObjectURL(e.target.files[0]);
      setImageobj((prev) => ({
        ...prev,
        [name]: URLs,
      }));
    } else {
      Swal.fire({
        title: "กรุณาอัปโหลดเฉพาะไฟล์รูปภาพ",
        icon: "warning",
        position: "center",
        timer: 1000,
        showConfirmButton: false,
      });
    }
  }

  function FormetDate({ dateTime }) {
    return <h4>{moment(dateTime).format("DD MMM YYYY")}</h4>;
  }

  function handleClickCreateProduct() {
    if (!inputFirstImage.current.value && !inputSecondImage.current.value) {
      Swal.fire({
        title: "กรุณาเพิ่มรูปภาพอย่างน้อย 1 รูป",
        icon: "warning",
        position: "center",
        timer: 1800,
        showConfirmButton: false,
      });
      return false;
    }

    if (formProduct.price_premium === "") {
      Swal.fire({
        title: "กรุณาใส่ราคา",
        icon: "warning",
        position: "center",
        timer: 1800,
        showConfirmButton: false,
      });
      return false;
    }
    const formData = new FormData();
    formData.append("premium", inputFirstImage.current.files[0]);
    formData.append("premium", inputSecondImage.current.files[0]);

    formData.append("storeCode", storeCode);
    formData.append("name_premium", formProduct.name_premium);
    formData.append("content_premium", formProduct.content_premium);
    formData.append("price_premium", formProduct.price_premium);
    formData.append("clip", formProduct.clip);
    formData.append("hasImage", formProduct.hasImage ? 1 : 0);

    Swal.fire({
      title: "ยืนยันการโพสต์",
      icon: "question",
      position: "center",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      confirmButtonColor: "#C93A87",
      showCancelButton: true,
      showConfirmButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        createPreorder(formData);
      } else {
        setImageobj({});
        clearImage();
        inputFirstImage.current = [];
        inputSecondImage.current = [];
      }
    });
  }

  async function createPreorder(params) {
    await axios({
      method: "POST",
      url: `${apiUrl}/api/admin/storeProductPre/Create`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
      data: params,
    });
    Toast.fire({
      icon: "success",
      title: "สำเร็จ",
    });
    apiGetStore();
    setImageobj({});
    clearImage();
    setFormProduct(form);
  }

  return (
    <div className={`${st.column_box_product} animate-[fade_0.3s_ease-in-out]`}>
      <div className={`${st.text_box_top} flex justify-between`}>
        <p>ลงสินค้า</p>
      </div>
      <div className={` overflow-auto px-8 ${st.displayScroller}`}>
        <div className="">
          <p className="font-bold text-lg mt-4 text-left">ข้อมูลส่วน Member</p>
        </div>
        <div
          className={`flex flex-col md-to-opx:flex-wrap justify-center items-center gap-4 mt-4 `}
        >
          <div className="flex gap-4 w-full justify-center items-center">
            {imageObj.first ? (
              <img
                className=" object-cover flex justify-center items-center text-sm cursor-pointer w-[50%] h-[160px] bg-[#D9D9D9] rounded "
                alt="image-first"
                src={imageObj.first}
                onClick={() => inputFirstImage.current.click()}
              />
            ) : (
              <div
                className="object-cover flex justify-center items-center text-sm cursor-pointer w-[50%] h-[160px] bg-[#D9D9D9] rounded "
                onClick={() => inputFirstImage.current.click()}
              >
                <i className="text-4xl fa-regular fa-image"></i>
              </div>
            )}
            <input
              type="file"
              style={{ display: "none" }}
              accept=".jpg,.jpeg,.png,.webp"
              ref={inputFirstImage}
              onChange={(e) => inputImageOnChange(e, "first")}
            />
            {imageObj.second ? (
              <img
                src={imageObj.second}
                alt="image-second"
                className="object-cover flex justify-center items-center text-sm cursor-pointer w-[50%] h-[160px] bg-[#D9D9D9] rounded "
                onClick={() => inputSecondImage.current.click()}
              />
            ) : (
              <div
                className="object-cover flex justify-center items-center text-sm cursor-pointer w-[50%] h-[160px] bg-[#D9D9D9] rounded "
                onClick={() => inputSecondImage.current.click()}
              >
                <i className="text-4xl fa-regular fa-image"></i>
              </div>
            )}
            <input
              type="file"
              style={{ display: "none" }}
              accept=".jpg,.jpeg,.png,.webp"
              ref={inputSecondImage}
              onChange={(e) => inputImageOnChange(e, "second")}
            />
          </div>

          <form className="flex justify-start md-to-opx:w-[88%] items-start text-start">
            <div className="flex flex-col gap-4 w-full justify-start sm:flex-col">
              <div className="flex flex-col md:flex-row gap-2">
                <div className="flex flex-col gap-2">
                  <div className="column-label">
                    <label>ราคา</label>
                    <label> BTH</label>
                  </div>
                  <input
                    className="p-2 bg-white border-2 border-[#747474] rounded-lg"
                    value={formProduct.price_premium}
                    maxLength={10}
                    onChange={(e) => {
                      if (
                        /^[0-9]+$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        setFormProduct({
                          ...formProduct,
                          price_premium: e.target.value,
                        });
                      }
                    }}
                  />
                </div>
                <flex className="flex flex-col gap-2">
                  <label>ชื่อสินค้า</label>
                  <input
                    className="w-[290px] p-2 md-to-opx:w-full bg-white border-2 border-[#747474] rounded-lg"
                    value={formProduct.name_premium}
                    placeholder="ยี่ห้อ/เนื้อผ้า/รหัสสินค้า(ตั้งเอง)"
                    onChange={(e) =>
                      setFormProduct({
                        ...formProduct,
                        name_premium: e.target.value,
                      })
                    }
                  />
                </flex>
              </div>
              <div className="w-full flex flex-col gap-2">
                <label>รายละเอียดสินค้า</label>
                <textarea
                  className="h-[128px] w-full p-2 md-to-opx:w-full bg-white border-2 border-[#747474] rounded-lg"
                  value={formProduct.content_premium}
                  placeholder="สั่งจองล่วงหน้า 3-4 วัน ตามลำดับคิว"
                  onChange={(e) =>
                    setFormProduct({
                      ...formProduct,
                      content_premium: e.target.value,
                    })
                  }
                ></textarea>
                {false && (
                  <>
                    <input
                      className="check-input"
                      type="checkbox"
                      checked={formProduct.clip === "yes" ? true : false}
                      onChange={(e) =>
                        setFormProduct({
                          ...formProduct,
                          clip: formProduct.clip === "yes" ? "no" : "yes",
                        })
                      }
                    />
                    <label>มีคลิป</label>
                  </>
                )}
                <div className="flex flex-row justify-end sm:justify-start mt-0 sm:mt-2 sm:mb-2 gap-1 items-center">
                  <input
                    className="check-input"
                    type="checkbox"
                    checked={formProduct.hasImage}
                    onChange={(e) =>
                      setFormProduct({
                        ...formProduct,
                        hasImage: !formProduct.hasImage,
                      })
                    }
                  />
                  <label>มีรูปตอนใส่</label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="column-button gap-2 flex flex-row justify-center my-3">
        <button
          style={{
            width: "120px",
            height: "30px",
            background: "linear-gradient(180deg, #FF95B5 0%, #C93A87 100%)",
            borderRadius: "50px",
            border: "none",
            fontize: "16px",
            color: "white",
            cursor: "pointer",
          }}
          className="btn-left hover:bg-pink-800"
          onClick={() => handleClickCreateProduct()}
        >
          โพสต์
        </button>
      </div>
    </div>
  );
}
