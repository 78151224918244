import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import st from "../../styles/allUse/content.module.scss";
import ReviewTable from "./ReviewTable";
import { Tab } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Review() {
  const apiUrl = useSelector((state) => state.app.apiPath);
  const access_token = useSelector((state) => state.app.access_token);
  const [reviewList, setReviewList] = useState();
  const [fashionReviewList, setFashionReviewList] = useState();

  let [categories] = useState({
    ชุดชั้นใน: [],
    แฟชั่น: [],
  });

  async function apiGetReview() {
    setReviewList([]);
    setFashionReviewList([]);

    await axios({
      method: "GET",
      url: `${apiUrl}/api/admin/review/get`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }).then((res) => {
      if (res.data.status) {
        setReviewList(res.data.reviews);
        setFashionReviewList(res.data.fashionReviews);
      }
    });
  }

  return (
    <div className={`main-page ${st.content} animate-[fade_0.3s_ease-in-out]`}>
      <h1 className={st.title}>จัดการรีวิว</h1>

      <div className={st.contentTab}>
        <Tab.Group>
          <Tab.List className={st.tabList}>
            {Object.keys(categories).map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  classNames(
                    "w-full rounded-lg  text-sm font-bold leading-5 text-pink-700",
                    "ring-white ring-opacity-60 ring-offset-2 ring-offset-pink-400 focus:outline-none focus:ring-2",
                    selected
                      ? "bg-white shadow  animate-[fade_0.3s_ease-in-out] "
                      : "text-pink-700 hover:bg-white/[0.12] hover:text-white "
                  )
                }
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel
              className={classNames(
                "rounded-xl bg-white ",
                "ring-white ring-opacity-60 ring-offset-2 "
              )}
            >
              <ReviewTable
                reviewList={reviewList}
                apiGetReview={apiGetReview}
                section={"review"}
                st={st}
              />
            </Tab.Panel>
            <Tab.Panel
              className={classNames(
                "rounded-xl bg-white",
                "ring-white ring-opacity-60 ring-offset-2 "
              )}
            >
              <ReviewTable
                reviewList={fashionReviewList}
                apiGetReview={apiGetReview}
                section={"reviewfashion"}
                st={st}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}
