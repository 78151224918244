import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useTable from "../../../hooks/useTable";
import moment from "moment";
import TableFooter from "../../sub_component/TableFooter";
import st from "../../../styles/allUse/table.module.scss";
import { useSelector } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 800,
  timerProgressBar: true,
})

const Table = ({ data, rowsPerPage, searchText, sex, apiGetProduct }) => {
  const access_token = useSelector((state) => (state.app.access_token))
  const apiUrl = useSelector((state) => (state.app.apiPath))
  const [page, setPage] = useState(1);
  const [productList, setProductList] = useState(data);
  const { slice, range } = useTable(productList, page, rowsPerPage);

  useEffect(() => {
    if (data) {
      setProductList(data)
    }
  }, [data])

  
  useEffect(()=>{
    setPage(1)
  },[searchText])
  

  function onSetProductList(index, e){
    setProductList(prev => prev.map((el, indexpp) => {
      if(index === indexpp){
        el.priority_recommend = parseInt(e.target.value)
      }
      return el
    }))
  }

  function regNumber(e){
    let regExp = new RegExp(/^[0-9]$/)
    if(!regExp.test(e.key)){
        e.preventDefault();
    }
  }

  function FormatDate({ dateTime }) {
    dateTime = moment(dateTime).format("DD MMM YYYY");
    return <span>{dateTime}</span>;
  }

  async function apiSetPriority(productCode, priority) {
    await axios({
      method: 'POST',
      url: `${apiUrl}/api/admin/storeProduct/setPriorityRecommend`,
      headers: {
        Authorization: `Bearer ${access_token}`
      },
      data: {
        productCode: productCode.toString(),
        priority: priority,
        sex: sex
      }
    }).then(res => {
      if (res.data.status) {
        Toast.fire({
          icon: 'success',
          title: 'เปลี่ยนลำดับแล้ว'
        }).then(async () => {
          await apiGetProduct()
        })
      }
    })
  }

  return (
    <>
      <table className={st.contentTable}>
        <thead>
          <tr>
            <th scope="col" style={{textAlign:"start"}}> ลำดับที่</th>
            <th scope="col" style={{textAlign:"start"}}> ชื่อร้าน</th>
            <th scope="col" style={{textAlign:"start"}}> ชื่อยูส</th>
            <th scope="col" style={{textAlign:"start"}}> ชื่อสินค้า </th>
            <th scope="col" style={{textAlign:"start"}}> รายละเอียด </th>
            <th scope="col" style={{textAlign:"start"}}> ราคา </th>
            <th scope="col" > วันที่ซื้อ </th>
          </tr>
        </thead>
        <tbody>
          {slice
            ?.filter((text) => {
              if (searchText === "") {
                return text;
              } else if (
                text.name_member
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
              ) {
                return text;
              }
            })
            .map((data, index) => (
              <tr key={index}>
                <td className="flex flex-row justify-center items-center gap-2">
                  <div className='flex flex-row justify-between items-center gap-4'>
                    {/* <label >ลำดับที่</label> */}
                    <input
                      className='w-10 p-1 bg-gray-300/50 text-center rounded'
                      type="text"
                      value={data.priority_recommend}
                      onKeyPress={(e) => regNumber(e)}
                      onChange={(e) => onSetProductList(index, e)}
                    />
                    <i onClick={() => apiSetPriority(data.product_code, data.priority_recommend)} className="text-xl fa-solid fa-repeat hover:scale-125 duration-200 cursor-pointer"></i>
                  </div>
                </td>
                <td className="min-w-[5rem] lg:min-w-[10rem]">
                  <div className="flex flex-row items-center justify-start gap-2">
                  <p className="font-bold">{data.storeName}</p>
                  </div>
                </td>
                <td className="min-w-[5rem] lg:min-w-[10rem]">
                  <div className="flex flex-row items-center justify-start gap-2">
                  <p className="font-bold">{data.username}</p>
                  </div>
                </td>
                <td className="min-w-[5rem] lg:min-w-[18rem]">
                  <div className="flex flex-row items-center justify-start gap-2">
                    <p className="font-bold">สินค้าพร้อมส่ง :</p>
                    <p>{data.name_member} </p>
                  </div>
                  <div className="flex flex-row items-center justify-start gap-2">
                    <p className="font-bold">สินค้าพรีเมี่ยม :</p>
                    <p>{data.name_premium} </p>
                  </div>
                </td>
                <td className="min-w-[5rem] lg:min-w-[18rem]">
                  <div className="flex flex-row items-center justify-start gap-2">
                    <p className="font-bold">สินค้าพร้อมส่ง :</p>
                    <p>{data.content_member} </p>
                  </div>
                  <div className="flex flex-row items-center justify-start gap-2">
                    <p className="font-bold">สินค้าพรีเมี่ยม :</p>
                    <p>{data.content_premium} </p>
                  </div>
                </td>
                <td className="min-w-[5rem] lg:min-w-[18rem]">
                  <div className="flex flex-row items-center justify-start gap-2">
                    <p className="font-bold">สินค้าพร้อมส่ง :</p>
                    <p>{data.price_standard} </p>
                  </div>
                  <div className="flex flex-row items-center justify-start gap-2">
                    <p className="font-bold">สินค้าพรีเมี่ยม :</p>
                    <p>{data.price_premium} </p>
                  </div>
                </td> 
                <td className='py-2 px-1 text-center min-w-[5rem] lg:min-w-[7rem]'>
                  <FormatDate dateTime={data.createdAt} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </>
  );
};

export default Table;
