import React from "react";
import styles from "./loading.module.css";
 
function Loading(props) {
  return (
    <div className={props.loading ? styles.body_loading : styles.none} style={{top: 0, left: 0}}>
      <div className={styles.lds_ellipsis}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Loading;