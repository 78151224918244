import React, { useState } from 'react'
import Table from './Table';
import st from '../../styles/allUse/content.module.scss'

export default function OrderFashion() {
    const [searchText, setSearchText] = useState("")

    return (
        <div className={`main-page ${st.content} animate-[fade_0.3s_ease-in-out]`} style={{paddingTop: '5rem', paddingBottom: '5rem'}}>
            <p className={st.title}>จัดการออเดอร์</p>
            <div className={st.searchText}>
                <input onChange={e => setSearchText(e.target.value)} type="text" placeholder='ค้นหาออเดอร์' style={{ margin: "0 0 0 auto" }} />
                <i className="fa-solid fa-magnifying-glass"></i>
            </div>
            <div className={st.contentTable}>
                <Table rowsPerPage={13} searchText={searchText} />
            </div>
        </div>

    )
}

