import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import moment from "moment";

import st from "../../../styles/order/orderDetail.module.scss";
import CustomizedStepperFashion from "../../sub_component/stepperFashion";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 800,
  timerProgressBar: true,
});

export default function OrderFashionDetail() {
  const query = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const apiUrl = useSelector((state) => state.app.apiPath);
  const access_token = useSelector((state) => state.app.access_token);
  const [orderNumber, setOrderNumber] = useState(query.get("orderNumber"));
  const [orderDetail, setOrderDetail] = useState({});
  const [productList, setProductList] = useState(null);
  const [productRec, setProductRec] = useState(null);
  const [product, setProduct] = useState(null);
  const [gpValue, setGpValue] = useState();
  const [store, setStore] = useState(null);
  const [priceHead, setPricHead] = useState("ราคา (รวม GP)");

  useEffect(() => {
    apiGetOrder();
  }, []);

  async function apiGetOrder() {
    await axios({
      method: "GET",
      url: `${apiUrl}/api/admin/orderfashion/get`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }).then((res) => {
      if (res.data.status) {
        for (let order of res.data.order_fashion) {
          if (orderNumber === order.order_number) {
            setOrderDetail(order);
            setProductRec(order.OrdersProduct);
            setProduct(order.OrdersProduct);
            setProductList(order.OrdersProduct);
            setStore(order.Store);

            if (order.OrdersPayment?.payment_type === "COD") setPricHead("ราคา (รวม GP, COD)")
            if (!order.isRead) {
              apiSetReadOrder();
            }
          }
        }
      }
    });
  }

  function stateStep(state) {
    switch (state) {
      case "pending":
        return 1;
      case "shipping":
        return 2;
      case "success":
        return 3;
      case "accepted":
        return 4;
      default:
        return 0;
    }
  }

  function handleChangeStatus(statusToChange, product_id, currentState) {
    let prev = stateStep(currentState);
    let next = stateStep(statusToChange);
    if (next <= prev || prev === 0) {
      return false;
    } else if (next !== prev + 1) {
      return false;
    }
    Swal.fire({
      title: "ยืนยันการเปลี่ยนสถานะ",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      confirmButtonColor: "#C93A87",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        apiUpdateStatusProduct(statusToChange, product_id);
      }
    });
  }

  async function apiUpdateStatusProduct(statusToChange, product_id) {
    await axios({
      method: "POST",
      url: `${apiUrl}/api/admin/orderfashion/updateProductStatus`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: {
        orderNumber: orderNumber,
        productId: product_id,
        status: statusToChange,
      },
    }).then((res) => {
      Toast.fire({
        icon: "success",
        title: "สำเร็จ",
      }).then(() => {
        apiGetOrder();
      });
    });
  }

  function handleSetGP(data) {
    Swal.fire({
      title: "ยืนยันการเปลี่ยนค่า GP",
      text: "จาก " + data.grossProfit + "%" + " เป็น " + gpValue + "%",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      confirmButtonColor: "#C93A87",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        apiSetGPValue(data, gpValue);
      }
    });
  }

  async function apiSetGPValue(data, gpValue) {
    await axios({
      method: "POST",
      url: `${apiUrl}/api/admin/orders/updateGpProduct`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: {
        orderNumber: orderNumber,
        productId: data.product_id,
        gp: gpValue,
      },
    }).then((res) => {
      Toast.fire({
        icon: "success",
        title: "สำเร็จ",
      }).then(() => {
        setGpValue("");
        apiGetOrder("");
      });
    });
  }

  function handleCancelProduct(data) {
    Swal.fire({
      title: "ยืนยันการยกเลิกสินค้า",
      text: "ชื่อสินค้า : " + data.product_name,
      html: `<textarea placeholder="หมายเหตุ" class="border-2" type="text" style="padding: 0.7rem ;border-radius: 10px ; width: 90%" id="note_cancel"/>`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      confirmButtonColor: "#ff0303",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      preConfirm: () => {
        return document.getElementById("note_cancel").value.trim();
      },
    }).then((result) => {
      if (result.isConfirmed) {
        apiCancelProduct(data.product_id, result.value);
      } else {
        return false;
      }
    });
  }

  async function apiCancelProduct(product_id, note) {
    await axios({
      method: "POST",
      url: `${apiUrl}/api/admin/orders/cancelProductInOrderFashion`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: {
        orderNumber: orderNumber,
        productId: parseInt(product_id),
        note: note ? note : "",
      },
    }).then((res) => {
      Toast.fire({
        icon: "success",
        title: "สำเร็จ",
      }).then(() => {
        setGpValue("");
        apiGetOrder("");
      });
    });
  }

  async function apiSetReadOrder() {
    await axios({
      method: "GET",
      url: `${apiUrl}/api/admin/orderfashion/read/${orderNumber}`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  function handleReportOrder() {
    Swal.fire({
      title: "แจ้งบัญหา",
      html: `<textarea placeholder="หมายเหตุ" className="border-2" type="text" style="padding: 0.7rem ;border-radius: 10px ; width: 90%" id="message"/>`,
      showCancelButton: true,
      confirmButtonText: "แจ้งปัญหา",
      cancelButtonText: "ยกเลิก",
      confirmButtonColor: "#ff0303",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      preConfirm: () => {
        if (document.getElementById("message").value.trim() === "") {
          return false;
        }
        return document.getElementById("message").value.trim();
      },
    }).then((result) => {
      if (result.isConfirmed) {
        apiReportOrder(result.value);
      } else {
        return false;
      }
    });
  }

  async function apiReportOrder(message) {
    await axios({
      method: "POST",
      url: `${apiUrl}/api/admin/orderfashion/updateStatus`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: {
        message: message,
        orderNumber: orderDetail.order_number,
        status: "failed",
      },
    }).then((res) => {
      if (res.data.status) {
        Toast.fire({
          icon: "success",
          title: "สำเร็จ",
        });
        apiGetOrder();
      }
    });
  }

  return (
    <>
      {true && (
        <div
          className={`main-page ${st.content} w-full animate-[fade_0.3s_ease-in-out]`}
        >
          <div className={st.wrapBtnBack}>
            <button
              onClick={() => navigate(-1)}
              className="flex gap-2 align-center "
            >
              <i className="flex my-auto text-pink-500 hover:text-[21px] duration-200 cursor-pointer text-xl fa-solid fa-circle-arrow-left"></i>
              <p className="text-pink-500 ">Back to order</p>
            </button>
          </div>
          <div className={`${st.contentTable} w-full custom-table-detail`}>
            <div className="">
              <table>
                <thead>
                  <tr>
                    <th scope="col"> order NO.</th>
                    {/* <th scope="col"> user ผู้ขาย</th> */}
                    <th scope="col"> หมวดหมู่สินค้า </th>
                    <th scope="col"> ชื่อร้าน </th>
                    <th scope="col"> user ผู้ขาย </th>
                    {/* <th scope="col"> %GP</th> */}
                    <th scope="col"> {priceHead} </th>
                    <th scope="col" className="min-w-[8rem]">
                      {" "}
                      ราคาหลังหัก GP ({`${productList?.gross_profit}%`}){" "}
                    </th>
                    <th scope="col"> หมายเหตุ </th>
                    <th scope="col">
                      {orderDetail !== undefined &&
                      orderDetail.status !== "failed" ? (
                        <div className={st.reportOrder}>
                          <button
                            onClick={() => handleReportOrder()}
                            type="button"
                          >
                            <i className="my-auto fa-solid fa-circle-minus"></i>
                            แจ้งปัญหาออเดอร์
                          </button>
                        </div>
                      ) : (
                        <p>สถานะออเดอร์</p>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody className="w-full">
                  {productList && (
                    <tr>
                      <td>{productList.order_number}</td>
                      {/* <td>{data.store_user}</td> */}
                      <td>
                        {productList.ProductFashion
                          ? productList.ProductFashion.ProductCategory.name
                          : "ไม่มีสินค้า"}
                      </td>
                      {/* <td>{productList?.ProductFashion.price}</td> */}
                      <td>{store?.name}</td>
                      <td>{store?.username}</td>
                      <td>{orderDetail.totalprice}</td>
                      {/* <td className="flex flex-row gap-4 justify-center items-center">
                        <input
                        onChange={(e) => setGpValue(e.target.value.trim(""))}
                        className="w-16 text-center"
                        type="text"
                        placeholder={productList.grossProfit}
                        />
                        <i
                        onClick={() => handleSetGP(productList)}
                        className="cursor-pointer hover:scale-105 font-bold text-2xl fa-solid fa-square-arrow-up-right text-yellow-700"
                        ></i>
                    </td> */}
                      <td>{orderDetail.netprice}</td>
                      <td>
                        {productList.ProductFashion
                          ? productList.note
                          : "ไม่มีสินค้า"}
                      </td>
                      <td>
                        {productList.product_status === "canceled" ? (
                          <p className="text-red-500 font-bold">
                            ยกเลิกสินค้าแล้ว
                          </p>
                        ) : productList.product_status === "pending" ? (
                          <div className={st.reportProduct}>
                            <button
                              onClick={() => handleCancelProduct(productList)}
                              type="button"
                            >
                              <i className="my-auto fa-solid fa-circle-minus"></i>
                              ยกเลิกสินค้า
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {orderDetail !== undefined && orderDetail.status === "failed" && (
              <div className="flex flex-row justify-center items-center">
                <p className="bg-red-400 text-white font-bold text-left text-lg rounded pl-10 sm:pl-4 w-[98%] sm:w-[95%] py-2 mt-2">
                  ออเดอร์ผิดพลาดเนื่องจาก : {orderDetail.message}
                </p>
              </div>
            )}
            <div className={st.contentBottom}>
              {product && (
                <div className={st.contentProduct}>
                  <p className={st.title}>สินค้าพร้อมส่ง</p>
                  <div>
                    <div className={st.product}>
                      <img
                        src={`${apiUrl}${product.ProductFashion?.ProductFashionImages[0]?.path_img}`}
                        alt="productImage"
                        onError={(e) => {
                          e.target.setAttribute("src", "/assets/empty.png");
                          return false;
                        }}
                      />
                      <div className={st.productDetail}>
                        <p className={`font-bold ${st.pd_name}`}>
                          {product.ProductFashion?.product_name}
                        </p>
                        <p className={st.pd_content}>
                          {product.ProductFashion?.details}
                        </p>
                        <p
                          className={` ${st.pd_status}
                                                        ${
                                                          product.status ===
                                                            "pending" &&
                                                          "bg-yellow-600  hover:shadow-lg absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                        }
                                                        ${
                                                          product.status ===
                                                            "accepted" &&
                                                          "bg-green-600   hover:shadow-lg  absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                        }
                                                        ${
                                                          product.status ===
                                                            "success" &&
                                                          "bg-green-600   hover:shadow-lg  absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                        }
                                                        ${
                                                          product.status ===
                                                            "shipping" &&
                                                          "bg-orange-600   hover:shadow-lg absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                        }
                                                        ${
                                                          product.status ===
                                                            "canceled" &&
                                                          "bg-red-600   hover:shadow-lg absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                        }`}
                        >
                          {product.status}
                        </p>
                        <div className="absolute bg-white w-20 h-8 top-3 right-2 flex flex-row items-center justify-center gap-2 rounded-bl-lg">
                          <p className={`text-xl font-bold`}>{product.price}</p>
                          <i className="text-red-500 fa-solid fa-tags"></i>
                        </div>
                      </div>
                      {productList.ProductFashion && (
                        <div className="w-full rounded-b-lg bg-gray-300">
                          <CustomizedStepperFashion
                            product={product}
                            handleChangeStatus={handleChangeStatus}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {/* {productRec && (
                <div className={st.contentProduct}>
                  <p className={st.title}>สินค้าแนะนำ</p>
                  <div>
                    <div className={st.product}>
                      <img
                        src={`${apiUrl}${data.product_image}`}
                        alt="productImage"
                        onError={(e) => {
                          e.target.setAttribute("src", "/assets/empty.png");
                          return false;
                        }}
                      />
                      <div className={st.productDetail}>
                        <p className={`font-bold ${st.pd_name}`}>
                          {data.product_name}
                        </p>
                        <p className={st.pd_content}>{data.product_content} </p>
                        <p
                          className={` ${st.pd_status}
                                                        ${
                                                          data.product_status ===
                                                            "pending" &&
                                                          "bg-yellow-600  hover:shadow-lg absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                        }
                                                        ${
                                                          data.product_status ===
                                                            "accepted" &&
                                                          "bg-green-600   hover:shadow-lg  absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                        }
                                                        ${
                                                          data.product_status ===
                                                            "success" &&
                                                          "bg-green-600   hover:shadow-lg  absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                        }
                                                        ${
                                                          data.product_status ===
                                                            "shipping" &&
                                                          "bg-orange-600   hover:shadow-lg absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                        }
                                                        ${
                                                          data.product_status ===
                                                            "canceled" &&
                                                          "bg-red-600   hover:shadow-lg absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                        }`}
                        >
                          {data.product_status}
                        </p>
                        <div className="absolute bg-white w-20 h-8 top-3 right-2 flex flex-row items-center justify-center gap-2 rounded-bl-lg">
                          <p className={`text-xl font-bold`}>{data.price}</p>
                          <i className="text-red-500 fa-solid fa-tags"></i>
                        </div>
                      </div>
                      <div className="w-full rounded-b-lg bg-gray-300">
                        <CustomizedStepperFashion
                          product={data}
                          handleChangeStatus={handleChangeStatus}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
