import React from 'react'
import SideList from '../SideList'
import './layout-main.scss';

const LayoutMain = ({ children }) => {
    return (
        <React.Fragment>
            <div className='flex overflow-hidden'>
                <SideList />
                <div className='flex-1 w-full overflow-auto'>
                    <main style={{overflowY: 'auto'}}>{children}</main>
                </div>
            </div>
        </React.Fragment>
    )
}

export default LayoutMain;