import React, { useEffect } from 'react'
import { Dialog, Listbox, Transition } from '@headlessui/react'
import { Fragment } from 'react';

const DropdownPayStatus = ({ data, selectStatus, setSelectStatus, statusPaymentList, handleChangepaymentStatus }) => {
    useEffect(() => {
        // console.log(data)
    }, [])
    return (
        <Fragment>
            <Listbox value={selectStatus} onChange={setSelectStatus}>
                {selectStatus &&
                    <div className="relative">
                        <Listbox.Button className={`z-0 hover:border-blue-300 appearance-none border-2 border-gray-200 rounded-lg w-full py-1 px-4 text-gray-700 leading-tight 
                                                 ${data.paymentStatus === 'pending' && 'bg-yellow-500'}
                                                 ${data.paymentStatus === 'confirm' && 'bg-green-500'}
                                                 ${data.paymentStatus === 'failed' && 'bg-red-500 text-rose-100'}
                                                 ${data.paymentStatus === 'deposit' && 'bg-blue-500'}
                                                `}>
                            <span className="block truncate text-left text-white mr-2">
                                {data.paymentStatus === "pending" && 'รอการยืนยัน'}
                                {data.paymentStatus === "confirm" && 'ยืนยันแล้ว'}
                                {data.paymentStatus === "failed" && 'ผิดพลาด'}
                                {data.paymentStatus === "deposit" && 'มัดจำ'}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <i className="text-white fa-solid fa-arrow-down"></i>
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="z-10 absolute max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {statusPaymentList?.map((status, index) => (
                                    <Listbox.Option
                                        key={index}
                                        className={({ active }) =>
                                            `relative  cursor-pointer select-none p-2 ${active ? 'bg-pink-100 text-pink-900' : 'text-gray-900'
                                            }`
                                        }
                                        value={status}
                                    >
                                        {({ selected }) => (
                                            <>
                                                <span
                                                    className={`text-xs block truncate align-left ${selected ? 'font-medium' : 'font-normal'
                                                        }`}
                                                    onClick={() => handleChangepaymentStatus(status.paymentStatus, data.orderNumber)}
                                                >
                                                    {status.paymentStatus === "pending" && 'รอการยืนยัน'}
                                                    {status.paymentStatus === "confirm" && 'ยืนยันแล้ว'}
                                                    {status.paymentStatus === "deposit" && 'มัดจำ'}
                                                    {/* {status.paymentStatus === "failed" && 'ผิดพลาด'} */}
                                                </span>
                                                {selected ? (
                                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-pink-600">
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                }
            </Listbox>

        </Fragment>
    )
}

export default DropdownPayStatus;
