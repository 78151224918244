import { Menu } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { appAction } from "../store/app-slice";
import st from "../styles/sideBar/sideBar.module.scss";
import "./sidelist.scss";

const Menus = [
  {
    title: "Dashboard",
    number: "1",
    route: "/",
    fa: "fa-solid fa-house-chimney",
  }, //1
  {
    title: "บัญชีแอดมิน",
    number: "2",
    route: "/admin",
    fa: "fa-solid fa-user",
  }, //2
  {
    title: "ออเดอร์",
    number: "3",
    route: "/order",
    fa: "fa-solid fa-border-all",
  }, //3
  {
    title: "ออเดอร์สินค้าแฟชั่น",
    number: "15",
    route: "/orderfashion",
    fa: "fa-solid fa-border-all",
  }, //15
  {
    title: "ประเภทสินค้า",
    number: "16",
    route: "/productcate",
    fa: "fa-solid fa-list",
  }, //16
  {
    title: "สินค้าแนะนำ",
    number: "4",
    route: "/product",
    fa: "fa-brands fa-product-hunt",
  }, //4
  {
    title: "ผู้ใช้งาน",
    number: "5",
    route: "/account",
    fa: "fa-solid fa-address-card",
  }, //5
  { title: "ร้านค้า", number: "6", route: "/store", fa: "fa-solid fa-shop" }, //6
  {
    title: "การชำระค่าสมาชิก",
    number: "7",
    route: "/payment",
    fa: "fa-solid fa-dollar",
  }, //7

  {
    title: "บัญชีธนาคาร",
    number: "8",
    route: "/bank",
    fa: "fa-solid fa-money-check-dollar",
  }, //8
  { title: "บทความ", number: "9", route: "/content", fa: "fa-solid fa-book" }, //9
  {
    title: "แพ็คเก็จ",
    number: "10",
    route: "/package",
    fa: "fa-solid fa-box-open",
  }, //10
  {
    title: "รีวิว",
    number: "11",
    route: "/review",
    fa: "fa-solid fa-regular fa-star",
  }, //11
  {
    title: "รายงาน",
    number: "12",
    route: "/report",
    fa: "fa-solid fa-square-poll-vertical",
  }, //12
  // { title: "แชท", number: "13", route: '/livechat', fa: "fa-solid fa-chart-bar" }, //13
  {
    title: "ตั้งค่า",
    number: "14",
    route: "/setting",
    fa: "fa-solid fa-screwdriver-wrench",
  }, //14
  // { title: "คู่มือ", number: "17", route: '/setting', fa: "fa-solid fa-screwdriver-wrench" }, //17
];

function SideList() {
  const query = useLocation().pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apiUrl = useSelector((state) => state.app.apiPath);
  const access_token = useSelector((state) => state.app.access_token);
  const [open, setOpen] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [select, setSelect] = useState(localStorage.getItem("sideMenuSelect"));
  const [profileImg, setProfileImg] = useState(
    localStorage.getItem("profileImg")
  );
  const [adminName, setAdminName] = useState(localStorage.getItem("adminName"));
  const [topDashboard, setTopDashboard] = useState({});
  const [permission, setPermission] = useState([]);

  useEffect(() => {
    if (query === "/order" || query === "/account" || query === "/product") {
      //เพราะตารางมีขนาดใหญ่ เลยให้มันย่อ side bar ให้เป็นขนาดเล็ก มันจะได้ไม่บีบ
      setOpen(true);
      // setOpen(false)
    } else {
      setOpen(true);
    }
    apiGetDashboard();
  }, [query]); // ดัก query ให้มัน get เอา เลขการเเจ้งเตือนมาแสดงเมื่อ route หน้า

  async function apiGetDashboard() {
    await axios({
      method: "GET",
      url: `${apiUrl}/api/admin/getDashboard`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }).then((res) => {
      if (res.data.status) {
        setTopDashboard(res.data.forTopDashboard);
      }
    });
    await axios({
      method: "GET",
      url: `${apiUrl}/api/admin/permission`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }).then((res) => {
      if (res.data.status) {
        setPermission(res.data.permission.split(","));
      }
    });
  }

  function router(index, route) {
    setSelect(index);
    navigate(route);
  }

  function logout() {
    dispatch(appAction.logout());
    window.location.reload();
  }

  const openManual = () => {
    const version = Math.floor(Date.now() / 1000);
    const url = `${apiUrl}/uploads/manual/manual.pdf?v=${version}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <div
        className={` flex-none
            ${st.hiddenScroll} 
            ${
              open ? "w-72 min-w-[18rem]" : "w-20 min-w-[5rem]"
              // (query === '/order' || query === '/account' || query === '/product')
              // ? 'fullScreen:min-w-[4.3rem] fullScreen:max-w-[4.3rem]' : 'w-20'
            } 
            hide-sidebar-sm:hidden collapse-sidebar-md:w-20 bg-dark-purple h-screen p-5  
            pt-8 relative duration-300 overflow-y-scroll`}
      >
        {Menu.title ? (
          <img
            src="./assets/control.png"
            alt="control"
            className={`absolute cursor-pointer -right-3 top-9 w-7 border-dark-purple  duration-300
                        border-2 rounded-full  ${
                          !open && "rotate-180"
                        } collapse-sidebar-md:rotate-180`}
            onClick={() => setOpen(!open)}
          />
        ) : (
          <img
            src="../../assets/control.png"
            alt="control"
            className={`absolute cursor-pointer -right-3 top-9 w-7 border-dark-purple  duration-300
                        border-2 rounded-full  ${
                          !open && "rotate-180"
                        } collapse-sidebar-md:rotate-180`}
            onClick={() => setOpen(!open)}
          />
        )}

        <div className="flex gap-x-4 items-center ">
          {Menu.title ? (
            <img
              src="./assets/logo-fillfin.png"
              alt="logo"
              className={`bg-pink-500 cursor-pointer duration-500 w-20 
                            ${
                              open && "rotate-[360deg]"
                            } collapse-sidebar-md:rotate-[360deg]`}
            />
          ) : (
            <img
              src="../../assets/logo-fillfin.png"
              alt="logo"
              className={`bg-pink-500 cursor-pointer duration-500 w-20 
                            ${
                              open && "rotate-[360deg]"
                            } collapse-sidebar-md:rotate-[360deg]`}
            />
          )}
          <h1
            className={`text-white origin-left font-medium text-xl duration-200 
                        ${!open && "scale-0"} collapse-sidebar-md:scale-0`}
          >
            Back Office
          </h1>
        </div>
        <ul>
          {adminName && (
            <div
              className={`${
                open ? "" : "hidden"
              } flex flex-col rounded-collapse-sidebar-md p-2 my-3 text-gray-300 text-sm items-center gap-y-4`}
            >
              <img
                src={`${apiUrl}${profileImg}`}
                alt="admin"
                style={{ boxShadow: "0px 0px 13px 0px rgba(255,255,255,1)" }}
                className={`rounded-full w-16 h-16 object-cover`}
                onError={(e) => {
                  e.target.setAttribute("src", "/assets/contact.jpg");
                }}
              />
              <span className={` origin-left duration-200`}>{adminName}</span>
            </div>
          )}
          {Menus.map((Menu, index) => (
            <React.Fragment key={index}>
              {permission.indexOf(Menu.number) >= 0 && (
                <li
                  key={index}
                  className={`flex rounded-lg collapse-sidebar-md p-2 cursor-pointer relative hover:bg-[#f0f8ff] hover:text-pink-600 text-gray-300 text-sm items-center gap-x-4  duration-75 mt-3 
                            ${
                              index == select && "bg-[#f0f8ff] text-pink-600"
                            } `}
                  onClick={() => (
                    router(index, Menu.route),
                    localStorage.setItem("sideMenuSelect", index)
                  )}
                >
                  <i
                    className={`${Menu.fa} ${
                      index == select && " text-pink-600"
                    } w-[14px] `}
                  ></i>
                  <span
                    className={`${!open && "hidden"} origin-left duration-200`}
                  >
                    {Menu.title}
                  </span>

                  {Menu.title == "ออเดอร์" && open && (
                    <p className={`${st.notification} md-to-opx:hidden`}>
                      {topDashboard.newOrder != 0 && topDashboard.newOrder}
                    </p>
                  )}
                  {Menu.title == "ออเดอร์สินค้าแฟชั่น" && open && (
                    <p className={`${st.notification} md-to-opx:hidden`}>
                      {topDashboard.newOrderFashion != 0 &&
                        topDashboard.newOrderFashion}
                    </p>
                  )}
                  {Menu.title == "ร้านค้า" && open && (
                    <>
                      <div className={st.notificationGroup}>
                        <span className={st.notifyList}>
                          <i>โพสต์</i> <b>{topDashboard.newPost}</b>
                        </span>
                        <span className={st.notifyList}>
                          <i>โปรไฟล์</i> <b>{topDashboard.newProfile}</b>
                        </span>
                        <span className={st.notifyList}>
                          <i>ร้านค้า</i> <b>{topDashboard.newStore}</b>
                        </span>
                      </div>
                    </>
                  )}
                  {Menu.title == "การชำระค่าสมาชิก" && open && (
                    <p className={`${st.notification} md-to-opx:hidden`}>
                      {topDashboard.newMember != 0 && topDashboard.newMember}
                    </p>
                  )}
                  {Menu.title == "แชท" && open && (
                    <p className={`${st.notification} md-to-opx:hidden`}>
                      {topDashboard.newChat != 0 && topDashboard.newChat}
                    </p>
                  )}
                </li>
              )}
            </React.Fragment>
          ))}
          <li
            className="flex w-full rounded-lg collapse-sidebar-md p-2 cursor-pointer relative hover:bg-[#f0f8ff] hover:text-pink-600 text-gray-300 text-sm items-center gap-x-4  duration-75 mt-3"
            onClick={() => openManual()}
          >
            <i className="fa-solid fa-book"></i>
            <span className={`${!open && "hidden"} origin-left duration-200`}>คู่มือการใช้งาน</span>
          </li>
          <li
            className="flex mt-4 rounded-collapse-sidebar-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4"
            onClick={() => logout()}
          >
            <i className="fa-solid fa-right-from-bracket text-red-700"></i>
            <span
              className={`${
                !open && "hidden"
              } text-red-500 origin-left duration-200`}
            >
              ออกจากระบบ
            </span>
          </li>
        </ul>
      </div>

      <div className="hidden sm:flex justify-end items-center">
        <i
          className={`text-2xl object-contain fixed right-6 top-4 z-50 cursor-pointer before:w-52 before:h-52 before:p-2 before:bg-[#e3dfdf94]
                ${toggle ? "fa-solid fa-circle-xmark" : "fa-solid fa-bars"}`}
          onClick={() => setToggle((prev) => !prev)}
        ></i>
        <div
          className={`${
            !toggle ? "opacity-0 right-20 -z-50" : "opacity-100 right-6  z-50"
          } mobile-navbar px-4 py-2 bg-gray-800 absolute top-14 rounded-xl sidebar duration-200`}
          style={{ boxShadow: "0px 0px 12px 0px rgb(0 0 0 / 70%)" }}
        >
          <ul className="list-none flex justify-end items-start flex-1 flex-col">
            {adminName && (
              <div className="flex flex-col rounded-collapse-sidebar-md p-2 my-5 justify-center mx-auto cursor-pointer text-gray-300 text-sm items-center gap-y-4">
                <img
                  width={50}
                  height={50}
                  src={`${apiUrl}${profileImg}`}
                  style={{ boxShadow: "0px 0px 13px 0px rgba(255,255,255,1)" }}
                  className="rounded-full w-14 h-14 object-cover"
                  alt="admin"
                  onError={(e) => {
                    e.target.setAttribute("src", "/assets/contact.jpg");
                  }}
                />
                <span className={` origin-left duration-200`}>{adminName}</span>
              </div>
            )}
            {Menus.map((Menu, index) => (
              <React.Fragment key={index}>
                {permission.indexOf(Menu.number) >= 0 && (
                  <li
                    className={`flex relative rounded-lg collapse-sidebar-md w-full p-2 cursor-pointer hover:bg-[#f0f8ff] hover:text-pink-600 text-gray-300 text-sm items-center gap-x-4  duration-300
                                            ${Menu.gap ? "mt-9" : "mt-1"} 
                                            ${
                                              index == select &&
                                              "bg-[#f0f8ff] text-pink-600"
                                            } `}
                    onClick={() => (
                      router(index, Menu.route),
                      localStorage.setItem("sideMenuSelect", index),
                      setToggle((prev) => !prev)
                    )}
                  >
                    <i
                      className={`${Menu.fa} ${
                        index == select && " text-pink-600"
                      } w-[14px] `}
                    ></i>
                    <span className={` origin-left duration-200`}></span>
                    {Menu.title}
                    {Menu.title == "ออเดอร์" && (
                      <p className={st.notification_sm}>
                        {topDashboard.newOrder != 0 && topDashboard.newOrder}
                      </p>
                    )}
                    {Menu.title == "ออเดอร์สินค้าแฟชั่น" && (
                      <p className={st.notification_sm}>
                        {topDashboard.newOrderFashion != 0 &&
                          topDashboard.newOrderFashion}
                      </p>
                    )}
                    {Menu.title == "ร้านค้า" && open && (
                      <>
                        <div className={st.notificationGroup}>
                          <span className={st.notifyList}>
                            <i>โพสต์</i> <b>{topDashboard.newPost}</b>
                          </span>
                          <span className={st.notifyList}>
                            <i>โปรไฟล์</i> <b>{topDashboard.newProfile}</b>
                          </span>
                          <span className={st.notifyList}>
                            <i>ร้านค้า</i> <b>{topDashboard.newStore}</b>
                          </span>
                        </div>
                      </>
                    )}
                    {Menu.title == "การชำระค่าสมาชิก" && (
                      <p className={st.notification_sm}>
                        {topDashboard.newMember != 0 && topDashboard.newMember}
                      </p>
                    )}
                    {Menu.title == "แชท" && (
                      <p className={st.notification_sm}>
                        {topDashboard.newChat != 0 && topDashboard.newChat}
                      </p>
                    )}
                  </li>
                )}
              </React.Fragment>
            ))}
            <li
              className="flex w-full rounded-lg collapse-sidebar-md p-2 cursor-pointer relative hover:bg-[#f0f8ff] hover:text-pink-600 text-gray-300 text-sm items-center gap-x-4  duration-75"
              onClick={() => openManual()}
            >
              <i className="fa-solid fa-book"></i>
              <span className={`origin-left duration-200`}>คู่มือการใช้งาน</span>
              
            </li>
            <li
              className="flex  rounded-collapse-sidebar-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4"
              onClick={() => logout()}
            >
              <i className="fa-solid fa-right-from-bracket text-red-700"></i>
              <span className={` origin-left duration-200 text-red-500`}>
                ออกจากระบบ
              </span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default SideList;
