import axios from "axios";
import moment from "moment";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Rating } from "@mui/material";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 800,
  timerProgressBar: true,
});

export default function ReviewTable({ reviewList, apiGetReview, section, st }) {
  const apiUrl = useSelector((state) => state.app.apiPath);
  const access_token = useSelector((state) => state.app.access_token);

  useEffect(() => {
    apiGetReview();
  }, []);

  async function ApiChangeStatus(bankId, status) {
    if (status === "yes") {
      status = "no";
    } else if (status === "no") {
      status = "yes";
    }
    try {
      await axios({
        method: "POST",
        url: `${apiUrl}/api/admin/changeStatusReview/${section}`,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        data: {
          id: bankId,
          display: status,
        },
      }).then((res) => {
        Toast.fire({
          icon: "success",
          title: "แก้ไขแล้ว",
        });
      });
    } catch (err) {
      // console.log(err);
    }
  }

  function FormatDate({ dateTime }) {
    dateTime = moment(dateTime).format("DD MMM YYYY");
    return <span>{dateTime}</span>;
  }

  function handleDelectReview(data) {
    Swal.fire({
      title: "ยืนยันการลบรีวิว",
      text: data.message,
      showCancelButton: true,
      confirmButtonText: "ลบ",
      cancelButtonText: "ยกเลิก",
      confirmButtonColor: "#ff0303",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        apiDeleteReview(data.id);
      }
    });
  }

  async function apiDeleteReview(id) {
    await axios({
      method: "GET",
      url: `${apiUrl}/api/admin/review/delete/${id}/${section}`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }).then(() => {
      Toast.fire({
        icon: "success",
        title: "ลบแล้ว",
      });
      apiGetReview();
    });
  }

  return (
    <div className={st.contentTable}>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="py-3 px-2 text-center">
              ลำดับ
            </th>
            <th scope="col" className="py-3 px-2 text-center">
              คะแนน
            </th>
            <th scope="col" className="py-3 px-2">
              ข้อความ
            </th>
            <th scope="col" className="py-3 px-2 sm:px-16 text-center">
              วันที่
            </th>
            <th scope="col" className="py-3 px-2 text-center">
              สถานะ
            </th>
            <th scope="col" className="py-3 px-2 text-center">
              เพิ่มเติม
            </th>
          </tr>
        </thead>
        <tbody>
          {reviewList?.map((data, index) => (
            <tr
              key={index}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
            >
              <th
                scope="row"
                className="py-4 px-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {index + 1}
              </th>
              <td className="py-2 px-2 text-center">
                <Rating readOnly name="size-medium" value={data.star} />
              </td>
              <td className="py-2 px-2">{data.message}</td>
              <td className="py-2 px-2 text-center min-w-[7rem]">
                <FormatDate dateTime={data.createdAt} />
              </td>
              <td className="py-2 px-2 text-center">
                <label className="inline-flex relative items-center mr-5 cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    defaultChecked={data.display === "yes" ? true : false}
                    onClick={() => ApiChangeStatus(data.id, data.display)}
                  />
                  <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-yellow-300 dark:peer-focus:ring-yellow-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-yellow-600" />
                </label>
              </td>
              <td className="py-2 px-2 text-center">
                <div className="flex flex-row justify-center ">
                  <button
                    onClick={() => handleDelectReview(data)}
                    type="button"
                    className="text-white w-[5rem] bg-[#c82222] hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-1.5 text-center mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                  >
                    <i className="fa-solid fa-trash-can"></i> ลบ
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
