import { Dialog, Listbox, Transition } from '@headlessui/react'
import axios from 'axios';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1200,
    timerProgressBar: true,
})

export default function Modal_add({ position, onSetOpen, apiGetContent, st }) {
    const apiUrl = useSelector((state) => (state.app.apiPath))
    const access_token = useSelector((state) => (state.app.access_token))
    const inputImage = useRef([])
    const [imageShow, setImageShow] = useState({})
    const [selectPosition, setSelectPosition] = useState(position[0])

    useEffect(() => {
        console.log(position)
    }, [selectPosition, position])

    function inputImageOnChange(e) {
        if (!e.target.files.length) {
            return false;
        }
        if (
            ["image/jpeg", "iamge/jpg", "image/png", "image/webp"].includes(e.target.files[0].type)
        ) {
            setImageShow(URL.createObjectURL(e.target.files[0]));
        } else {
            Swal.fire({
                title: "กรุณาอัปโหลดเฉพาะไฟล์รูปภาพ",
                icon: "warning",
                position: "center",
                timer: 1000,
                showConfirmButton: false
            });
        }
    }
    function handleClickUpload() {
        const formData = new FormData()
        formData.append("image", inputImage.current.files[0])
        formData.append("position", selectPosition.position)
        Swal.fire({
            title: "ยืนยันการอัปโหลด",
            icon: "question",
            position: "center",
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
            confirmButtonColor: '#C93A87',
            showCancelButton: true,
            showConfirmButton: true
        }).then(res => {
            if (res.isConfirmed) {
                uploadImage(formData)
            }
        })
    }

    async function uploadImage(formData) {
        await axios({
            method: 'POST',
            url: `${apiUrl}/api/admin/createSlide`,
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
            data: formData
        })
        Toast.fire({
            icon: 'success',
            title: 'สำเร็จ'
        }).then(() => {
            apiGetContent()
            onSetOpen()
        })
    }

    return (
        <>
            <Transition appear show={true} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={onSetOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full max-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className={st.dialogPanel}>
                                    <Dialog.Title
                                        as="h2"
                                        className="pb-4 text-lg leading-6 text-gray-900"
                                    >
                                        เพิ่มโฆษณา
                                    </Dialog.Title>
                                    <form className="w-full">
                                        <div className='flex flex-col justify-center gap-3 items-center '>
                                            <div>
                                                {imageShow
                                                    ? (
                                                        <img
                                                            src={imageShow}
                                                            alt="image-second"
                                                            className='h-[10rem] w-[30rem] cursor-pointer bg-slate-100 object-cover'
                                                            onClick={() => inputImage.current.click()}
                                                            aria-hidden
                                                            onError={e => {
                                                                e.target.setAttribute('src', '/assets/empty.png')
                                                            }}

                                                        />
                                                    ) : (
                                                        <div onClick={() => inputImage.current.click()}>
                                                            <img src="/assets/empty.png" alt="empty" />
                                                        </div>
                                                    )}
                                                <input
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    accept=".jpg,.jpeg,.png,.webp"
                                                    ref={inputImage}
                                                    onChange={(e) => inputImageOnChange(e)}
                                                />
                                            </div>
                                            <div className="flex flex-row gap-3 mt-4 mb-12">
                                                <div className='flex flex-col gap-8 items-center justify-center'>
                                                    <p>ตำแหน่่ง</p>
                                                </div>
                                                <div className='flex flex-col gap-3 items-center justify-center '>
                                                    <Listbox value={selectPosition} onChange={setSelectPosition}>
                                                        <div className="relative mt-1">
                                                            <Listbox.Button className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-pink-500">
                                                                <span className="block truncate text-left mr-2">
                                                                    {selectPosition.position === "home" && 'หน้าหลัก'}
                                                                    {selectPosition.position === "login" && 'หน้าล็อกอิน'}
                                                                    {selectPosition.position === "store-women" && 'ร้านค้าผู้หญิง'}
                                                                    {selectPosition.position === "store-men" && 'ร้านค้าผู้ชาย'}
                                                                    {selectPosition.position === "home-fashion" && 'หน้าหลักแฟชั่น'}
                                                                    {selectPosition.position === "all-category" && 'หมวดหมู่แฟชั่น'}
                                                                </span>
                                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                    <i className=" fa-solid fa-arrow-right"></i>
                                                                </span>
                                                            </Listbox.Button>
                                                            <Transition
                                                                as={Fragment}
                                                                leave="transition ease-in duration-100"
                                                                leaveFrom="opacity-100"
                                                                leaveTo="opacity-0"
                                                            >
                                                                <Listbox.Options className="z-50  absolute top-[2.3rem] left-0 mt-1 max-h-72 w-[10rem] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                    {position?.map((data, index) => (
                                                                        <Listbox.Option
                                                                            key={index}
                                                                            className={({ active }) =>
                                                                                `z-50 relative cursor-default select-none px-2 ${active ? 'bg-pink-100 text-pink-900' : 'text-gray-900'
                                                                                }`
                                                                            }
                                                                            value={data}
                                                                        >
                                                                            {({ selected }) => (
                                                                                <>
                                                                                    <span
                                                                                        className={`block truncate align-left ${selected || selectPosition.position === data.position ? 'text-md text-center' : 'text-md'
                                                                                            }`}
                                                                                    >
                                                                                        {data.position === "home" && 'หน้าหลัก'}
                                                                                        {data.position === "login" && 'หน้าล็อกอิน'}
                                                                                        {data.position === "store-women" && 'ร้านค้าผู้หญิง'}
                                                                                        {data.position === "store-men" && 'ร้านค้าผู้ชาย'}
                                                                                        {data.position === "home-fashion" && 'หน้าหลักแฟชั่น'}
                                                                                        {data.position === "all-category" && 'หมวดหมู่แฟชั่น'}
                                                                                    </span>
                                                                                    {(selected || selectPosition.position === data.position)
                                                                                        ? (
                                                                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-pink-600">
                                                                                                <i className="fa-solid fa-check"></i>
                                                                                            </span>
                                                                                        ) : null
                                                                                    }
                                                                                </>
                                                                            )}
                                                                        </Listbox.Option>
                                                                    ))}
                                                                </Listbox.Options>
                                                            </Transition>
                                                        </div>
                                                    </Listbox>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="flex flex-row justify-end items-end ">
                                        <button
                                            type="button"
                                            className="rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => handleClickUpload()}
                                        >
                                            เพิ่ม
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )

}
