import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios';
import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import moment from "moment";
import { now } from "moment";

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1200,
    timerProgressBar: true,
})

export default function PopUpAddDay({ onSetOpen, memberData, apiGetAll }) {
    const apiUrl = useSelector((state) => (state.app.apiPath))
    const access_token = useSelector((state) => (state.app.access_token))
    const [day, setDay] = useState("")

    function handleAddDay() {
        Swal.fire({
            title: 'เพิ่มวันสมาชิก ' + day + ' วัน',
            showCancelButton: true,
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
            confirmButtonColor: "#f472b6",
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                apiAddDay()
            }
        })
    }

    async function apiAddDay() {
        await axios({
            method: 'POST',
            url: `${apiUrl}/api/admin/packageAddDate`,
            headers: {
                Authorization: `Bearer ${access_token}`
            },
            data: {
                member_id: memberData.id,
                day: day
            }
        }).then(res => {
            Toast.fire({
                icon: 'success',
                title: 'สำเร็จ'
            })
            onSetOpen()
            apiGetAll()
        })
    }

    return (
        <Transition appear show={true} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onSetOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-[18rem] max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h2"
                                    className=" text-xl leading-6 text-gray-900 text-center"
                                >
                                    เพิ่มวันสมาชิก
                                </Dialog.Title>
                                <div>
                                    <div className='flex flex-row justify-center items-center gap-2 mt-4 mb-2'>
                                        <p className='text-sm opacity-80'>ปัจจุบันคงเหลือ</p>
                                        <p className='text-sm opacity-80'>{memberData.expire ? moment(memberData.expire).diff(now(), 'days') : "-"}</p>
                                        <p className='text-sm opacity-80'>วัน</p>
                                    </div>
                                    <div className="flex flex-row items-center justify-center mb-4  gap-4">
                                        <input
                                            className="w-44 h-10 bg-slate-200 text-center rounded text-xl"
                                            value={day}
                                            onChange={(e) => {
                                                if (
                                                    /^[0-9]+$/.test(
                                                        e.target.value.trim()
                                                    ) || e.target.value == ""
                                                ) {
                                                    setDay(e.target.value.trim());
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-row items-center gap-2 justify-center ml-auto'>
                                    <button
                                        className='flex flex-row gap-2  text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-600 font-medium rounded text-sm px-2 py-1.5 text-center dark:focus:ring-green-900'
                                        onClick={() => handleAddDay()}
                                        type="button" >
                                        <p>ยืนยัน</p>
                                    </button>
                                    <button
                                        className='flex flex-row gap-2  text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded text-sm px-2 py-1.5 text-center dark:focus:ring-red-900'
                                        onClick={() => onSetOpen()}
                                        type="button" >
                                        <p>ยกเลิก</p>
                                    </button>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}
