import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { appAction } from "../../store/app-slice";

export default function Login() {
  const apiUrl = useSelector((state) => (state.app.apiPath))

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  useEffect(() => {
  }, [])


  async function handleLogin() {
    try {
      await axios({
        method: 'POST',
        url: `${apiUrl}/api/admin/signin`,
        data: {
          username: username,
          password: password
        }
      }).then(res => {
        if (res.data.status) {
          dispatch(appAction.login({
            isLogin: true,
            access_token: res.data.access_token,
            refresh_token: res.data.refresh_token,
          }))
          localStorage.setItem('accessToken', res.data.access_token)
          localStorage.setItem('refreshToken', res.data.refresh_token)
          localStorage.setItem('profileImg', res.data.profileImg)
          localStorage.setItem('adminName', res.data.adminName)
          navigate('/');
        }
      })
    } catch (err) {
      if (err.response.status === 401) {
        Swal.fire({
          icon: 'warning',
          position: 'center',
          title: 'รอแอดมินตรวจสอบ',
          timer: 1500,
          showConfirmButton: false
        })
      } else if (err.response.status === 404) {
        Swal.fire({
          icon: 'warning',
          position: 'center',
          title: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
          timer: 1500,
          showConfirmButton: false
        })
      }
    }
  }

  async function handleForgetPassword() {
    Swal.fire({
      title: 'ระบุอีเมลที่ต้องการเปลี่ยนรหัสผ่าน',
      html: `
      <div class="flex flex-col gap-4 justify-center items-center">
        <div class="flex flex-col gap-2">
          <label class="text-xl text-start" >email</label>
          <input class="border-2"  type="email" style="padding: 0.7rem ;border-radius: 10px ;" id="email"/>
        </div>
        <div class="flex flex-col gap-2">
        <label class="text-xl text-start" >username</label>
         <input class="border-2"  type="text" style="padding: 0.7rem ;border-radius: 10px ;" id="username"/>
        </div>
      </div>
      `,
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      confirmButtonColor: "#3088d5",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      preConfirm: () => {
        if (document.getElementById("email").value.trim() === "") {
          return false;
        }
        if (document.getElementById("username").value.trim() === "") {
          return false;
        }
        return (
          document.getElementById("email").value.trim(),
          document.getElementById("username").value.trim()
        )
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const email = document.getElementById("email").value.trim()
        const username = document.getElementById("username").value.trim()
        sendForgetPassword(email, username)
      }
    })
  }



  async function sendForgetPassword(email, username) {
    await axios({
      method: 'POST',
      url: `${apiUrl}/api/admin/resetPassword`,
      data: {
        email: email,
        username: username
      }
    }).then(() => {
      Swal.fire({
        icon: 'warning',
        position: 'center',
        title: 'สำเร็จ กรุณาตรวจสอบอีเมล',
        timer: 1500,
        showConfirmButton: true,
      })
    })
  }


  return (
    <div className="grid grid-cols-1 h-screen relative" >
      <div className="bg-white opacity-10 absolute top-0 w-[10rem] h-[10rem] rounded-br-full"></div>
      <div className="bg-white opacity-10 absolute bottom-0 right-0 w-[10rem] h-[10rem] rounded-tl-full"></div>
      <img
        className="imageBg absolute mix-blend-overlay w-full h-full object-cover "
        src="./assets/background/art2.jpg"
        alt="login-bg"
      />
      <div className=" lg:mx-auto lg:w-[30%] sm:h-screen md:rounded-sm lg:rounded-lg lg:my-auto bg-gray-800 flex flex-col justify-center duration-200">
        <div className="sm:rounded-lg md:rounded-lg sm:mx-2 md:mx-[10%] sm:bg-gray-700 duration-200 ">
          <form className="relative max-w-[380px] w-full mx-auto p-8 px-8 rounded-lg  contrast-200">
            {/* <img width={200} height={80} className="mb-5 mx-auto" src="/assets/logo-fillfin.png"/> */}
            <h2 className="text-4xl text-white font-bold text-center">
              เข้าสู่ระบบ
            </h2>
            <div className="flex flex-col text-gray-100 py-2">
              <label className="text-start ml-2">User name</label>
              <input
                type="text"
                defaultValue={username}
                onChange={(e) => setUsername(e.target.value)}
                className=" rounded-lg bg-gray-600 mt-2 p-2 focus:bg-white-800 focus:outline-none"
              />
            </div>
            <div className="flex flex-col text-gray-100 py-2">
              <label className="text-start ml-2">Password</label>
              <input
                type="password"
                defaultValue={password}
                onChange={(e) => setPassword(e.target.value)}
                className="rounded-lg bg-gray-600 mt-2 p-2 focus:bg-white-800 focus:outline-none"
              />
            </div>
            <div onClick={() => handleForgetPassword()} className="cursor-pointer flex flex-col text-white text-end text-rigth align-center text-sm mt-1">
              ลืมรหัสผ่าน?
            </div>
            <button
              type="button"
              style={{ boxShadow: "-2px 10px 17px 0px rgb(255 139 142 / 20%" }}
              className="shadow-transparent rounded-lg w-full mb-5 mt-7 py-2 bg-pink-700 font-bold text-white hover:bg-pink-900 hover:boder hover:boder-white"
              onClick={handleLogin}
            >
              Sign In
            </button>
            <a className="text-white hover:text-blue hover:text-gray-400" href="/register">
              sign up?
            </a>
          </form>
        </div>
      </div>
    </div>
  );
}
