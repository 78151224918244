import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';

export default function Register() {
  const apiUrl = useSelector((state) => (state.app.apiPath))
  const navigate = useNavigate()
  const inputImage = useRef([])
  const [image, setImage] = useState([])
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")

  useEffect(() => {
  }, [])

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1200,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  async function handleRegis() {
    if(username === "" || password === "" || name === "" || email === ""){
      Swal.fire({
        icon : 'warning',
        position : 'center',
        title : 'กรุณากรอกข้อมูลให้ครบ'
      })
      return false;
    }

    const formData = new FormData()
    formData.append("image", '')
    formData.append("username", username)
    formData.append("password", password)
    formData.append("name", name)
    formData.append("email", email)
    try {
      await axios({
        method: 'POST',
        url: `${apiUrl}/api/admin/register`,
        data: formData
      }).then(res => {
        if (res.data.status) {
          Toast.fire({
            icon: 'success',
            title: 'สมัครแล้ว'
          })
          navigate('/login');
        }
      })
    } catch (err) {
      // console.log(err);
    }
  }

  return (
    <div className="grid grid-cols-1  h-screen w-full">
      <img
        className="imageBg absolute mix-blend-overlay w-full h-full object-cover "
        src="./assets/background/leaf.jpg"
        alt="leaf"
      />
      <div className=" lg:mx-auto lg:w-[40%] sm:h-screen md:rounded-sm lg:rounded-lg lg:my-auto bg-gray-800 flex flex-col justify-center ">
        <div className="basis-2/4">
          <form className="relative max-w-[380px] w-full mx-auto p-8 px-8 rounded-lg  contrast-200">
            <h2 className="text-4xl text-white font-bold text-center">
              สมัครสมาชิก
            </h2>
            <div className="flex flex-col text-gray-100 py-2">
              <label className="text-start ml-2">name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className=" rounded-lg bg-gray-600 mt-2 p-2 focus:bg-white-800 focus:outline-none"
              />
            </div>
            <div className="flex flex-col text-gray-100 py-2">
              <label className="text-start ml-2">email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className=" rounded-lg bg-gray-600 mt-2 p-2 focus:bg-white-800 focus:outline-none"
              />
            </div>
            <div className="flex flex-col text-gray-100 py-2">
              <label className="text-start ml-2">User name</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className=" rounded-lg bg-gray-600 mt-2 p-2 focus:bg-white-800 focus:outline-none"
              />
            </div>
            <div className="flex flex-col text-gray-100 py-2">
              <label className="text-start ml-2">Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="rounded-lg bg-gray-600 mt-2 p-2 focus:bg-white-800 focus:outline-none"
              />
            </div>

            <button
              style={{ boxShadow: "-2px 10px 17px 0px rgb(255 139 142 / 20%" }}
              onClick={() => handleRegis()}
              type="button"
              className="shadow-transparent rounded-lg w-full my-5 py-2 bg-pink-700 font-bold text-white hover:bg-pink-900 hover:boder hover:boder-white"
            >
              REGISTER
            </button>
            <a className="text-white hover:text-gray-400" href="/login">
              already have an account?
            </a>
          </form>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}
