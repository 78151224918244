import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Table from "./Table";
import PackageAddModel from "../PackageAddModel";
import st from "../../../styles/allUse/content.module.scss";

const initPackage = {
  content: "",
  subject: "",
  day: "",
  grossProfit: "",
  price: "",
  gender: "men",
  name: "MEMBER",
}

export default function EditPackage() {
  const apiUrl = useSelector((state) => state.app.apiPath);
  const access_token = useSelector((state) => state.app.access_token);

  const [refreshData, setRefreshData] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [packageList, setPackageList] = useState([]);
  const [subPackageList, setSubPackageList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSub, setOpenSub] = useState(false);
  const [addSub, setAddSub] = useState(false);
  const [packageEdit, setPackageEdit] = useState({});

  useEffect(() => {
    apiGetPackage();
  }, [refreshData]);

  async function apiGetPackage() {
    setPackageEdit(initPackage);
    await axios({
      method: "GET",
      url: `${apiUrl}/api/admin/package/get`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }).then((res) => {
      if (res.data.status) {
        const data = res.data.data;
        const main_package = data?.filter((item) => item.is_main_package); 
        const sub_package = data?.filter((item) => !item.is_main_package); 
        setPackageList(main_package);
        setSubPackageList(sub_package)
      }
    });
  }

  function onSetOpen() {
    setIsOpen(!isOpen);
  }

  const onAddSub = () => {
    setPackageEdit(initPackage);
    setOpen(false);
    setOpenSub(true);
    setAddSub(true);
  };

  return (
    <div
      className={`flex-1 overflow-hidden animate-[fade_0.3s_ease-in-out] sm:min-w-[380px]`}
    >
      <div className="w-full">
        <button
          onClick={onAddSub}
          type="button"
          className="ml-auto flex gap-2 align-center
                    item-center justify-center m-3 text-white bg-pink-700 hover:bg-pink-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
        >
          <i className="fa-solid fa-plus flex align-center justify-center"></i>
        </button>
      </div>
      <div className="overflow-x-auto relative mx-auto">
        {packageList.length > 0 && (
          <>
            <Table
              openSub={openSub}
              setOpenSub={setOpenSub}
              setPackageEdit={setPackageEdit}
              packageEdit={packageEdit}
              addSub={addSub}
              setAddSub={setAddSub}
              data={packageList}
              subPackageList={subPackageList}
              rowsPerPage={12}
              searchText={searchText}
              apiGetPackage={apiGetPackage}
              open={open}
              setOpen={setOpen}
            />
          </>
        )}
      </div>

      {isOpen && (
        <PackageAddModel
          onSetOpen={onSetOpen}
          setRefreshData={setRefreshData}
        />
      )}
    </div>
  );
}
