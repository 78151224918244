import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import ProtectRoute from './auth/ProtectRoute';
import Account from './components/account/Account';
import AccountDetail from './components/account/accountDetail/accountDetail';
// import LiveChat from './components/livechat/liveChat';
import Bank from './components/bank/Bank';
import Order from './components/order/order';
import OrderDetail from './components/order/orderDetail/orderDetail';
import Package from './components/package/Package';
import Payment from './components/payment/payment';
import PaymentDetail from './components/payment/paymentDetail/paymentDetail';
import Review from './components/review/Review';
import Login from './components/signin/login';
import Register from './components/signin/register';
import Store from './components/store/Store';
import StoreDetail from './components/store/storeDetail/storeDetail';
import Dashboard from './components/dashboard/dashboard';
import Admin from './components/admin/admin';
import AdminDetail from './components/admin/adminDetail/adminDetail';
import Report from './components/report/report';
import Content from './components/content/Content';
import ContentDetail from './components/content/contents/contentDetail/contentDetail';
import moment from 'moment'
import LayoutMain from './pages/layouts/layoutMain';
import ForgetPassword from './components/signin/ForgetPassword';
import ProductRec from './components/productRec/productRec';
import ProductCate from './components/productCate/productCate';
import Settings from './components/setting/Settings';
import OrderFashion from './components/orderFashion/order';
import OrderFashionDetail from './components/orderFashion/orderDetail/orderFashionDetail';

moment.locale('th');

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<ProtectRoute />} >
            {/* Route Level 1 */}
            <Route path='/' element={<LayoutMain> <Dashboard /> </LayoutMain>}/>
            <Route path='/content'   element={<LayoutMain> <Content /> </LayoutMain>}/>
            <Route path='/admin' element={<LayoutMain> <Admin /> </LayoutMain>}/>
            <Route path='/account' element={<LayoutMain> <Account /> </LayoutMain>}/>
            <Route path='/store' element={<LayoutMain> <Store /> </LayoutMain>}/>
            <Route path='/review' element={<LayoutMain> <Review /> </LayoutMain>}/>
            {/* <Route path='/livechat' element={<LayoutMain> <LiveChat /> </LayoutMain>}/> */}
            <Route path='/setting' element={<LayoutMain> <Settings /> </LayoutMain>}/>
            {/* <Route path='/setting' element={<LayoutMain> <Setting /> </LayoutMain>}/> */}
            <Route path='/bank' element={<LayoutMain> <Bank /> </LayoutMain>}/>
            <Route path='/package' element={<LayoutMain> <Package /> </LayoutMain>}/>
            <Route path='/payment' element={<LayoutMain> <Payment /> </LayoutMain>}/>
            <Route path='/order' element={<LayoutMain> <Order /> </LayoutMain>}/>
            <Route path='/orderfashion' element={<LayoutMain> <OrderFashion /> </LayoutMain>}/> 
            <Route path='/report' element={<LayoutMain> <Report /> </LayoutMain>}/>
            <Route path='/productcate' element={<LayoutMain> <ProductCate /> </LayoutMain>}/>
            <Route path='/product' element={<LayoutMain> <ProductRec /> </LayoutMain>}/>

            {/* Route Level 2 */}
            <Route path='/admin/detail' element={<LayoutMain> <AdminDetail /> </LayoutMain>}/>
            <Route path='/order/detail' element={<LayoutMain> <OrderDetail /> </LayoutMain>}/>
            <Route path='/orderfashion/detail' element={<LayoutMain> <OrderFashionDetail /> </LayoutMain>}/>
            <Route path='/content/detail' element={<LayoutMain> <ContentDetail /> </LayoutMain>}/>
            <Route path='/account/detail' element={<LayoutMain> <AccountDetail /> </LayoutMain>}/>
            <Route path='/store/detail' element={<LayoutMain> <StoreDetail /> </LayoutMain>}/>
            <Route path='/payment/detail' element={<LayoutMain> <PaymentDetail /> </LayoutMain>}/>
          </Route>
          
          {/* without auth */}
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/forgetPassword' element={<ForgetPassword />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;


