import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Modal_add from "./modal_add";
import Modal_edit from "./modal_edit";
import st from "../../styles/allUse/content.module.scss";
import BankTable from "./BankTable";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 800,
  timerProgressBar: true,
});

export default function Bank() {
  const apiUrl = useSelector((state) => state.app.apiPath);
  const access_token = useSelector((state) => state.app.access_token);
  const [bankList, setBankList] = useState([]);
  const [bankPackage, setBankPackage] = useState([]);
  const [bankUnderwear, setBankUnderwear] = useState([]);
  const [bankFashion, setBankFashion] = useState();
  const [bankData, setBankData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [choose, setChoose] = useState("");
  useEffect(() => {
    ApiGetBank();
  }, []);

  async function ApiGetBank() {
    setBankPackage([]);
    setBankUnderwear([]);
    setBankFashion([]);
    try {
      await axios({
        method: "GET",
        url: `${apiUrl}/api/admin/bank/get`,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }).then((res) => {
        const bankLists = res.data.data;
        setBankList(bankLists);

        const bankPackageList = bankLists?.filter(b => b.bank_type_id === 1);
        const bankFashionList = bankLists?.filter(b => b.bank_type_id === 2);
        const bankUnderwearList = bankLists?.filter(b => b.bank_type_id === 3);

        setBankPackage(bankPackageList);
        setBankUnderwear(bankUnderwearList);
        setBankFashion(bankFashionList);
      });
    } catch (err) {
      // console.log(err);
    }
  }

  async function ApiChangeStatus(bankId, status) {
    if (status === "active") {
      status = "inactive";
    } else if (status === "inactive") {
      status = "active";
    }
    try {
      await axios({
        method: "POST",
        url: `${apiUrl}/api/admin/changeStatusBank`,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        data: {
          bank_id: bankId,
          status: status,
        },
      }).then((res) => {
        Toast.fire({
          icon: "success",
          title: "แก้ไขแล้ว",
        });
        ApiGetBank();
      });
    } catch (err) {
      // console.log(err);
    }
  }

  // async function apiDeleteBank(bankId) {
  //     await axios({
  //         method: 'GET',
  //         url: `${apiUrl}/api`
  //     })
  // }

  function onSetOpen() {
    setIsOpen(!isOpen);
  }

  return (
    <div className={`main-page ${st.content} animate-[fade_0.3s_ease-in-out]`}>
      <p className={st.title}>จัดการบัญชีธนาคารค่า Package</p>
      <div className="w-full">
        <button
          onClick={() => (setIsOpen(!isOpen), setChoose("add"))}
          type="button"
          className="ml-auto flex gap-2 align-center
                    item-center justify-center m-3 text-white bg-pink-700 hover:bg-pink-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
        >
          <i className="fa-solid fa-plus flex align-center justify-center"></i>
        </button>
      </div>

      {/* จัดการบัญชีธนาคารค่าสินค้า Package */}
      <BankTable
        bankList={bankPackage}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        setChoose={setChoose}
        setBankData={setBankData}
        ApiChangeStatus={ApiChangeStatus}
        ApiGetBank={ApiGetBank}
      />

      <p className={st.title}>จัดการบัญชีธนาคารค่าสินค้าชุดชั้นใน</p>
      {/* จัดการบัญชีธนาคารค่าสินค้าชุดชั้นใน */}
      <BankTable
        bankList={bankUnderwear}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        setChoose={setChoose}
        setBankData={setBankData}
        ApiChangeStatus={ApiChangeStatus}
        ApiGetBank={ApiGetBank}
      />

      <p className={st.title}>จัดการบัญชีธนาคารค่าสินค้าแฟชั่น</p>
      {/* จัดการบัญชีธนาคารค่าสินค้าชุดชั้นใน */}
      <BankTable
        bankList={bankFashion}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        setChoose={setChoose}
        setBankData={setBankData}
        ApiChangeStatus={ApiChangeStatus}
        ApiGetBank={ApiGetBank}
      />
   
      {isOpen && choose === "edit" && (
        <Modal_edit
          bankData={bankData}
          onSetOpen={onSetOpen}
          setBankData={ApiGetBank}
        />
      )}
      {isOpen && choose === "add" && (
        <Modal_add
          bankData={bankData}
          onSetOpen={onSetOpen}
          setBankData={ApiGetBank}
        />
      )}
    </div>
  );
}
