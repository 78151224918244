import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";

import CustomizedSteppers from "../../sub_component/stepper";
import st from "../../../styles/order/orderDetail.module.scss";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 800,
  timerProgressBar: true,
});

export default function OrderDetail() {
  const query = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const apiUrl = useSelector((state) => state.app.apiPath);
  const access_token = useSelector((state) => state.app.access_token);
  const [orderNumber, setOrderNumber] = useState(query.get("orderNumber"));
  const [orderDetail, setOrderDetail] = useState({});
  const [productList, setProductList] = useState([]);
  const [productRec, setProductRec] = useState([]);
  const [productPre, setProductPre] = useState([]);
  const [product, setProduct] = useState([]);
  const [gpValue, setGpValue] = useState();

  useEffect(() => {
    apiGetOrder();
  }, []);

  async function apiGetOrder() {
    await axios({
      method: "GET",
      url: `${apiUrl}/api/admin/orders/get`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }).then((res) => {
      if (res.data.status) {
        for (let order of res.data.order) {
          if (orderNumber === order.orderNumber) {
            const filterRec = order.product.filter(
              (p) =>
                p.recommend === "yes" &&
                (p.preOrder === "no" || p.preOrder === "yes")
            );
            const filterPre = order.product.filter(
              (p) => p.preOrder === "yes" && p.recommend === "no"
            );
            const filterProduct = order.product.filter(
              (p) => p.preOrder === "no" && p.recommend === "no"
            );
            setOrderDetail(order);
            setProductRec(filterRec);
            setProductPre(filterPre);
            setProduct(filterProduct);
            setProductList(order.product);

            if (!order.isRead) {
              apiSetReadOrder();
            }
          }
        }
      }
    });
  }

  function stateStep(state) {
    switch (state) {
      case "pending":
        return 1;
      case "shipping":
        return 2;
      case "success":
        return 3;
      case "accepted":
        return 4;
      default:
        return 0;
    }
  }

  function handleChangeStatus(statusToChange, product_id, currentState) {
    let prev = stateStep(currentState);
    let next = stateStep(statusToChange);
    if (next <= prev || prev === 0) {
      return false;
    } else if (next !== prev + 1) {
      return false;
    }
    apiUpdateStatusProduct(statusToChange, product_id);
  }

  async function apiUpdateStatusProduct(statusToChange, product_id) {
    await axios({
      method: "POST",
      url: `${apiUrl}/api/admin/orders/updateProductStatus`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: {
        orderNumber: orderNumber,
        productId: product_id,
        status: statusToChange,
      },
    }).then((res) => {
      Toast.fire({
        icon: "success",
        title: "สำเร็จ",
      }).then(() => {
        apiGetOrder();
      });
    });
  }

  function handleSetGP(data) {
    Swal.fire({
      title: "ยืนยันการเปลี่ยนค่า GP",
      text: "จาก " + data.grossProfit + "%" + " เป็น " + gpValue + "%",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      confirmButtonColor: "#C93A87",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        apiSetGPValue(data, gpValue);
      }
    });
  }

  async function apiSetGPValue(data, gpValue) {
    await axios({
      method: "POST",
      url: `${apiUrl}/api/admin/orders/updateGpProduct`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: {
        orderNumber: orderNumber,
        productId: data.product_id,
        gp: gpValue,
      },
    }).then((res) => {
      Toast.fire({
        icon: "success",
        title: "สำเร็จ",
      }).then(() => {
        setGpValue("");
        apiGetOrder("");
      });
    });
  }

  function handleCancelProduct(data) {
    Swal.fire({
      title: "ยืนยันการยกเลิกสินค้า",
      text: "ชื่อสินค้า : " + data.product_name,
      html: `<textarea placeholder="หมายเหตุ" class="border-2" type="text" style="padding: 0.7rem ;border-radius: 10px ; width: 90%" id="note_cancel"/>`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      confirmButtonColor: "#ff0303",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      preConfirm: () => {
        return document.getElementById("note_cancel").value.trim();
      },
    }).then((result) => {
      if (result.isConfirmed) {
        apiCancelProduct(data.product_id, result.value);
      } else {
        return false;
      }
    });
  }

  async function apiCancelProduct(product_id, note) {
    await axios({
      method: "POST",
      url: `${apiUrl}/api/admin/orders/cancelProductInOrder`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: {
        orderNumber: orderNumber,
        productId: parseInt(product_id),
        note: note ? note : "",
      },
    }).then((res) => {
      Toast.fire({
        icon: "success",
        title: "สำเร็จ",
      }).then(() => {
        setGpValue("");
        apiGetOrder("");
      });
    });
  }

  async function apiSetReadOrder() {
    await axios({
      method: "GET",
      url: `${apiUrl}/api/admin/orders/read/${orderNumber}`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  function handleReportOrder() {
    Swal.fire({
      title: "แจ้งบัญหา",
      html: `<textarea placeholder="หมายเหตุ" class="border-2" type="text" style="padding: 0.7rem ;border-radius: 10px ; width: 90%" id="message"/>`,
      showCancelButton: true,
      confirmButtonText: "แจ้งปัญหา",
      cancelButtonText: "ยกเลิก",
      confirmButtonColor: "#ff0303",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      preConfirm: () => {
        if (document.getElementById("message").value.trim() === "") {
          return false;
        }
        return document.getElementById("message").value.trim();
      },
    }).then((result) => {
      if (result.isConfirmed) {
        apiReportOrder(result.value);
      } else {
        return false;
      }
    });
  }

  async function apiReportOrder(message) {
    await axios({
      method: "POST",
      url: `${apiUrl}/api/admin/orders/updateStatus`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: {
        message: message,
        orderNumber: orderDetail.orderNumber,
        status: "failed",
      },
    }).then((res) => {
      if (res.data.status) {
        Toast.fire({
          icon: "success",
          title: "สำเร็จ",
        });
        apiGetOrder();
      }
    });
  }

  const handleDownloadImage = async () => {
    if (!orderDetail.spacial_image) return false;

    const fileName = orderDetail.spacial_image?.split("/").pop();
    await axios({
      method: "GET",
      url: `${apiUrl}/api/admin/order/downloadimage/${orderDetail.orderNumber}`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      responseType: "blob",
    })
      .then((response) => {
        const imageUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = imageUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      className={`main-page ${st.content} w-full animate-[fade_0.3s_ease-in-out]`}
    >
      <div className={st.wrapBtnBack}>
        <button
          onClick={() => navigate(-1)}
          className="flex gap-2 align-center "
        >
          <i className="flex my-auto text-pink-500 hover:text-[21px] duration-200 cursor-pointer text-xl fa-solid fa-circle-arrow-left"></i>
          <p className="text-pink-500 ">Back to order</p>
        </button>
      </div>
      <div className={`${st.contentTable} w-full custom-table-detail`}>
        <div className="">
          <table>
            <thead>
              <tr>
                <th scope="col"> order NO.</th>
                {/* <th scope="col"> user ผู้ขาย</th> */}
                <th scope="col"> ชนิด </th>
                <th scope="col"> ราคา </th>
                <th scope="col"> ชื่อร้าน </th>
                <th scope="col"> user ผู้ขาย </th>
                <th scope="col"> %GP</th>
                <th scope="col" className="min-w-[7rem]">
                  {" "}
                  ราคาหลังหัก GP{" "}
                </th>
                {orderDetail?.isHasImage && (
                  <React.Fragment>
                    <th scope="col"> หมายเหตุสินค้า </th>
                    <th scope="col"> ดาวน์โหลดรูปตอนใส่ </th>
                  </React.Fragment>
                )}
                <th scope="col"> หมายเหตุ </th>
                <th scope="col">
                  {orderDetail !== undefined &&
                  orderDetail.status !== "failed" ? (
                    <div className={st.reportOrder}>
                      <button onClick={() => handleReportOrder()} type="button">
                        <i className="my-auto fa-solid fa-circle-minus"></i>
                        แจ้งปัญหาออเดอร์
                      </button>
                    </div>
                  ) : (
                    <p>สถานะออเดอร์</p>
                  )}
                </th>
              </tr>
            </thead>
            <tbody className="w-full">
              {productList?.map((data, index) => (
                <tr key={index}>
                  <td>{data.orderNumber}</td>
                  {/* <td>{data.store_user}</td> */}
                  <td>
                    {/* ********************** ถ้าเป็นสินค้าแนะนำและสินค้าพรีออเดอร์ จะเอาสินค้าแนะนำขึ้นมาก่อน แล้วถ้าเป็นสินค้าพรีออเดอร์ด้วยจะตามด้วย /พรีออเดอร์ */}
                    {data.recommend === "yes" &&
                      (data.preOrder === "no" || data.preOrder === "yes") &&
                      `สินค้าแนะนำ ${
                        data.preOrder === "yes" && data.recommend === "yes"
                          ? "/พรีออเดอร์"
                          : ""
                      }`}
                    {data.preOrder === "yes" &&
                      data.recommend === "no" &&
                      "พรีออเดอร์"}
                    {data.preOrder === "no" &&
                      data.recommend === "no" &&
                      "สินค้าพร้อมส่ง"}
                  </td>
                  <td>{data.price}</td>
                  <td>{data.storename}</td>
                  <td>{data.store_user}</td>
                  <td className="flex flex-row gap-4 justify-center items-center">
                    <input
                      onChange={(e) => setGpValue(e.target.value.trim(""))}
                      className="w-16 text-center"
                      type="text"
                      placeholder={data.grossProfit}
                    />
                    <i
                      onClick={() => handleSetGP(data)}
                      className="cursor-pointer hover:scale-105 font-bold text-2xl fa-solid fa-square-arrow-up-right text-yellow-700"
                    ></i>
                  </td>
                  <td>{data.netPrices}</td>
                  {orderDetail?.isHasImage && (
                    <React.Fragment>
                      <td className="text-red-300">
                        {data.hasImage === "1" ? "แถมรูปตอนใส่" : ""}
                      </td>
                      <td className="hover:text-blue-300">
                        {orderDetail.spacial_image && (
                          <button
                            className={st.btnUpload}
                            onClick={handleDownloadImage}
                          >
                            <i className="fa-solid fa-download"></i> ดาวน์โหลด
                          </button>
                        )}
                      </td>
                    </React.Fragment>
                  )}
                  <td>{data.note}</td>
                  <td>
                    {data.product_status === "canceled" ? (
                      <p className="text-red-500 font-bold">ยกเลิกสินค้าแล้ว</p>
                    ) : data.product_status === "pending" ? (
                      <div className={st.reportProduct}>
                        <button
                          onClick={() => handleCancelProduct(data)}
                          type="button"
                        >
                          <i className="my-auto fa-solid fa-circle-minus"></i>
                          ยกเลิกสินค้า
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {orderDetail !== undefined && orderDetail.status === "failed" && (
          <div className="flex flex-row justify-center items-center">
            <p className="bg-red-400 text-white font-bold text-left text-lg rounded pl-10 sm:pl-4 w-[98%] sm:w-[95%] py-2 mt-2">
              ออเดอร์ผิดพลาดเนื่องจาก : {orderDetail.message}
            </p>
          </div>
        )}
        <div className={st.contentBottom}>
          {product.length > 0 && (
            <div className={st.contentProduct}>
              <p className={st.title}>สินค้าพร้อมส่ง</p>
              {product?.map((data, index) => (
                <div key={index}>
                  <div className={st.product}>
                    <img
                      src={`${apiUrl}${data.product_image}`}
                      alt="productImage"
                      onError={(e) => {
                        e.target.setAttribute("src", "/assets/empty.png");
                        return false;
                      }}
                    />
                    <div className={st.productDetail}>
                      <p className={`font-bold ${st.pd_name}`}>
                        {data.product_name}
                      </p>
                      <p className={st.pd_content}>{data.product_content}</p>
                      <p
                        className={` ${st.pd_status}
                                                    ${
                                                      data.product_status ===
                                                        "pending" &&
                                                      "bg-yellow-600  hover:shadow-lg absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                    }
                                                    ${
                                                      data.product_status ===
                                                        "accepted" &&
                                                      "bg-green-600   hover:shadow-lg  absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                    }
                                                    ${
                                                      data.product_status ===
                                                        "success" &&
                                                      "bg-green-600   hover:shadow-lg  absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                    }
                                                    ${
                                                      data.product_status ===
                                                        "shipping" &&
                                                      "bg-orange-600   hover:shadow-lg absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                    }
                                                    ${
                                                      data.product_status ===
                                                        "canceled" &&
                                                      "bg-red-600   hover:shadow-lg absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                    }`}
                      >
                        {data.product_status}
                      </p>
                      <div className="absolute bg-white w-20 h-8 top-3 right-2 flex flex-row items-center justify-center gap-2 rounded-bl-lg">
                        <p className={`text-xl font-bold`}>{data.price}</p>
                        <i className="text-red-500 fa-solid fa-tags"></i>
                      </div>
                    </div>
                    <div className="w-full rounded-b-lg bg-gray-300">
                      <CustomizedSteppers
                        product={data}
                        handleChangeStatus={handleChangeStatus}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {productPre.length > 0 && (
            <div className={st.contentProduct}>
              <p className={st.title}>สินค้าพรีออเดอร์</p>
              <>
                {productPre?.map((data, index) => (
                  <div key={index}>
                    <div className={st.product}>
                      <img
                        src={`${apiUrl}${data.product_image}`}
                        alt="productImage"
                        onError={(e) => {
                          e.target.setAttribute("src", "/assets/empty.png");
                          return false;
                        }}
                      />
                      <div className={st.productDetail}>
                        <p className={`font-bold ${st.pd_name}`}>
                          {data.product_name}
                        </p>
                        <p className={st.pd_content}>{data.product_content} </p>
                        <p
                          className={` ${st.pd_status}
                                                    ${
                                                      data.product_status ===
                                                        "pending" &&
                                                      "bg-yellow-600  hover:shadow-lg absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                    }
                                                    ${
                                                      data.product_status ===
                                                        "accepted" &&
                                                      "bg-green-600   hover:shadow-lg  absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                    }
                                                    ${
                                                      data.product_status ===
                                                        "success" &&
                                                      "bg-green-600   hover:shadow-lg  absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                    }
                                                    ${
                                                      data.product_status ===
                                                        "shipping" &&
                                                      "bg-orange-600   hover:shadow-lg absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                    }
                                                    ${
                                                      data.product_status ===
                                                        "canceled" &&
                                                      "bg-red-600   hover:shadow-lg absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                    }`}
                        >
                          {data.product_status}
                        </p>
                        <div className="absolute bg-white w-20 h-8 top-3 right-2 flex flex-row items-center justify-center gap-2 rounded-bl-lg">
                          <p className={`text-xl font-bold`}>{data.price}</p>
                          <i className="text-red-500  fa-solid fa-tags"></i>
                        </div>
                      </div>
                      <div className="w-full rounded-b-lg bg-gray-300">
                        <CustomizedSteppers
                          product={data}
                          handleChangeStatus={handleChangeStatus}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            </div>
          )}
          {productRec.length > 0 && (
            <div className={st.contentProduct}>
              <p className={st.title}>สินค้าแนะนำ</p>
              <>
                {productRec?.map((data, index) => (
                  <div key={index}>
                    <div className={st.product}>
                      <img
                        src={`${apiUrl}${data.product_image}`}
                        alt="productImage"
                        onError={(e) => {
                          e.target.setAttribute("src", "/assets/empty.png");
                          return false;
                        }}
                      />
                      <div className={st.productDetail}>
                        <p className={`font-bold ${st.pd_name}`}>
                          {data.product_name}
                        </p>
                        <p className={st.pd_content}>{data.product_content} </p>
                        <p
                          className={` ${st.pd_status}
                                                    ${
                                                      data.product_status ===
                                                        "pending" &&
                                                      "bg-yellow-600  hover:shadow-lg absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                    }
                                                    ${
                                                      data.product_status ===
                                                        "accepted" &&
                                                      "bg-green-600   hover:shadow-lg  absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                    }
                                                    ${
                                                      data.product_status ===
                                                        "success" &&
                                                      "bg-green-600   hover:shadow-lg  absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                    }
                                                    ${
                                                      data.product_status ===
                                                        "shipping" &&
                                                      "bg-orange-600   hover:shadow-lg absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                    }
                                                    ${
                                                      data.product_status ===
                                                        "canceled" &&
                                                      "bg-red-600   hover:shadow-lg absolute rotate-[-45deg] text-lg left-[-24px] top-3 text-white  px-5 transition duration-150  ease-in-out"
                                                    }`}
                        >
                          {data.product_status}
                        </p>
                        <div className="absolute bg-white w-20 h-8 top-3 right-2 flex flex-row items-center justify-center gap-2 rounded-bl-lg">
                          <p className={`text-xl font-bold`}>{data.price}</p>
                          <i className="text-red-500 fa-solid fa-tags"></i>
                        </div>
                      </div>
                      <div className="w-full rounded-b-lg bg-gray-300">
                        <CustomizedSteppers
                          product={data}
                          handleChangeStatus={handleChangeStatus}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
