import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import CustomizedSteppers from "../../sub_component/stepper";
import st from "../../../styles/allUse/table.module.scss";
import Swal from "sweetalert2";
import useTable from "../../../hooks/useTable";
import TableFooter from "../../sub_component/TableFooter";
import DropdownPayStatus from "./dropdownPayStatus";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 800,
  timerProgressBar: true,
});

const formStatusPayment = [
  { id: 0, paymentStatus: "pending" },
  { id: 1, paymentStatus: "confirm" },
  { id: 2, paymentStatus: "deposit" },
  // { id: 2, paymentStatus: 'failed' },
];

const formStatusOrder = [
  { id: 0, status: "pending" },
  { id: 1, status: "success" },
  // { id: 2, status: 'failed' },
];

const Table = ({ rowsPerPage, searchText }) => {
  const [page, setPage] = useState(1);
  const [orderData, setOrderDate] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const { slice, range } = useTable(totalData, page, rowsPerPage);
  const apiUrl = useSelector((state) => state.app.apiPath);
  const access_token = useSelector((state) => state.app.access_token);
  const [selectStatus, setSelectStatus] = useState();
  const [selectStatusOrder, setSelectStatusOrder] = useState();
  const [statusPaymentList, setStatusPaymentList] = useState(formStatusPayment);
  const [statusOrderList, setStatusOrderList] = useState(formStatusOrder);
  const [slipError, setSlipError] = useState(false);

  useEffect(() => {
    apiGetOrder();
  }, []);

  useEffect(() => {
    OnFilteredData();
  }, [orderData, searchText, page]);

  const OnFilteredData = () => {
    setFilteredData([])
    /* แก้ไข function filter  */
    let begin = page * rowsPerPage - rowsPerPage;
    const filtered = orderData.filter((text) => {
      if (searchText !== "") {
        let searchValue = searchText.toLowerCase();
        if (
          !text.member_user.toLowerCase().includes(searchValue) &&
          !text.orderNumber.toLowerCase().includes(searchValue)
        ) {
          return false;
        }
      }
      return text;
    });
    /* นำไปแสดงผล pagination (totalData) */
    setTotalData(filtered);
    /* นำไปแสดงผล */
    setFilteredData(filtered.slice(begin, begin + rowsPerPage));
  };

  async function apiGetOrder() {
    await axios({
      method: "GET",
      url: `${apiUrl}/api/admin/orders/get`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }).then((res) => {
      if (res.data.status) {
        setOrderDate(res.data.order);
        setSelectStatus({ paymentStatus: res.data.paymentStatus });
        setSelectStatusOrder({ status: res.data.status });
      }
    });
  }

  function handleChangepaymentStatus(paymentStatus, orderNumber) {
    Swal.fire({
      title: "ยืนยันการเปลี่ยนสถานะ",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      confirmButtonColor: "#C93A87",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        apiUtpdatePaymentStatus(paymentStatus, orderNumber);
      }
    });
  }

  async function apiUtpdatePaymentStatus(paymentStatus, orderNumber) {
    await axios({
      method: "POST",
      url: `${apiUrl}/api/admin/orders/updatePaymentStatus`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: {
        orderNumber: orderNumber,
        status: paymentStatus,
      },
    }).then((res) => {
      Toast.fire({
        icon: "success",
        title: "แก้ไขแล้ว",
      });
      apiGetOrder();
    });
  }

  function handleChangeOrderStatus(orderStatus, orderNumber) {
    Swal.fire({
      title: "ยืนยันการเปลี่ยนสถานะ",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      confirmButtonColor: "#C93A87",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        apiUpdateStatus(orderStatus, orderNumber);
      }
    });
  }

  async function apiUpdateStatus(orderStatus, orderNumber) {
    await axios({
      method: "POST",
      url: `${apiUrl}/api/admin/orders/updateStatus`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: {
        orderNumber: orderNumber,
        status: orderStatus,
        message: "",
      },
    }).then((res) => {
      Toast.fire({
        icon: "success",
        title: "แก้ไขแล้ว",
      });
      apiGetOrder();
    });
  }

  function handleShowSlip(slip) {
    if (!slip) return false;
    Swal.fire({
      imageUrl: apiUrl + slip,
      imageWidth: 400,
      // imageHeight: 500,
      imageAlt: "Custom image",
      showConfirmButton: false,
      backdrop: true,
      background: "rgba(0,0,0,0)",
    });
  }

  function FormatDate({ dateTime }) {
    dateTime = moment(dateTime).format("DD MMM YYYY");
    return <span>{dateTime}</span>;
  }

  return (
    <>
      <table className={st.contentTable}>
        <thead>
          <tr>
            <th scope="col"> ลำดับที่</th>
            <th scope="col"> เลขออเดอร์</th>
            <th scope="col"> user ผู้ซื้อ</th>
            <th scope="col"> การชำระเงิน </th>
            <th scope="col"> ยอดโอน </th>
            <th scope="col"> สลิป </th>
            {/* <th scope="col"> ชื่อร้าน </th> */}
            <th scope="col"> สถานะการชำระเงิน </th>
            <th scope="col"> สถานะออเดอร์</th>
            <th scope="col"> เพิ่มเติม</th>
          </tr>
        </thead>
        <tbody>
          {filteredData?.length > 0 && filteredData.map((data, index) => (
            <tr key={index}>
              <td>
                {!data.isRead && (
                  <span
                    className="flex my-auto absolute h-4 w-4"
                    style={{ left: "10px" }}
                  >
                    <span className="flex animate-ping h-full w-full rounded-full bg-green-400 opacity-75"></span>
                    <span className="flex absolute rounded-full h-full w-full bg-green-500"></span>
                  </span>
                )}
                <p className={`${!data.isRead && "font-bold"}`}>
                  {index + 1 + page * rowsPerPage - rowsPerPage}
                </p>
              </td>
              <td className={`${!data.isRead && "font-bold"}`}>
                {data.orderNumber}
              </td>
              <td className={`${!data.isRead && "font-bold"}`}>
                {data.member_user}
              </td>
              <td className={`${!data.isRead && "font-bold"}`}>
                {data.payment_type}
              </td>
              <td className={`${!data.isRead && "font-bold"}`}>
                {data.payment_type === "COD"?50:data.total_order_price}
              </td>
              <td>
                <img
                  className={`${
                    !data.isRead && "font-bold"
                  } cursor-pointer mx-auto w-6 h-6 object-cover`}
                  onClick={() => handleShowSlip(data.slip)}
                  src={`${apiUrl}${data.slip}`}
                  alt="slip"
                  onError={(e) => {
                    e.target.setAttribute("src", "/assets/empty.png");
                    // e.target.style.pointerEvents = "none";
                  }}
                />
              </td>
              {/* <td className={`${!data.isRead && 'font-bold'} min-w-[5rem]`}>
                  <span>{data.storename}</span>
                </td> */}
              <td>
                <DropdownPayStatus
                  data={data}
                  selectStatus={selectStatus}
                  setSelectStatus={setSelectStatus}
                  statusPaymentList={statusPaymentList}
                  handleChangepaymentStatus={handleChangepaymentStatus}
                />
              </td>
              <td>
                <Listbox
                  value={selectStatusOrder}
                  onChange={setSelectStatusOrder}
                >
                  {selectStatusOrder && (
                    <div className="relative">
                      {!data.hasCancel || data.status === "failed" ? (
                        <Listbox.Button
                          className={`z-0 cursor-default max-w-[10rem] appearance-none border-2 border-gray-200 rounded-lg w-full py-1 px-4 text-gray-700 leading-tight 
                                                   ${
                                                     data.status ===
                                                       "pending" &&
                                                     "bg-yellow-500"
                                                   }
                                                   ${
                                                     data.status ===
                                                       "success" &&
                                                     "bg-green-500"
                                                   }
                                                   ${
                                                     data.status === "failed" &&
                                                     "bg-red-500 text-rose-100"
                                                   }
                                                  `}
                        >
                          <span className="block truncate text-center text-white">
                            {data.status === "pending" && "กำลังดำเนินการ"}
                            {data.status === "success" && "ส่งแล้ว"}
                            {data.status === "failed" && "ผิดพลาด"}
                          </span>
                        </Listbox.Button>
                      ) : (
                        <Listbox.Button
                          className={`z-0 cursor-default max-w-[10rem] appearance-none border-2 border-gray-200 rounded-lg w-full py-1 px-4 text-gray-700 leading-tight bg-blue-400`}
                        >
                          <span className="block truncate text-center text-white">
                            {data.status === "pending" && "กำลังดำเนินการ"}
                            {data.status === "success" && "ส่งแล้ว"}
                          </span>
                        </Listbox.Button>
                      )}
                    </div>
                  )}
                </Listbox>
              </td>
              <td>
                <div className={st.wrapBtn}>
                  <Link to={`/order/detail?orderNumber=${data.orderNumber}`}>
                    <p
                      type="button"
                      className="m-auto w-[110px] h-[28px] gap-2 flex text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-1.5 text-center dark:focus:ring-yellow-900"
                    >
                      <i className="fa-solid fa-eye my-auto"></i>
                      ดูสินค้า
                    </p>
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </>
  );
};

export default Table;
