import React from "react";
import st from "../../styles/allUse/content.module.scss";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 1200,
  timerProgressBar: true,
});

function BankTable({
  bankList,
  setBankData,
  setIsOpen,
  isOpen,
  setChoose,
  ApiChangeStatus,
  ApiGetBank,
}) {
  const access_token = useSelector((state) => state.app.access_token);
  const apiUrl = useSelector((state) => state.app.apiPath);

  const deleteStoreHandler = async (data) => {
    Swal.fire({
      title: "ยืนยันการลบ?",
      text: "คุณต้องการลบบัญชีธนาคารหรือไม่!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่, ลบเลย!",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        confirmedDelete(data);
      }
    });
  };

  const confirmedDelete = async (bank) => {
    const result = await axios
      .delete(`${apiUrl}/api/admin/bank/${bank.id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then(
        (res) => {
          console.log(res);
          return { status: true, description: res.data.description };
        },
        (error) => {
          return {
            status: false,
            description: error.response.data.description,
          };
        }
      );

    if (result.status) {
      ApiGetBank();
      // props.onRefresh();
      // Swal.fire({
      //   title: "ลบบัญชี",
      //   text: "การลบบัญชีสำเร็จ!",
      //   icon: "success",
      //   position: "center",
      //   timer: 1000,
      //   showConfirmButton: false,
      // });
      Toast.fire({
        icon: "success",
        title: "การลบบัญชีสำเร็จ!",
      });
    } else {
      // Swal.fire({
      //   icon: "error",
      //   title: "ผิดพลาด",
      //   text: "การลบบัญชีล้มเหลว!",
      //   position: "center",
      //   showConfirmButton: false,
      //   showCancelButton: true,
      //   cancelButtonText: "ปิด",
      // });
      Toast.fire({
        icon: "success",
        title: "การลบบัญชีล้มเหลว!",
      });
    }
  };

  return (
    <div className={st.contentTable}>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="py-3 px-6 w-[297px]">
              ชื่อบัญชี
            </th>
            <th scope="col" className="py-3 px-6">
              ธนาคาร
            </th>
            <th scope="col" className="py-3 px-6">
              เลขที่บัญชี
            </th>
            <th scope="col" className="py-3 px-6 text-center">
              อักษรย่อ
            </th>
            <th scope="col" className="py-3 px-6">
              สถานะ
            </th>
            <th scope="col" className="py-3 px-6 text-center">
              เพิ่มเติม
            </th>
          </tr>
        </thead>
        <tbody>
          {bankList?.map((data, index) => (
            <tr
              key={index}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
            >
              <td className="py-2 px-6">{data.name}</td>
              <td className="py-2 px-6">{data.bank_name}</td>
              <td className="py-2 px-6">{data.bank_number}</td>
              <td className="py-2 px-6 text-center">{data.bank_shortname}</td>
              <td className="py-2 px-6">
                <label className="inline-flex relative items-center mr-5 cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    defaultChecked={data.status === "active" ? true : false}
                    onClick={() => ApiChangeStatus(data.id, data.status)}
                  />
                  <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600" />
                </label>
              </td>
              <td className="py-2 px-6">
                <div className="flex flex-row justify-center gap-2">
                  <button
                    type="button"
                    className="flex flex-row gap-2 text-white bg-pink-400 hover:bg-pink-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-xl text-sm px-5 py-1.5 text-center mr-2 mb-2 dark:focus:ring-yellow-900"
                    onClick={() => (
                      setIsOpen(!isOpen), setChoose("edit"), setBankData(data)
                    )}
                  >
                    <i className="my-auto fa-solid fa-pen-to-square"></i>

                    <p>แก้ไข</p>
                  </button>
                  <a
                    className="cursor-pointer"
                    onClick={() => deleteStoreHandler(data)}
                  >
                    <p
                      type="button"
                      className=" w-[90px] m-auto gap-2 flex justify-center items-center text-white bg-red-400 hover:bg-red-500 rounded-xl focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium text-sm px-5 py-1.5 text-center dark:focus:ring-yellow-900"
                    >
                      <i className="fa-solid fa-trash my-auto"></i>
                      ลบ
                    </p>
                  </a>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default BankTable;
