import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from "./Table";
import st from "../../../styles/allUse/content.module.scss";

function Contents() {
  const apiUrl = useSelector((state) => state.app.apiPath);
  const access_token = useSelector((state) => state.app.access_token);
  const [contentList, setContentList] = useState();

  useEffect(() => {
    apiGetContent();
  }, []);
  async function apiGetContent() {
    await axios({
      method: "GET",
      url: `${apiUrl}/api/admin/content/get`,
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    }).then((res) => {
      if (res.data.status) {
        setContentList(res.data.content);
      }
    });
  }

  return (
    <div className={`main-page ${st.content} animate-[fade_0.3s_ease-in-out]`} style={{paddingTop: '5rem', paddingBottom: '5rem'}}>
      <div className={st.contentTable}>
        {contentList !== undefined && contentList.length > 0 && (
          <Table
            data={contentList}
            rowsPerPage={12}
            apiGetContent={apiGetContent}
          />
        )}
      </div>
    </div>
  );
}

export default Contents;
