import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useTable from "../../../hooks/useTable";
import moment from "moment";
import TableFooter from "../../sub_component/TableFooter";
import st from "../../../styles/allUse/table.module.scss";
import { useSelector } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 800,
  timerProgressBar: true,
})

const Table = (props) => {
  const { data, rowsPerPage, searchText, sex, apiGetStore } = props
  const apiUrl = useSelector((state) => (state.app.apiPath))
  const [page, setPage] = useState(1);
  const [storeList, setStoreList] = useState([]);
  const { slice, range } = useTable(storeList, page, rowsPerPage);
  const access_token = useSelector((state) => (state.app.access_token))
  const [priorityNumber, setPriorityNumber] = useState(undefined)

  useEffect(() => {
    if (data) {
      setStoreList(data)
    }
  }, [data])
  
  useEffect(()=>{
    setPage(1)
  },[searchText])
  

  function FormatDate({ dateTime }) {
    dateTime = moment(dateTime).format("DD MMM YYYY");
    return <span>{dateTime}</span>;
  }

  // async function apiSetPriority(productCode, priority) {
  //   await axios({
  //     method: 'POST',
  //     url: `${apiUrl}/api/admin/storeProduct/setPriorityRecommend`,
  //     headers: {
  //       Authorization: `Bearer ${access_token}`
  //     },
  //     data: {
  //       productCode: productCode.toString(),
  //       priority: priority,
  //       sex: sex
  //     }
  //   }).then(res => {
  //     setPriorityNumber(undefined)
  //     if (res.data.status) {
  //       Toast.fire({
  //         icon: 'success',
  //         title: 'ลบแล้ว'
  //       }).then(async () => {
  //         const data = await apiGetProduct()
  //       })
  //     }
  //   })
  // }

  const deleteStoreHandler = async (storeCode) => {
    Swal.fire({
      title: 'ยืนยันการลบ?',
      text: "คุณต้องการลบบัญชีร้านค้าหรือไม่!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ใช่, ลบเลย!',
      cancelButtonText: 'ยกเลิก',
    }).then((result) => {
      if (result.isConfirmed) {
        confirmedDelete(storeCode)
      }
    }) 
  }
  const confirmedDelete = async (storeCode) => {
    const result = await axios.delete( `${apiUrl}/api/admin/store/${storeCode}`,{
      headers: { Authorization: `Bearer ${access_token}` }
    }).then(res => {
      console.log(res)
      return { status: true, description: res.data.description }
    }, error => {
      return { status: false, description: error.response.data.description}
    })

    if(result.status) {
      props.onRefresh()
      Swal.fire({
        title: "ลบบัญชี",
        text: "การลบบัญชีสำเร็จ!",
        icon: "success",
        position: "center",
        timer: 1000,
        showConfirmButton: false
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: "ผิดพลาด",
        text: "การลบบัญชีล้มเหลว!",
        position: "center",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "ปิด"
      });
    }
  }

  return (
    <>
      <table className={st.contentTable}>
        <thead>
          <tr>
            <th scope="col" className="py-3 px-6 text-center">
              โปรไฟล์
            </th>
            <th scope="col" className="py-3 px-6 text-center">
              ชื่อร้าน
            </th>
            <th scope="col" className="py-3 px-6 text-center">
              สถานะ
            </th>
            <th scope="col" className="py-3 px-6 min-w-[8rem] text-center">
              การแจ้งเตือน
            </th>
            <th scope="col" className="py-3 px-6 text-center w-80">
              จัดการ
            </th>
          </tr>
        </thead>
        <tbody>
          {slice
            ?.filter((text) => {
              if (searchText === "") {
                return text;
              } else if (
                text.name
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
              ) {
                return text;
              }
            })
            .map((data, index) => (
              <tr
                key={index}
                className=" text-center bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  className="py-4 px-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  <img
                    className="w-8 h-8 rounded m-auto"
                    src={`${apiUrl}${data.profile_img}`} alt="profile_img"
                    onError={e => {
                      e.target.setAttribute('src', '/assets/empty.png')
                    }} />
                </th>
                <td className="py-2 px-6 text-center">{data.name}</td>
                <td className="py-2 px-6 text-center">
                  <p className={`py-1 px-5 rounded-xl 
                    ${data.status === "active" && "text-green-600"}
                    ${data.status === "inactive" && "text-red-500"}
                    ${data.status === "banned" && "text-red-500"}
                       text-md font-semibold min-w-[6rem]`} >
                    {data.status === "active" && 'เปิดร้าน'}
                    {data.status === "inactive" && 'ปิดร้าน'}
                    {data.status === "banned" && 'แบน'}
                  </p>
                </td>
                <td className="py-2 px-6 text-center">
                  <div className="flex flex-col gap-1">
                    {data.updateProduct &&
                      <div className="flex flex-row items-center justify-center gap-2">
                        <p className="px-1 font-bold rounded text-red-500 bg-dark-purple"> update </p>
                        <p className="text-red-500"> มีการอัพเดทสินค้าใหม่ </p>
                      </div>
                    }
                    {data.updateProfile &&
                      <div className="flex flex-row items-center justify-center gap-4 ">
                        <p className="px-1 font-bold rounded text-red-500 bg-dark-purple"> update </p>
                        <p className="text-red-500 "> มีการอัพเดทโปรไฟล์  </p>
                      </div>
                    }
                    {data.updatePost &&
                      <div className="flex flex-row items-center justify-center gap-4 ">
                        <p className="px-1 font-bold rounded text-red-500 bg-dark-purple"> update </p>
                        <p className="text-red-500 "> มีการอัพเดทโพสต์  </p>
                      </div>
                    }
                  </div>
                </td>
                <td className="py-2 px-6 text-center">
                  <div className="flex flex-row justify-center gap-4">
                    <Link to={`/store/detail/?storeCode=${data.store_code}&gender=${sex}`}>
                      <p
                        type="button"
                        className="w-[140px] m-auto gap-2 flex text-white bg-pink-400 hover:bg-pink-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-md text-sm px-5 py-1.5 text-center dark:focus:ring-yellow-900"
                      >
                        <i className="fa-solid fa-eye my-auto"></i>
                        ข้อมูลร้านค้า
                      </p>
                    </Link>
                    <a className="cursor-pointer" onClick={() => deleteStoreHandler(data.store_code)}>
                      <p type="button"
                          className=" w-[120px] m-auto gap-2 flex text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-md text-sm px-5 py-1.5 text-center dark:focus:ring-yellow-900" >
                          <i className="fa-solid fa-trash my-auto"></i>
                          ลบร้านค้า
                        </p>
                    </a>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </>
  );
};

export default Table;
