import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useTable from "../../../hooks/useTable";
import moment from "moment";
import TableFooter from "../../sub_component/TableFooter";
import st from "../../../styles/allUse/table.module.scss";
import { useSelector } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 800,
  timerProgressBar: true,
});

const Table = ({ data, rowsPerPage, searchText, setRefreshData }) => {
  const access_token = useSelector((state) => state.app.access_token);
  const apiUrl = useSelector((state) => state.app.apiPath);
  const [page, setPage] = useState(1);
  const [productList, setProductList] = useState([]);
  const { slice, range } = useTable(productList, page, rowsPerPage);

  useEffect(() => {
    setProductList(data);
  }, [data]);

  useEffect(() => {
    setPage(1);
  }, [searchText]);

  function onSetProductList(index, e) {
    const value = e.target.value;
    const isNaN = Number.isNaN(parseInt(value));
    if (isNaN && value != "") return false;

    setProductList((prev) =>
      prev.map((el, indexpp) => {
        if (index === indexpp) {
          el.priority = parseInt(value) || 0;
        }
        return el;
      })
    );
  }

  function FormatDate({ dateTime }) {
    dateTime = moment(dateTime).format("DD MMM YYYY HH:mm:ss");
    return <span>{dateTime}</span>;
  }

  function handleSubmitForm(data) {
    Swal.fire({
      title: "ยืนยันการอัปเดต " + data.name,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      confirmButtonColor: "#f472b6",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        apiUpdateCate(data);
      }
    });
  }

  function inputImageOnChange(e, cate_id, file_banner = false) {
    if (!e.target.files.length) return false;
    if (
      ["image/jpeg", "iamge/jpg", "image/png", "image/webp"].includes(
        e.target.files[0].type
      )
    ) {
      const urlImg = URL.createObjectURL(e.target.files[0]);
      const file_img = e.target.files;

      if (!file_banner) {
        setProductList((prev) =>
          prev.map((el, indexpp) => {
            if (el.cate_id === cate_id) {
              el.preview_img = urlImg;
              el.file_img = file_img;
            }
            return el;
          })
        );
      } else {
        setProductList((prev) =>
          prev.map((el, indexpp) => {
            if (el.cate_id === cate_id) {
              el.preview_img_banner = urlImg;
              el.file_img_banner = file_img;
            }
            return el;
          })
        );
      }
    } else {
      Swal.fire({
        title: "กรุณาอัปโหลดเฉพาะไฟล์รูปภาพ",
        icon: "warning",
        position: "center",
        timer: 1000,
        showConfirmButton: false,
      });
    }
  }

  async function apiUpdateCate(data) {
    const formData = new FormData();
    if (data.file_img) {
      formData.append("image", data.file_img[0]);
    }
    if (data.file_img_banner) {
      formData.append("banner_image", data.file_img_banner[0]);
    }
    formData.append("cate_id", data.cate_id);
    formData.append("name", data.name);
    formData.append("path_img", data.path_img);
    formData.append("priority", data.priority);
    formData.append("display", data.display);
    formData.append("description", data.description);
    formData.append("slug", data.slug?.trim().toLowerCase() || "");

    await axios({
      method: "POST",
      url: `${apiUrl}/api/admin/updateproductcate`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: formData,
    })
      .then((res) => {
        Toast.fire({
          icon: "success",
          title: "แก้ไขแล้ว",
        });
        setRefreshData((prev) => prev + 1);
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <table className={st.contentTable}>
        <thead>
          <tr>
            <th scope="col" style={{ textAlign: "center" }}>
              {" "}
              ลำดับที่
            </th>
            <th scope="col" style={{ textAlign: "center" }}>
              {" "}
              รูปภาพ
            </th>
            <th scope="col" style={{ textAlign: "center" }}>
              {" "}
              Banner หมวดหมู่
            </th>
            <th scope="col" style={{ textAlign: "center" }}>
              {" "}
              รายละเอียด{" "}
            </th>
            <th scope="col" style={{ textAlign: "center" }}>
              {" "}
              แสดงผล{" "}
            </th>
            <th scope="col"> วันที่สร้าง </th>
            <th scope="col"> อัปเดตล่าสุด </th>
            <th scope="col" style={{ textAlign: "center" }}>
              {" "}
              อัปเดต{" "}
            </th>
          </tr>
        </thead>
        <tbody>
          {slice
            ?.filter((text) => {
              if (searchText === "") {
                return text;
              } else if (
                text.name_member
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
              ) {
                return text;
              }
            })
            .map((data, index) => (
              <tr key={index}>
                <td className="w-[100px]">
                  <div className="flex flex-row justify-center items-center gap-4">
                    {/* <label >ลำดับที่</label> */}
                    <input
                      className="w-[3rem] p-1 bg-white text-center rounded text-black"
                      type="text"
                      value={data.priority}
                      onChange={(e) => onSetProductList(index, e)}
                    />
                  </div>
                </td>
                <td className="w-[150px]">
                  <div className={st.forImage}>
                    <img
                      className="w-full object-cover"
                      src={
                        data.preview_img
                          ? data.preview_img
                          : `${apiUrl}${data.path_img}`
                      }
                      alt="slip"
                      onError={(e) => {
                        e.target.setAttribute("src", "/assets/empty.png");
                      }}
                    />
                    <input
                      type="file"
                      accept=".jpg,.jpeg,.png,.webp"
                      onChange={(e) => inputImageOnChange(e, data.cate_id)}
                    />
                    <i className="fa-solid fa-camera"></i>
                    <div className={st.circle}></div>
                  </div>
                </td>
                <td className="w-[350px]">
                  <div className={st.forBannerImage}>
                    <img
                      className="w-full object-contain"
                      src={
                        data.preview_img_banner
                          ? data.preview_img_banner
                          : `${apiUrl}${data.path_img_banner}`
                      }
                      alt="slip"
                      onError={(e) => {
                        e.target.setAttribute("src", "/assets/empty.png");
                      }}
                    />
                    <input
                      type="file"
                      accept=".jpg,.jpeg,.png,.webp"
                      onChange={(e) =>
                        inputImageOnChange(e, data.cate_id, true)
                      }
                    />
                    <i className="fa-solid fa-camera"></i>
                    <div className={st.circle}></div>
                  </div>
                </td>
                <td className="min-w-[10rem] text-center">
                  <div className="flex flex-row items-center justify-center gap-2 mb-2">
                    <input
                      className="w-full p-1 bg-white text-center rounded text-black"
                      type="text"
                      value={data.name}
                      placeholder="ชื่อหมวดหมู่"
                      onChange={(e) => {
                        setProductList((prev) =>
                          prev.map((el, indexpp) => {
                            if (el.cate_id === data.cate_id) {
                              el.name = e.target.value;
                            }
                            return el;
                          })
                        );
                      }}
                    />
                  </div>
                  {data.cate_id === 8 && (
                    <div className="flex flex-row items-center justify-center gap-2 mb-2">
                      <input
                        className="w-full p-1 bg-white text-center rounded text-black"
                        type="text"
                        value={data.slug}
                        placeholder="slug"
                        onChange={(e) => {
                          setProductList((prev) =>
                            prev.map((el, indexpp) => {
                              if (el.cate_id === data.cate_id) {
                                el.slug = e.target.value;
                              }
                              return el;
                            })
                          );
                        }}
                      />
                    </div>
                  )}
                  <div className="flex flex-row items-center justify-center gap-2">
                    <textarea
                      className="w-full p-1 bg-white text-center rounded text-black"
                      type="text"
                      value={data.description}
                      placeholder={"คำอธิบายหมวดหมู่"}
                      onChange={(e) => {
                        setProductList((prev) =>
                          prev.map((el, indexpp) => {
                            if (el.cate_id === data.cate_id) {
                              el.description = e.target.value;
                            }

                            return el;
                          })
                        );
                      }}
                    />
                  </div>
                </td>
                <td className="min-w-[6rem]">
                  <label className="inline-flex items-center relative cursor-pointer ml-4 mr-4">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={data.display ? true : false}
                      onChange={() => {
                        setProductList((prev) =>
                          prev.map((el, indexpp) => {
                            if (el.cate_id === data.cate_id) {
                              el.display = !el.display;
                            }
                            return el;
                          })
                        );
                      }}
                    />
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600" />
                  </label>
                </td>
                <td className="py-2 px-1 text-center min-w-[5rem] lg:min-w-[7rem]">
                  <FormatDate dateTime={data.createdAt} />
                </td>
                <td className="py-2 px-1 text-center min-w-[5rem] lg:min-w-[7rem]">
                  <FormatDate dateTime={data.updatedAt} />
                </td>
                <td className="min-w-[8rem]">
                  <button
                    type="button"
                    className="w-[4rem] bg-green-600 text-white rounded h-10"
                    onClick={() => handleSubmitForm(data)}
                  >
                    อัปเดต
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </>
  );
};

export default Table;
