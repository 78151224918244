import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Table from './Table'
import st from '../../../styles/allUse/content.module.scss'
import Modal_add from './modal_add'

function Ads() {
    const apiUrl = useSelector((state) => (state.app.apiPath))
    const access_token = useSelector((state) => (state.app.access_token))
    const [contentData, setContentData] = useState()
    const [isOpen, setIsOpen] = useState(false)
    const position = [
        {position: 'login'},
        {position: 'home'},
        {position: 'store-men'},
        {position: 'store-women'},
        {position: 'home-fashion'},
        {position: 'all-category'},
    ];

    useEffect(() => {
        if (!contentData) {
            apiGetContent()
        }
    }, [])

    async function apiGetContent() {
        await axios({
            method: 'GET',
            url: `${apiUrl}/api/admin/content/get`,
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        }).then(res => {
            if (res.data.status) {
                setContentData(res.data)
            }
        })
    }

    function onSetOpen() {
        setIsOpen(!isOpen)
    }
    if (!contentData) return <div></div>

    return (
        <div className={`main-page ${st.content} animate-[fade_0.3s_ease-in-out]`}>
            <button onClick={() => onSetOpen()} className='flex flex-row ml-auto text-white bg-green-800/80 hover:bg-green-800 duration-200 px-2 py-1 m-2 rounded-md '>เพิ่มโฆษณา</button>
            <div className={st.contentTable}>
                {isOpen &&
                    <Modal_add position={position} apiGetContent={apiGetContent} onSetOpen={onSetOpen} st={st} />
                }
                {contentData &&
                    <Table adsList={contentData.ads} position={position} rowsPerPage={12} apiGetContent={apiGetContent} />
                }
            </div>
        </div>
    )
}

export default Ads;