import axios from "axios";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Loading from "../../loadingPage/loading";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 800,
  timerProgressBar: true
});

export default function UploadVideo({ videoPath, apiGetStore }) {
  const query = new URLSearchParams(useLocation().search);
  const [storeCode, setStoreCode] = useState(query.get("storeCode"));
  const apiUrl = useSelector((state) => state.app.apiPath);
  const access_token = useSelector((state) => state.app.access_token);
  const inputStoreVideo = useRef([]);
  const [videoFile, setVideoFile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  function inputVideoOnChange(e) {
    if (!e.target.files.length) {
      return false;
    }
    if (e.target.files[0].type) {
      const URLs = URL.createObjectURL(e.target.files[0]);
      setVideoFile(URLs);
    }
  }

  function handleConUpload() {
    Swal.fire({
      icon: "question",
      position: "center",
      title: "ยืนยันการอัปโหลด",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      showCancelButton: true,
      showConfirmButton: true
    }).then((res) => {
      if (res.isConfirmed) {
        const formData = new FormData();
        formData.append("video", inputStoreVideo.current.files[0]);
        formData.append("storeCode", storeCode);
        apiCreateVideo(formData);
      }
    });
  }

  async function apiCreateVideo(params) {
    // if (isLoading) {
    //   Swal.fire({
    //     title: "กำลังอัปโหลด ",
    //     text: "กรุณารอสักครู่",
    //     timerProgressBar: true,
    //     allowOutsideClick: false,
    //     allowEscapeKey: false,
    //     didOpen: () => {
    //       Swal.showLoading();
    //     }
    //   });
    // }
    setIsLoading(true);

    axios({
      method: "POST",
      url: `${apiUrl}/api/admin/store/videoUpload`,
      headers: {
        Authorization: `Bearer ${access_token}`
      },
      data: params
    }).then((res) => {
      Swal.close()
      Toast.fire({
        icon: "success",
        title: "อัปโหลดแล้ว"
      });
      console.log('close')
      apiGetStore();
      setIsLoading(false);
    });
  }

  const handleRemove = () => {
    setVideoFile([]);
    if(videoPath !== '' && videoPath !== null && videoPath !== undefined){
        Swal.fire({
            icon: "question",
            position: "center",
            title: "ยืนยันการลบวิดีโอ",
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            showCancelButton: true,
            showConfirmButton: true
          }).then(async (res) => {
            if (res.isConfirmed) {
              const formData = new FormData();
              formData.append("video", inputStoreVideo.current.files[0]);
              formData.append("storeCode", storeCode);
              await axios({
                method: "DELETE",
                url: `${apiUrl}/api/admin/store/videoRemove/${storeCode}`,
                headers: {
                  Authorization: `Bearer ${access_token}`
                },
              }).then((res) => {
                Toast.fire({
                  icon: "success",
                  title: "ลบวิดีโอแล้ว"
                });
                apiGetStore();
                setIsLoading(false);
              });
            }
          });
    }
  };

  return (
    <>
      <div className="flex justify-center flex-col items-center relative">
        <label className="block text-left text-gray-500 font-bold md:text-right mb-1">
          อัปโหลดวิดีโอร้านค้า
        </label>
        <div className="relative">
          {videoFile.length > 0 ? (
            <video
              autoPlay
              muted
              loop
              controls
              className=" object-cover flex justify-center items-center text-sm  w-[auto] h-[220px] bg-[#D9D9D9] rounded "
              alt="videoStore"
              src={videoFile}
              type="video/mp4"
            />
          ) : (
            <video
              autoPlay
              muted
              controls
              loop
              className=" object-cover flex justify-center items-center text-sm  w-[auto] h-[220px] bg-[#D9D9D9] rounded "
              alt="videoStore"
              src={`${apiUrl}/${videoPath}`}
              type="video/mp4"
              poster="/assets/empty.png"
            />
          )}
          <div className="absolute top-2 right-3  z-30">
            <span
              className="hover:underline hover:text-blue-400 underline-offset-1 cursor-pointer"
              onClick={() => inputStoreVideo.current.click()}
            >
              <i className="fa fa-cloud-upload"></i> Upload
            </span>
            <input
              className="hidden"
              type="file"
              accept=".mp4"
              ref={inputStoreVideo}
              onChange={(e) => inputVideoOnChange(e)}
            />
          </div>
        </div>

        {(videoFile.length > 0 || videoPath !== "") && (
          <button
            type="button"
            className="text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-xl text-sm px-5 py-1.5 text-center m-2 dark:focus:ring-yellow-900"
            onClick={handleRemove}
          >
            ลบวิดีโอ
          </button>
        )}
        {videoFile.length > 0 && (
          <button
            type="button"
            className="text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-4 focus:ring-pink-300 font-medium rounded-xl text-sm px-5 py-1.5 text-center m-2 dark:focus:ring-yellow-900"
            onClick={handleConUpload}
          >
            อัปโหลด
          </button>
        )}

        <Loading loading={isLoading} />
      </div>
    </>
  );
}
