import { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2';

const GPSettingTable = (props) => {
    const access_token = useSelector((state) => (state.app.access_token))
    const apiUrl = useSelector((state) => (state.app.apiPath))
    const [data, setData ] = useState([])
    const [refreshData, setRefreshData] = useState(0)

    useEffect( () => {
        axios.get(`${apiUrl}/api/admin/recommendGp`, {
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        }).then(res => {
          setData(res.data.data)
        })
    }, [refreshData])

  const removeHanlder = async (_data) => {
    const confirm = await Swal.fire({
      title: 'แน่ใจหรือไม่?',
      text: `คุณกำลังลบข้อมูลลำดับที่ ${_data.priority} (GP ${_data.value}%)`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ใข่, ลบเลย!'
    }).then((result) => {
      return result.isConfirmed;
    })
    
    if(confirm) {
      axios.delete(`${apiUrl}/api/admin/recommendGp/${_data.id}`, {
        headers: {
            Authorization: `Bearer ${access_token}`
        }
      }).then(res => {
        setRefreshData(prev => prev + 1)
        Swal.fire('Successfully!','Data have been change!','success')
      }, error => {
        Swal.fire( 'Failed!', 'Can not create', 'error')
      })
    }

  }

  
  const createHandler = async () => {
    const { value: formValues } = await Swal.fire({
        title: 'ตั้งค่า GP ตามลำดับ',
        confirmButtonText: "บันทึก",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        html:
          '<input id="swal-input1" type="number" class="swal2-input" placeholder="ลำดับ">' +
          '<input id="swal-input2" type="number" class="swal2-input" placeholder="ค่า GP">',
        focusConfirm: false,
        preConfirm: () => {
          return [
            document.getElementById('swal-input1').value,
            document.getElementById('swal-input2').value
          ]
        },
    })

    axios.post(`${apiUrl}/api/admin/createRecommendGp`, {
      priority: formValues[0],
      value: formValues[1]
    },{ headers: { Authorization: `Bearer ${access_token}` }})
    .then(res => {
      setRefreshData(prev => prev + 1)
      Swal.fire('Successfully!','Data have been change!','success')
    }, error => {
      Swal.fire( 'Failed!', 'Can not create', 'error')
    })
  }

  return (
    <section className="gp-setting-table">
      <div className="tb-header">
        <h2>ตั้งค่า GP สินค้าแนะนำ</h2>
        <button type="button" className="add-btn" onClick={createHandler}>
          <i className="fa fa-plus"></i> เพิ่ม GP
        </button>
      </div>
      <table className="tb-body">
        <thead>
          <tr>
            <th>ลำดับที่</th>
            <th>ค่า GP</th>
            <th>จัดการ</th>
          </tr>
        </thead>
        <tbody>
          {data.map((d) => (
            <tr key={d.id}>
              <td>{d.priority}</td>
              <td>{d.value}%</td>
              <td>
                <button
                  type="button"
                  className="btn-remove"
                  onClick={() => removeHanlder(d)}
                >
                  <i className="fa fa-trash"></i> ลบ
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default GPSettingTable;
